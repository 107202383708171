import { useState } from "react";
import { Button, TextButton } from "@lib/Forms";
import { useTitle } from "@lib/Hooks";
import { privateAgent } from "@lib/Requests/AuthRequests";

import { Formik, useFormikContext } from "formik";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { routesName } from "@lib/RoutesName/Routes";
import AddNoteFields from "@components/formFields/Notes/AddNoteFields";
import NavigateBack from "@components/shared/NavigateBack";
import { noteEditValue, noteInitialValue } from "./notes-helper";
import { AddNoteProps, NoteProps } from "@lib/Types/NoteProps";
import Cookies from "js-cookie";
import { PAGE_FOR } from "@pages/pagefor";
import PlayIcon from "@assets/Play";
import ViewNotePage from "@pages/ViewNotePage";
import AddNoteValidationSchema from "@components/formFields/Notes/noteValidationSchema";
import { Header } from "src/styles/Typography";
import { StyledForm } from "src/styles/Forms";
import {
  TopContainer,
  TableContainer,
  NoteContainer,
  ButtonContainerEnd,
  PreviewContainer,
} from "src/styles/Containers";

interface AddNotePageProps {
  isEdit?: boolean;
  pagefor: PAGE_FOR;
}

function AddNotePage(props: AddNotePageProps) {
  const { isEdit, pagefor } = props;
  useTitle("Client Note | Paige");
  const formikProps = useFormikContext();

  const navigate = useNavigate();
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();
  const { clientId, noteId } = params;
  const [notes, setNotes] = useState<NoteProps>();
  const [fieldInitialValues, setFieldInitialValues] =
    useState(noteInitialValue);

  let { isLoading, data: editNotes } = useQuery(["singleNote"], async () => {
    const { data } = await privateAgent.get(
      routesName.NoteRoute({ id: noteId }).get_single_note
    );

    if (data.status === 200) {
      const results = data.result;
      setFieldInitialValues(noteEditValue(results));
      return results;
    }
  });

  const handleSubmit = async (values: any) => {
    const modifiedStepNotes = values.noteSteps.map(
      (stepnote: any, index: number) => {
        return {
          text: values[`noteSteps${index}`]?.text ?? "",
        };
      }
    );
    values.noteSteps = modifiedStepNotes;
    values.userId = pagefor == "coach" ? clientId : Cookies.get("userId");
    values.noteType =
      values.noteType == undefined ? "REGULAR" : values.noteType;
    values.link = "";
    values.isSystem = values.isSystem == true;
    if (isEdit) {
      const editApi = pagefor == 'admin' ?
        routesName.SystemNoteRoute({ id: noteId }).update_note :
        routesName.NoteRoute({ id: noteId }).update_note
      const res = await privateAgent.put(editApi, values);

      if (res.status === 200) {
        enqueueSnackbar("Note edited successfully", { variant: "success" });
        navigate(-1);
      }
    } else {
      const res = await privateAgent.post(
        routesName.NoteRoute({}).add_note,
        values
      );
      if (res.status === 201) {
        enqueueSnackbar("Note added successfully", { variant: "success" });
        navigate(-1);
      }
    }
  };

  const handlePreview = async (values: any) => {
    setOpenPreviewDialog(true)

    // const test = formikProps.values as AddNoteProps;
  };

  return (
    <>
      <NavigateBack text='Notes' />

      <Formik
        enableReinitialize={true}
        initialValues={fieldInitialValues}
        onSubmit={handleSubmit}
        validationSchema={AddNoteValidationSchema}
      >
        {({ isValid, setValues, values }) => (
          <StyledForm>
            <TopContainer>
              <Header>
                {isEdit ? "Edit Regular Note" : "Add Regular Note"}
                {(values.noteType != 'RECORD_STATS' && values.noteType != 'RECORD_WEIGHTS') && 
                <PreviewContainer onClick={handlePreview}>
                  <PlayIcon />
                  <p>Preview</p>
                </PreviewContainer>}
              </Header>
              <ButtonContainerEnd>
                <TextButton
                  label={"Cancel"}
                  onClick={() => navigate(-1)}
                ></TextButton>
                <Button label={"Save"} type={"submit"}></Button>
              </ButtonContainerEnd>
            </TopContainer>
            <TableContainer>
              <NoteContainer>
                <ViewNotePage
                  // note={previewNote.note}
                  isOpen={openPreviewDialog}
                  setIsOpen={setOpenPreviewDialog}
                  onClose={() => {
                    // setOpenPreviewDialog(false);
                  }}
                />
                <AddNoteFields isEdit={isEdit} />
              </NoteContainer>
            </TableContainer>
          </StyledForm>
        )}
      </Formik>


    </>
  );
}

export default AddNotePage;
