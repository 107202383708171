import { useState, useMemo } from "react";
import { AlertDialog } from "@lib/Forms";
import { useAddButtonOptions, useSystemAddButtonOptions, useTitle } from "@lib/Hooks";
import { privateAgent } from "@lib/Requests/AuthRequests";
import { useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { routesName } from "@lib/RoutesName/Routes";
import AddNoteBtn from "@components/formFields/Notes/AddNoteBtn";
import { useNavigate } from "react-router-dom";
import { MRT_ColumnDef, MRT_Row } from "material-react-table";
import { DataTable } from "@lib/Forms/DataTable";
import NoteModeMenu from "@components/formFields/Notes/NoteModeMenu";
import { BehaviorProps } from "@lib/Types/BehaviorProps";
import { CallsToActionProps } from "@lib/Types/CallsToActionProps";
import { Mode } from "@components/shared/dropdown/mode";
import { OnChangeFn } from "@tanstack/react-table";
import { PAGE_FOR } from "@pages/pagefor";
import Cookies from "js-cookie";
import { NoteProps } from "@lib/Types";
import { Tag } from "@components/styles/general.styled";
import {
  TopContainerNoMargin,
  UsersContainer,
  TableContainer,
} from "src/styles/Containers";
import { TitleLarge } from "src/styles/Typography";

interface NotePageProps {
  pagefor: PAGE_FOR;
}

function NotePage({ pagefor }: NotePageProps) {
  useTitle("Client Note | Paige");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const { clientId } = params;
  const queryClient = useQueryClient();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [noteId, setNoteId] = useState("");
  const addBtnOptions = useAddButtonOptions()
  const addSystemNoteBtnOptions = useSystemAddButtonOptions()

  const [filteredData, setFilteredData] = useState([]);
  let { isLoading, data: clientFullName } = useQuery(
    ["Client"],
    async () => {
      const { data } = await privateAgent.get(
        routesName.UserRoute({ id: clientId }).get_single_user
      );

      if (data.status === 200) {
        const results = `${data.result.userdetails.firstName} ${data.result.userdetails.lastName}`;
        return results;
      }
    },
    { enabled: pagefor == "coach" }
  );

  let { data: clientNotes } = useQuery(["Clients_Notes"], async () => {
    const clientid = pagefor == "coach" ? clientId : Cookies.get("userId");
    const { data } = await privateAgent.get(
      routesName.NoteRoute({ id: clientid }).get_notes_by_clientId
    );

    if (data.status === 200) {
      const results = data.result.items;
      setFilteredData(results);
      return results;
    }
  }, { enabled: pagefor == "coach" || pagefor == "client" });


  let { data: systemNotes } = useQuery(["Clients_Notes"], async () => {
    const { data } = await privateAgent.get(
      routesName.SystemNoteRoute({  }).get_notes
    );

    if (data.status === 200) {
      const results = data.result.items;
      setFilteredData(results);
      return results;
    }
  }, { enabled: pagefor == "admin" });

  type Note = {
    id: number;
    title: string;
    noteType: string;
    behavior: BehaviorProps;
    isSystem: boolean;
    callToAction: CallsToActionProps;
  };

  function capitalizeFirstLetter(val: string) {
    let updatedText = val;
    if (val.toLowerCase().indexOf("system") == 0) {
      updatedText = val.slice(val.lastIndexOf("_") + 1);
    }
    return (
      updatedText.charAt(0).toUpperCase() + updatedText.slice(1).toLowerCase()
    );
  }

  const columns = useMemo<MRT_ColumnDef<Note>[]>(
    () => [
      {
        accessorFn: (row) => `${row.title}`,
        id: "title",
        header: "Note",
        minSize: 250,
        Cell: ({ cell }) => (
          <div>
            {cell.row.original.title}
            {cell.row.original.isSystem &&
                <Tag>System</Tag>
            }

          </div>
        ),
      },
      {
        accessorFn: (row) => `${capitalizeFirstLetter(row.noteType)}`,
        accessorKey: "noteType",
        header: "Note Type",
      },
      {
        header: "Behavior",
        id: "behavior",
        accessorFn: (row) => `${row?.behavior?.name ?? "-"}`,
        size: 100,
      },
      {
        header: "Call to Action",
        id: "calltoAction",
        accessorFn: (row) => `${row?.callToAction?.name ?? "-"}`,
        size: 100,
      },
      {
        id: "actions",
        header: "Actions",
        headerClassName: "header-row",
        flex: 1,
        muiTableBodyCellProps: {
          align: "right",
          className: "action",
        },
        size: 50,
        enableResizing: false, //disable resizing for this column
        Cell: ({ cell }) => (
          <NoteModeMenu
            id={0}
            isSystem={cell.row.original.isSystem}
            onItemClick={function (id: number, mode: Mode): void {
              const note = cell.row.original
              
              if (mode == "edit") {
                if (cell.row.original.noteType !=="LINK") {
                  if (pagefor == "coach") {
                    navigate(
                      `/coach/clients/${clientId}/editnote/${cell.row.original.id}`
                    );
                  }
                  if (pagefor == "client") {
                    navigate(`/clients/editnote/${cell.row.original.id}`);
                  }
                }
                if (cell.row.original.noteType === "LINK") {
                  if (pagefor == "coach") {
                    navigate(
                      `/coach/clients/${clientId}/editlinknote/${cell.row.original.id}`
                    );
                  }
                  if (pagefor == "client") {
                    navigate(`/clients/editlinknote/${cell.row.original.id}`);
                  }
                }
              }

              if (mode == "duplicate") {
                
                if(cell.row.original.isSystem){
                  enqueueSnackbar("You don't have permission to duplicate this note", { variant: "error" });
                  return;
                }
                if (cell.row.original.noteType.includes("LINK")) {
                  if (pagefor == "coach") {
                    navigate(
                      `/coach/clients/${clientId}/addlinknote/${cell.row.original.id}/duplicate`
                    );
                  }
                  if (pagefor == "client") {
                    navigate(
                      `/clients/addlinknote/${cell.row.original.id}/duplicate`
                    );
                  }
                }else{
                    if (pagefor == "coach") {
                      navigate(
                        `/coach/clients/${clientId}/addnote/${cell.row.original.id}/duplicate`
                      );
                    }
                    if (pagefor == "client") {
                      navigate(
                        `/clients/addnote/${cell.row.original.id}/duplicate`
                      );
                    }
                }
              }

              if (mode === "delete") {
                const note = cell.row.original  as NoteProps
                if(note.isSystem){
                  enqueueSnackbar("You don't have permission to delete this note", { variant: "error" });
                  return;
                }
                else if(note.reminders?.length!>0){
                  enqueueSnackbar("This note is associated to a reminder", { variant: "error" });
                  return;
                }
                setNoteId(`${cell.row.original.id}`);
                setOpenDeleteDialog(true);
              }
            }}
          />
        ),
      },
    ],
    []
  );


  const handleDelete = async () => {
    try {
      const res = await privateAgent.delete(
        routesName.NoteRoute({ id: noteId }).delete_note
      );
      setOpenDeleteDialog(false);
      if (res.status === 200) {
        queryClient.invalidateQueries("Clients_Notes");
        enqueueSnackbar("Note deleted successfully", { variant: "success" });
      }
    } catch (error: any) {
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
      setOpenDeleteDialog(false);
    }
  };

  const handleRowClick = (column: any, row: any) => {
    if (column.id === "title") {
      if (row.original.noteType.includes("REGULAR") || row.original.noteType.includes("REVIEW_RULES") || row.original.noteType.includes("RITUAL") || row.original.noteType.includes("RECORD_STATS") || row.original.noteType.includes("RECORD_WEIGHTS")) {
        if (pagefor == "coach") {
          navigate(`/coach/clients/${clientId}/editnote/${row.original.id}`);
        }
        if (pagefor == "client") {
          navigate(`/clients/editnote/${row.original.id}`);
        }
      }
      if (row.original.noteType.includes("LINK")) {
        if (pagefor == "coach") {
          navigate(
            `/coach/clients/${clientId}/editlinknote/${row.original.id}`
          );
        }
        if (pagefor == "client") {
          navigate(`/clients/editlinknote/${row.original.id}`);
        }
      }
    }
  };

  return (
    <>
      <AlertDialog
        variety='error'
        onConfirm={() => handleDelete()}
        onClose={() => setOpenDeleteDialog(false)}
        isOpen={openDeleteDialog}
        title={"Are you sure you want to delete this note"}
        content={
          "Deleting this note will permanently erase any data associated with this note."
        }
        btnText={{ cancel: "Cancel", action: "Delete" }}
      />

      <TopContainerNoMargin>
        <TitleLarge>
          {pagefor == "coach"
            ? clientFullName == undefined
              ? ""
              : `${clientFullName}'s Notes`
            : ""}
        </TitleLarge>
        <AddNoteBtn
          options={ pagefor=="admin"? addSystemNoteBtnOptions : addBtnOptions }
          onItemClick={function (mode: Mode): void {
            if (mode === "regular-note") {
              if (pagefor == "coach") {
                navigate(`/coach/clients/${clientId}/addnote`);
              }
              if (pagefor == "client") {
                navigate(`/clients/addnote`);
              }
            }
            if (mode === "link-note") {
              if (pagefor == "coach") {
                navigate(`/coach/clients/${clientId}/addlinknote`);
              }
              if (pagefor == "client") {
                navigate(`/clients/addlinknote`);
              }
            }
          }}
        />
      </TopContainerNoMargin>
      <UsersContainer>
        <TableContainer>
          {filteredData && filteredData.length > 0 && (
            <DataTable
              columns={columns}
              data={filteredData}
              onCellClick={handleRowClick}
              enableRowOrdering={true}
              enableSorting={true}
              muiTableBodyRowDragHandleProps={({ table }: any) => ({
                onDragEnd: async () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    filteredData.splice(
                      (hoveredRow as MRT_Row<any>).index,
                      0,
                      filteredData.splice(draggingRow.index, 1)[0]
                    );

                    const Order = filteredData.map(
                      (data: NoteProps, index: number) => {
                        return {
                          id: data.id,
                          ordering: index,
                        };
                      }
                    );

                    const order = await privateAgent.post(
                      routesName.NoteRoute({}).notes_reorder,
                      { notes: Order }
                    );
                    queryClient.invalidateQueries("Clients_Notes");
                  }
                },
              })}
            />
          )}
        </TableContainer>
      </UsersContainer>
    </>
  );
}

export default NotePage;
