import styled from "@emotion/styled";
import { Form } from "formik";

export const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
`;

export const LoginContainer = styled.div`
  // display: inline-block;
  display: inline-flex;
  height: 100%;
  width: calc(100% - 8em);
  flex-wrap: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 4em;
  padding-right: 4em;
  gap: 48px;

  // position: absolute;
  // width: 536px;
  // height: 351px;
  // left: calc(50% - 536px / 2 - 372px);
  // top: calc(50% - 351px / 2 - 0.5px);

  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.05));
  border-radius: 4px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;
export const FormContainer = styled(Form)`
  position: relative;
  height: 100%;
  // position: absolute;
  display: flex;
  // top: 0;
  // bottom: 0;
  // align-items: center;
  // width: calc(100% - 4em);
  // padding: 4em;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;
