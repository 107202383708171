import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextButton from "./TextButton";
import Button, { variety } from "./Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "../../assets/Close";
import ErrorIcon from "../../assets/ErrorIcon";

interface AlertDialogProps {
  variety?: variety;
  isOpen: boolean;
  title: string;
  content: string;
  onClose: () => void;
  onConfirm: () => void;
  btnText: { cancel: string; action: string };
}

export default function AlertDialog({
  isOpen,
  title,
  content,
  onClose,
  onConfirm,
  btnText,
  variety,
}: AlertDialogProps) {
  const [open, setOpen] = React.useState(isOpen);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        className='alertDialog'
      >
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id='alert-dialog-title'>
          <ErrorIcon
            style={{
              height: "100%",
              verticalAlign: "middle",
              paddingBottom: "4px",
              paddingRight: "4px",
            }}
          ></ErrorIcon>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <TextButton label={btnText.cancel} onClick={() => handleClose()} />
          <Button
            variety={variety}
            label={btnText.action}
            onClick={() => onConfirm()}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
