import SvgIcon from "@mui/material/SvgIcon";

function DuplicateIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <g id='duplicate_svgrepo.com'>
          <path
            id='Vector'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2.98816 5.73381C2.98816 5.21331 3.39685 4.77663 3.96116 4.77569C4.52554 4.77469 4.8901 4.77663 4.8901 4.77663C4.8901 4.77663 4.86585 4.22038 4.86585 3.778C4.86585 3.3355 5.25504 2.89269 5.81972 2.89269C6.38441 2.89269 11.6199 2.87644 12.1508 2.91581C12.6817 2.95519 13.1043 3.28913 13.1043 3.82625C13.1043 4.36338 13.1009 9.68919 13.1009 10.1825C13.1009 10.6758 12.6584 11.1239 12.1098 11.1301C11.5612 11.1364 11.1894 11.1239 11.1894 11.1239C11.1894 11.1239 11.2095 11.747 11.2095 12.1298C11.2095 12.5124 10.7645 13.0683 10.2424 13.0683C9.72029 13.0683 4.4226 13.1021 3.9171 13.1021C3.4116 13.1021 2.9451 12.6574 2.96666 12.1029C2.98822 11.5485 2.98816 6.25419 2.98816 5.73381ZM5.86691 3.95338L5.86266 10.0886C5.86266 10.1571 5.91854 10.213 5.9876 10.2126L11.9598 10.1808C11.993 10.1806 12.0248 10.1673 12.0482 10.1437C12.0716 10.1202 12.0847 10.0883 12.0847 10.0551L12.0807 3.97494C12.0806 3.94165 12.0674 3.90975 12.0438 3.88623C12.0202 3.86271 11.9883 3.8495 11.955 3.8495L5.99254 3.82913C5.97611 3.82896 5.95981 3.83205 5.94459 3.83822C5.92936 3.84439 5.91551 3.85351 5.90383 3.86507C5.89215 3.87662 5.88287 3.89037 5.87653 3.90553C5.8702 3.92069 5.86693 3.93695 5.86691 3.95338ZM3.95429 5.98819L4.01154 12.0036C4.01195 12.0367 4.02546 12.0683 4.04911 12.0915C4.07277 12.1148 4.10465 12.1277 4.13779 12.1274L10.0206 12.0828C10.0534 12.0826 10.0858 12.0759 10.116 12.0631C10.1462 12.0504 10.1735 12.0317 10.1965 12.0084C10.2195 11.985 10.2376 11.9573 10.2498 11.9269C10.262 11.8964 10.2681 11.8639 10.2677 11.8311L10.2591 11.1351C10.2591 11.1351 6.32929 11.1712 5.81822 11.1712C5.30716 11.1712 4.85335 10.6874 4.85335 10.1431C4.85335 9.59869 4.8901 5.84931 4.8901 5.84931C4.89072 5.78056 4.83547 5.72494 4.7666 5.72588L4.20241 5.734C4.13583 5.73491 4.07233 5.76217 4.02582 5.80982C3.97931 5.85747 3.95359 5.92161 3.95429 5.98819Z'
            fill='#172882'
            stroke='#172882'
            strokeWidth='0.25'
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default DuplicateIcon;
