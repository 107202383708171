import React, { useState, useMemo } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Button, DialogBox, TextButton, AlertDialog } from "@lib/Forms";

import { useTitle } from "@lib/Hooks";
import { privateAgent } from "@lib/Requests/AuthRequests";

import { Formik, Form } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { routesName } from "@lib/RoutesName/Routes";
import { useNavigation, useParams } from "react-router-dom";
import { MRT_ColumnDef, MRT_Row } from "material-react-table";
import { AddReminderProps, ReminderProps } from "@lib/Types/ReminderProps";
import { DataTable } from "@lib/Forms/DataTable";
import {
  reminderIntialValue,
  editReminderIntitialValues,
  formattedReminderValues,
} from "./reminder-helpers";
import AddReminderFields from "@components/formFields/Reminders/AddReminderFields";
import dayjs from "dayjs";
import { Mode } from "@components/shared/dropdown/mode";
import ReminderModeMenu from "@components/formFields/Reminders/ReminderModeMenu";
import Cookies from "js-cookie";
import AddSystemReminderFields from "./AddSystemReminderFields";

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const Title = styled(Typography)`
  font-family: Nunito;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color: #242424;
`;

const UsersContainer = styled.div`
  padding: 16px;
  border-radius: 4px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px #0000000;
`;

const TableContainer = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  text-align: right;
`;
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type PAGE_FOR = "client" | "coach"|"admin";

interface ReminderPageProps {
  pagefor: PAGE_FOR;
}

function ReminderPage({ pagefor }: ReminderPageProps) {
  useTitle("Admin Reminder | Paige");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openAddBehaviorCategoryDialog, setOpenAddBehaviorCategoryDialog] =
    useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [reminderId, setReminderId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const params = useParams();
  const clientId  = Cookies.get("userId");
  const [filteredData, setFilteredData] = useState([]);
  const [openAddReminderDialog, setOpenAddReminderDialog] = useState(false);
  const queryClient = useQueryClient();
  const [fieldInitialValues, setFieldInitialValues] =
    useState<AddReminderProps>(reminderIntialValue);

  let { isLoading, data: clientFullName } = useQuery(
    ["Client"],
    async () => {
      const { data } = await privateAgent.get(
        routesName.UserRoute({ id: clientId }).get_single_user
      );

      if (data.status === 200) {
        const results = `${data.result.userdetails.firstName} ${data.result.userdetails.lastName}`;
        return results;
      }
    },
    { enabled: pagefor == "coach" }
  );

  let { data: systemReminders } = useQuery(["System_Reminders"], async () => {
    const clientid = pagefor == "admin" ? Cookies.get("userId") : clientId;
    const { data } = await privateAgent.get(
      routesName.SystemReminderRoute({  }).get_reminders
    );

    if (data.status === 200) {
      const results = data.result.items;
      console.log(results);
      setFilteredData(results);
      return results;
    }
  });

  console.log("system reminder ", systemReminders)


  const handleSubmit = async (values: AddReminderProps) => {
    const clientid = pagefor == "coach" ? clientId : Cookies.get("userId");
    const value = formattedReminderValues(values, Number(clientid));

    try {
      if (isEdit) {
        const res = await privateAgent.put(
          routesName.SystemReminderRoute({ id: reminderId }).update_reminder,
          value
        );

        if (res.data && res.data.status === 200) {
          queryClient.invalidateQueries("System_Reminders");
          setOpenAddReminderDialog(false);
        }
      } else {
        const res = await privateAgent.post(
          routesName.SystemReminderRoute({}).add_reminder,
          value
        );

        if (res.status === 201) {
          queryClient.invalidateQueries("System_Reminders");
          setOpenAddReminderDialog(false);
        }
      }
    } catch (error: any) {
      console.log("error ", error.response.data.error);
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
    }
  };

  const handleDelete = async () => {
    try {
      const res = await privateAgent.delete(
        routesName.SystemReminderRoute({ id: reminderId }).delete_reminder
      );

      if (res.status === 200) {
        queryClient.invalidateQueries("System_Reminders");
        setOpenDeleteDialog(false);
        enqueueSnackbar("Reminder deleted successfully", {
          variant: "success",
        });
      }
    } catch (error: any) {
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
    }
  };

  const columns = useMemo<MRT_ColumnDef<ReminderProps>[]>(
    () => [
      {
        accessorFn: (row) => `${row.title}`,
        id: "title",
        header: "Reminder Title",
      },
      // {
      //   accessorFn: (row) => `${row.reminderType}`,
      //   id: "reminderType",
      //   header: "Association Type",
      // },
      {
        header: "Associated Note or Sequence",
        id: "noteId",
        accessorFn: (row) =>
          `${
            row?.reminderType === "NOTE"
              ? row?.note?.title
              : row?.sequence?.title
          }`,
        size: 100,
      },
      {
        header: "Date",
        id: "date",
        accessorFn: (row) =>
          `${dayjs(row?.datetimeStr).format("DD MMMM	 YYYY") ?? "-"}`,
        size: 100,
      },
      {
        header: "Time",
        id: "time",
        accessorFn: (row) => `${row?.reminderType === "NOTE"? dayjs(row?.datetimeStr).format("hh:mm A") : "-"}`,
        size: 100,
      },
      {
        header: "Frequency",
        id: "freqyency",
        accessorFn: (row) => `${row?.repeat ?? "-"}`,
        size: 100,
      },
      {
        id: "actions",
        header: "Actions",
        headerClassName: "header-row",
        flex: 1,
        muiTableBodyCellProps: {
          align: "right",
          className: "action",
        },
        size: 50,
        enableResizing: false, //disable resizing for this column
        Cell: ({ cell }) => (
          <ReminderModeMenu
            id={0}
            onItemClick={function (id: number, mode: Mode): void {
              if (mode == "edit") {
                setOpenAddReminderDialog(true);
                setFieldInitialValues(
                  editReminderIntitialValues(cell.row.original)
                );
                setReminderId(`${cell.row.original.id}`);
                setIsEdit(true);
              }

              if (mode == "duplicate") {
                setOpenAddReminderDialog(true);
                setFieldInitialValues(
                  editReminderIntitialValues(cell.row.original)
                );
                setReminderId(`${cell.row.original.id}`);
                setIsEdit(false);
              }

              if (mode === "delete") {
                setReminderId(`${cell.row.original.id}`);
                setOpenDeleteDialog(true);
              }
            }}
          />
        ),
      },
    ],
    []
  );

  const handleRowClick = (column: any, row: any) => {
    if (column.id !== "title") return;
    setOpenAddReminderDialog(true);
    setFieldInitialValues(editReminderIntitialValues(row.original));
    setReminderId(`${row.original.id}`);
    setIsEdit(true);
  };

  return (
    <>
      <AlertDialog
        variety='error'
        onConfirm={() => handleDelete()}
        onClose={() => setOpenDeleteDialog(false)}
        isOpen={openDeleteDialog}
        title={"Are you sure you want to delete this reminder?"}
        content={
          "Deleting this reminder will erase all the data associated with this reminder."
        }
        btnText={{ cancel: "Cancel", action: "Delete" }}
      />
      <DialogBox
        isOpen={openAddReminderDialog}
        onClose={() => {
          setOpenAddReminderDialog(false);
        }}
        title={isEdit ? "Edit Reminder" : "Add Reminder"}
        content={
          <Formik
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={fieldInitialValues as AddReminderProps}
            onSubmit={handleSubmit}
            // validationSchema={
            //   isEdit ? EditUserValidationSchema : AddUserValidationSchema
            // }
          >
            {({ isValid, setValues, values }) => (
              <StyledForm>
                <AddSystemReminderFields
                  isEdit={isEdit}
                  clientId={
                    pagefor == "coach" ? clientId : Cookies.get("userId")
                  }
                />
                <ButtonContainer>
                  <TextButton
                    label={"Cancel"}
                    onClick={() => {
                      setOpenAddReminderDialog(false);
                    }}
                  ></TextButton>
                  <Button
                    label={"Add"}
                    type={"submit"}
                    // disabled={isEdit? false: !isValid}
                  ></Button>
                </ButtonContainer>
              </StyledForm>
            )}
          </Formik>
        }
      />
      <TopContainer>
        <Title>{clientFullName ? `${clientFullName}'s Reminder` : ""}</Title>
        <Button
          label={"+ Add System Reminder"}
          onClick={() => {
            setIsEdit(false)
            setOpenAddReminderDialog(true);
          }}
        ></Button>
      </TopContainer>
      <UsersContainer>
        <TableContainer>
          {filteredData && filteredData.length > 0 && (
            <DataTable
              columns={columns}
              data={systemReminders}
              enableRowOrdering={true}
              enableSorting={false}
              onCellClick={handleRowClick}
              muiTableBodyRowDragHandleProps={({ table }: any) => ({
                onDragEnd: async() => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    filteredData.splice(
                      (hoveredRow as MRT_Row<any>).index,
                      0,
                      filteredData.splice(draggingRow.index, 1)[0]
                    );

                    
                    const Order = filteredData.map((data: ReminderProps, index: number)=>{
                      return{
                        id: data.id,
                        ordering: index
                      }
                    })
                    
                    const order = await privateAgent.post(routesName.SystemReminderRoute({}).reminders_reorder, {notes: Order})
                    queryClient.invalidateQueries("System_Reminders");

                  }
                },
              })}
            />
          )}
        </TableContainer>
      </UsersContainer>
    </>
  );
}

export default ReminderPage;
