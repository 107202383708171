import { MaterialReactTable, MaterialReactTableProps } from "material-react-table";
import DescSortedIcon from "../../assets/Sort-Desc";
import MoveDotsIcon from "../../assets/MoveDots";
import { useState } from "react";
import { DataTableContainer } from "src/styles/Containers";

interface DataTableProps extends MaterialReactTableProps {
  data: any;
  columns: any;
  onCellClick?: (column: any, rows: any) => void;
  [key: string]: any;
}

export function DataTable(props: DataTableProps) {
  const { data, columns, onCellClick, ...rest } = props;
  const [currentPage, setCurrentPage] = useState(1);
  // const[data,setData] =useState([]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <DataTableContainer>
      <MaterialReactTable
        autoResetPageIndex={props.enableRowOrdering ? false : true}
        columns={columns}
        data={data}
        enableRowOrdering={props.enableRowOrdering ? true : false}
        enableSorting={props.enableSorting ? true : false}
        displayColumnDefOptions={{ "mrt-row-drag": { size: 20 } }} //change width of actions column to 300px
        enableColumnResizing
        columnResizeMode='onChange' //default
        enableTopToolbar={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        paginateExpandedRows={false}
        // positionExpandColumn='last'
        enableExpandAll={false} //hide expand all double arrow in column header
        muiTableHeadRowProps={({ table, headerGroup }) => ({
          className:
            (props.enableRowOrdering ? "isRowDraggable" : "") +
            (props.enableExpanding ? " isRowExpandable" : ""),
        })}
        muiTableBodyRowProps={({ row, table }) => ({
          className:
            (row.depth == 1 ? "subRow" : "mainRow") +
            (props.enableRowOrdering ? " isRowDraggable" : "") +
            (props.enableExpanding ? " isRowExpandable" : ""),
        })}
        muiTableBodyCellProps={({ cell, column, row, table }) => ({
          onClick: (event) => {
            if (onCellClick) {
              onCellClick(column, row);
            }
          },
        })}
        initialState={{
          pagination: {
            pageSize: 10,
            pageIndex: 0,
          },
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10],
        }}
        localization={{ of: "out of", move: "", expand: "" }}
        icons={{
          ArrowDownwardIcon: (props: any) => <DescSortedIcon {...props} />,
          DragHandleIcon: (props: any) => <MoveDotsIcon {...props} />,
        }}
        {...rest}
      />
    </DataTableContainer>
  );
}
