
import { AddCallsToActionProps } from "../../../lib/Types/CallsToActionProps"

export const callToActionInitialValues : AddCallsToActionProps =  {
    name: ""
} 

export const CreateUsersListObject=(item: any)=>{

    return {
         id: item.id,
         firstName:item.userdetails?.firstName ?? '',
         name: item.userdetails?  `${item.userdetails?.firstName ?? ''} ${item?.userdetails?.lastName ?? ''}`: '' , 
         email: item?.email ?? '', 
         class: item?.classes[0]?.name ?? '', 
         userType: item?.role?.name ?? '', 
         coach: `${item?.coach?.users?.userdetails?.firstName} ${item?.coach?.users?.userdetails?.lastName}`?? '', 
         advancedSettings: 'No', 
         status: item?.isActive ?? false, 
    }
}

export const editIntitialValues = (item: any): AddCallsToActionProps=>{
    return {
         name:item?.name 
    }
}