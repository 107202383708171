

export type FREQUENCY = 'Daily'| 'Does not repeat' | 'Weekly'|'Hourly' | 'Monthly' | 'Yearly'


const useFrequencyOptions = () => {

    const frequencyOptions: any = [

      {
        Name: "Does not repeat",
        value: "Does not repeat",
        days: 0,
        Enabled: 1,
      },
      {
        Name: "Daily",
        value: "Daily",
        days: 1,
        Enabled: 1,
      },
      {
        Name: "Weekly",
        value: "Weekly",
        days: 7,
        Enabled: 1,
      },
      {
        Name: "Monthly",
        value: "Monthly",
        days: 30,
        Enabled: 1,
      },
      {
        Name: "Yearly",
        value: "Yearly",
        days: 365,
        Enabled: 1,
      },
    ];
    
  return frequencyOptions;
};

export default useFrequencyOptions;
