import React, { useState } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import {
  Button,
  DialogBox,
  TextButton,
  AlertDialog,
  SubTitle,
  InputField,
} from "@lib/Forms";

import { Formik, Form } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { routesName } from "@lib/RoutesName/Routes";
import AddLinkNoteFields from "@components/formFields/Notes/AddLinkNoteFields";
import NavigateBack from "@components/shared/NavigateBack";
import { useNavigate, useParams } from "react-router-dom";
import { noteEditValue, noteInitialValue } from "./notes-helper";
import { useTitle } from "@lib/Hooks";
import { privateAgent } from "@lib/Requests/AuthRequests";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  // margin-bottom: 24px;
`;
const Header = styled(Typography)`
  font-family: Nunito;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #242424;
  display: flex;
  align-items: center;
`;
const Title = styled(Typography)`
  font-family: Nunito;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.28px;
  letter-spacing: 0em;
  text-align: left;
  color: #242424;
`;

const TableContainer = styled.div`
  padding: 16px;
  border-radius: 4px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px #0000000;
  display: flex;
  flex-direction: column;
`;
const NoteContainer = styled.div`
  width: 30%;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  text-align: right;
`;
const PreviewDiv = styled.div`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  & p {
    color: #172882;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

type PAGE_FOR = "client" | "coach" | "admin";

interface AddLinkNotePageProps {
  isEdit?: boolean;
  pagefor: PAGE_FOR;
}

function AddSystemLinkNotePage(props: AddLinkNotePageProps) {
  const { isEdit } = props;
  useTitle("Client Note | Paige");
  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();
  const { clientId, noteId } = params;
  const [fieldInitialValues, setFieldInitialValues] =
    useState(noteInitialValue);

  let { isLoading, data: editNotes } = useQuery(["singleNote"], async () => {
    const { data } = await privateAgent.get(
      routesName.NoteRoute({ id: noteId }).get_single_note
    );

    if (data.status === 200) {
      const results = data.result;
      setFieldInitialValues(noteEditValue(results));
      return results;
    }
  });

  const handleSubmit = async (values: any) => {
    console.log("values after submit ", values);
    values.noteSteps = [];
    values.userId = clientId;
    values.noteType =
    values.noteType == undefined ? "SYSTEMLINK" : values.noteType;
    values.callToActionId = 1;

    if (isEdit) {
      const res = await privateAgent.put(
        routesName.SystemNoteRoute({ id: noteId }).update_note,
        values
      );
      if (res.status === 200) {
        enqueueSnackbar("Link note edited successfully", {
          variant: "success",
        });
        navigate(-1);
      }
    } else {
      const res = await privateAgent.post(
        routesName.SystemNoteRoute({}).add_note,
        values
      );
      if (res.status === 201) {
        enqueueSnackbar("Link note added successfully", { variant: "success" });
        navigate(-1);
      }
    }
  };

  return (
    <>
      <NavigateBack text='Notes' />

      <Formik
        enableReinitialize={true}
        initialValues={fieldInitialValues}
        onSubmit={handleSubmit}
      >
        {({ isValid, setValues, values }) => (
          <StyledForm>
            <TopContainer>
              <Header>{isEdit ? "Edit System Link Note" : "Add System Link Note"}</Header>
              <ButtonContainer>
                <TextButton
                  label={"Cancel"}
                  onClick={() => navigate(-1)}
                ></TextButton>
                <Button label={"Save"} type={"submit"}></Button>
              </ButtonContainer>
            </TopContainer>
            <TableContainer>
              <Title>{"General"}</Title>
              <NoteContainer>
                <AddLinkNoteFields />
              </NoteContainer>
            </TableContainer>
          </StyledForm>
        )}
      </Formik>
    </>
  );
}

export default AddSystemLinkNotePage;
