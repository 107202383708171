import { useState, useEffect } from "react";
import { privateAgent } from "../../Requests/AuthRequests";
import { routesName } from "../../RoutesName/Routes";
import { SequenceProps } from "../../Types/SequenceProps";
import { SequenceNoteProps } from "@lib/Types/SequenceNoteProps";

const useSequencesOptions = (clientId?: string,showSystem:boolean = false) => {
  const [options, setOptions] = useState([]);

  const fetchSequencesByClientId = async () => {
    if (!clientId) return;
    const res = await privateAgent.get(
      routesName.SequenceRoute({ id: clientId }).get_sequences_by_clientId
    );
    if (res.status == 200) {
      const options = res.data.result.items.filter((item:SequenceProps) =>{
        return showSystem || !item.isSystem;
        }).map(
        (item: SequenceProps) => {
          // const hasDuplicate = hasDuplicateTitles(item?.sequenceNotes);
          const maxDays = Math.max(...item?.sequenceNotes.map((item:any) => item.day));
          return {
            Name: `${item.title}`,
            value: item.id,
            Enabled: 1,
            // hasDuplicate: hasDuplicate,
            maxDays: maxDays,
          };
        },
        {
          enabled: !!clientId,
        }
      );
      setOptions(options);
    } else {
      setOptions([]);
    }
  };

  const hasDuplicateTitles = (sequenceNotes:SequenceNoteProps[]) => {
    if (sequenceNotes.length > 1) {
        for (const sequenceNote of sequenceNotes) {
            if (sequenceNote.day > 1) {
                return true
            }
        }
    }
    else return false;
    
    // const days = {};
    // for (const note of sequenceNotes) {
    //   if (days[note.day]) {
    //     return true; 
    //   }
    //   days[note.day] = true;
    // }
    // return false; 
  };

  useEffect(() => {
    fetchSequencesByClientId();

    return () => {
      setOptions([]);
    };
  }, [clientId]);

  return options;
};

export default useSequencesOptions;
