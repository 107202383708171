import * as React from "react";
import Menu from "@mui/material/Menu";
import styled from "@emotion/styled";
import { Button } from "@lib/Forms";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery } from "react-query";
import { privateAgent } from "@lib/Requests/AuthRequests";
import { routesName } from "@lib/RoutesName";
import { SequenceProps } from "@lib/Types";
import { useRef } from "react";
import { PAGE_FOR } from "@pages/pagefor";
import Cookies from "js-cookie";

const MenuStyled = styled(Menu)`
  border: 1px solid #e2e6e8;
`;
const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    background: transparent;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2) !important;
  }
  & .MuiFormControl-root {
    margin-top: 10px;
  }
  & .MuiPopover-paper {
    margin-top: 10px;
    height: 250px;
    padding: 0;
    box-shadow: none;
    &.MuiAutocomplete-root {
      padding: 10px;
      background: white;
    }
  }
  & .MuiFormControl-fullWidth {
    & .MuiInputBase-root {
      padding-left: 0px;
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }
  & .MuiAutocomplete-popper {
    width: 100% !important;
    height: 75%; //calc(100% - 50px);
    overflow-y: auto;
    div {
      width: 100% !important;
      // box-shadow: none;
      background: white;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2) !important;
    }
    ul {
      width: 100% !important;
    }
    li {
      width: 100% !important;
    }
  }
  & .MuiInputBase-input {
    padding: 8.5px 14px !important;
    transform: unset !important;
  }
  & .MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
    padding: 8.5px 14px !important;
    transform: unset !important;
  }
  & .one2 {
  }
`;
const StyledTextField = styled(TextField)`
  & .MuiInputBase-input {
    padding: 8.5px 14px !important;
    transform: unset !important;
  }
  & .MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
    padding: 8.5px 14px !important;
    transform: unset !important;
  }
`;

export interface HoverMenuProps {
  onItemClick?: (sequence: SequenceProps) => void;
  clientId?: string;
  pagefor: PAGE_FOR;
  hide?: boolean
}

export default function AddNoteToSequenceBtn({
  clientId,
  pagefor,
  hide,
  onItemClick,
}: HoverMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [focus, setFocus] = React.useState(true);
  const [openOptions, setOpenOptions] = React.useState(false);

  let { data: systemSequences } = useQuery(
    ["Systems_Sequences_dropdown"],
    async () => {
      const clientid = pagefor == 'admin' ?  clientId : Cookies.get('userId')
      const { data } = await privateAgent.get(
        routesName.SystemSequenceRoute({ }).get_sequences
      );

      if (data.status === 200) {
        const results = data.result.items;
        return results;
      }
    },
    { enabled: !!clientId || !!Cookies.get('userId')}
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenOptions(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenOptions(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const autocompleteTextFieldRef = useRef<HTMLInputElement>(null);

  const handleOptionClick = (event: any, value: any) => {
    if (onItemClick) onItemClick(value as SequenceProps);
    handleClose();
  };

  return (
    <div>
      { !hide && <>
      <Button
        label={"+ Add Note to Sequence"}
        aria-describedby={id}
        onClick={handleClick}
      />
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionProps={{
          onEntered: () => autocompleteTextFieldRef?.current?.focus(),
        }}
        sx={{ height: 400 }}
      >
        <Autocomplete
          open={openOptions}
          disablePortal
          id='combo-box-demo'
          onChange={handleOptionClick}
          getOptionLabel={(option) => option.title}
          options={systemSequences}
          sx={{ width: 300, background: "white" }}
          renderInput={(params) => (
            <StyledTextField
              inputRef={autocompleteTextFieldRef}
              // inputRef={(input: any) => (focus ? input && input.focus() : null)}
              // autoFocus
              // onFocus={(e: any) => {
              //   e.currentTarget.setSelectionRange(
              //     e.currentTarget.value.length,
              //     e.currentTarget.value.length
              //   );
              // }}
              {...params}
              label='Search'
            />
          )}
        />
      </StyledPopover>
      </> 
      }
    </div>
  );
}


AddNoteToSequenceBtn.defaultProps = {
  hide: false
};