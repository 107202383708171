import { Link } from "react-router-dom";
import {
  Button,
  ForgotPwProps,
  privateAgent,
  SubTitle,
  TextButton,
  Title,
} from "../../lib";
import { Formik } from "formik";
import { routesName } from "../../lib/RoutesName/Routes";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import AddForgotPasswordFields from "../../components/formFields/ForgotPassworda/addForgotPasswordFields";
import ForgotPwValidationSchema from "../../components/formFields/ForgotPassworda/ForgotPwValidationSchema";
import LoginPageLayout from "../../components/shared/loginPageLayout";
import {
  ButtonContainer,
  FormContainer,
  LoginContainer,
  TitleContainer,
} from "../../components/styles/login.styled";

// const ForgotPwContainer = styled.div`
//   // display: inline-block;
//   display: inline-flex;
//   height: 100%;
//   width: calc(100% - 8em);
//   flex-wrap: wrap;
//   flex-direction: column;
//   justify-content: center;
//   align-items: flex-start;
//   padding-left: 4em;
//   padding-right: 4em;
//   gap: 48px;

//   // position: absolute;
//   // width: 536px;
//   // height: 351px;
//   // left: calc(50% - 536px / 2 - 372px);
//   // top: calc(50% - 351px / 2 - 0.5px);

//   filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.05));
//   border-radius: 4px;
// `;

// const TitleContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   padding: 0px;
//   gap: 8px;
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `;
// const FormContainer = styled(Form)`
//   position: relative;
//   height: 100%;
//   display: flex;
//   align-items: center;
// `;

function ForgotPasswordPage() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = async (values: ForgotPwProps) => {
    try {
      const { data } = await privateAgent.post(
        routesName.AuthRoute({}).forgotPassword,
        values
      );

      enqueueSnackbar(data.result ?? "", { variant: "success" });
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
    }
  };

  return (
    <LoginPageLayout>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={ForgotPwValidationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <FormContainer>
            <LoginContainer>
              <TitleContainer>
                <Title label={"Forgot Password"} />
                <SubTitle
                  label={
                    "Don't worry, it happens. Please enter the email address associated with your account below."
                  }
                />
              </TitleContainer>

              <AddForgotPasswordFields />

              <ButtonContainer>
                <Button
                  width={"156px"}
                  type={"submit"}
                  label={"Send Recovery Email"}
                  disabled={!formikProps.isValid}
                ></Button>
                <Link to={`/login`}>
                  <TextButton label={"Back"} />
                </Link>
              </ButtonContainer>
            </LoginContainer>
          </FormContainer>
        )}
      </Formik>
    </LoginPageLayout>
  );
}

export default ForgotPasswordPage;
