import RitualAnswers from "@pages/RitualAnswers/RitualAnswers";
import { PagePermission } from "@routeName/roleroute"
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { RitualAnswersContainer, TopContainerNoMargin } from "src/styles/Containers";
import { TitleLarge } from "src/styles/Typography";

interface HappinessAnswersPageParamI {
    role: PagePermission;

}

export default function HappinessAnswersPage({ role }: HappinessAnswersPageParamI) {
    const params = useParams();
    const userId = role == 'client' ? Number(Cookies.get("userId")) : Number(params.userId);


    return (<>
        <TopContainerNoMargin>
            <TitleLarge>Happiness Answers</TitleLarge>
        </TopContainerNoMargin>
        <RitualAnswersContainer>
            <RitualAnswers userId={userId}></RitualAnswers>
        </RitualAnswersContainer>
    </>)
}