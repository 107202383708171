import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SequencePage from "../Sequence/SequencePage";
import NotePage from "../Notes/NotePage";
import styled from "@emotion/styled";
import { TabScrollButton } from "@mui/material";
import ReminderPage from "../Reminder/ReminderPage";
import GoBackIcon from "../../assets/GoBack";
import NavigateBack from "../../components/shared/NavigateBack";
import { Navigate, Route, useLocation, useNavigate, useParams } from "react-router-dom";
import { tab } from "@testing-library/user-event/dist/types/setup/directApi";
import PaigeAIPage from "./PaigeAIPage";
import ClientInfo from "./ClientInfo";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface CoachTabsProps{
  tabIndex?: number
}

export default function CoachTabs({tabIndex}: CoachTabsProps) {
  const [value, setValue] = React.useState(Number(tabIndex ?? '0'));

  const params = useParams();
  const { clientId } = params;
  const navigate = useNavigate()
  const location = useLocation();

  React.useEffect(()=>{
    if(location.pathname.includes('notes')){
      setValue(0)
    } else if(location.pathname.includes('sequences')){
      setValue(1)
    } else if(location.pathname.includes('reminders')){
      setValue(2)
    } else if(location.pathname.includes('client_info')){
      setValue(3)
    } else if(location.pathname.includes('paige_ai')){
      setValue(4)
    }
  }, [location.pathname])

  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if(value == newValue) return

      let url = `/coach/clients/${clientId}/notes`
      if(newValue == 1){
        url = `/coach/clients/${clientId}/sequences`
      } else if(newValue == 2){
        url = `/coach/clients/${clientId}/reminders`
      } else if(newValue == 3){
        url = `/coach/clients/${clientId}/client_info`
      } else if(newValue == 4){
        url = `/coach/clients/${clientId}/paige_ai`
      }

      navigate(url, {replace: true})

    
    // navigate(0)
 
  };
  const StyledBox = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `;

  const StyledTabs = styled(Tabs)`
    & .MuiTab-root {
      text-transform: none;
      font-family: Nunito;
      font-size: 16px;
      font-weight: 500;
    }
    & .Mui-selected {
      font-weight: 700;
    }
  `;

  return (
    <StyledBox sx={{ width: "100%" }}>
      <NavigateBack text='Clients' link={"/clients"} />
      <Box sx={{}}>
        <StyledTabs
          id='asdf'
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab value={0} label='Notes' {...a11yProps(0)} />
          <Tab value={1} label='Sequences' {...a11yProps(1)} />
          <Tab value={2} label='Reminder' {...a11yProps(2)} />
          <Tab value={3} label='Client Info' {...a11yProps(3)} />
          <Tab value={4} label='Paige AI' {...a11yProps(4)} />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <NotePage pagefor='coach'/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SequencePage pagefor={'coach'}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ReminderPage pagefor={'coach'}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ClientInfo pagefor={'coach'}/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <PaigeAIPage pagefor={'coach'}/>
      </TabPanel>
    </StyledBox>
  );
}
