import { useEffect, useState } from "react";
import { InputField } from "../../../lib";
import { useFormikContext } from "formik";
import { FieldsContainer } from "src/styles/Containers";

function ChangePasswordFields() {
  const [focus, setFocus] = useState(true);
  const formikProps = useFormikContext();

  useEffect(() => {
    formikProps.setTouched({});
    setFocus(false);
  }, []);

  return (
    <FieldsContainer>
      <InputField
        placeholder={"Old password *"}
        name={"oldPassword"}
        type={"password"}
        inputRef={(input: any) => (focus ? input && input.focus() : null)}
        onFocus={(e: any) => {
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          );
        }}
      />
      <InputField
        placeholder={"New password *"}
        name={"password"}
        type={"password"}
      />
      <InputField
        placeholder={"Confirm password *"}
        name={"confirmPassword"}
        type={"password"}
      />
    </FieldsContainer>
  );
}

export default ChangePasswordFields;
