/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import {
  Container,
  LeftContainer,
  RightContainer,
  ImageContainer,
  LayoutContainer,
  MainContainer,
  ContentContainer,
} from "src/styles/Containers";

interface AdminLayoutInterface {
  children: React.ReactNode;
}

const RootLayout = ({ children }: AdminLayoutInterface) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [topMenuOpened, setTopMenuOpened] = useState(false);
  const handleTopMenuClicked = () => {
    setTopMenuOpened(!topMenuOpened);
  };
  const handleLinkMenuClicked = () => {
    setTopMenuOpened(false);
  };

  const nonadminpaths = [
    "/login",
    "/signup",
    "/forgotpassword",
    "/resetpassword",
  ];

  useEffect(() => {

    const isNonAdminPath = nonadminpaths.some((x: any) => pathname.includes(x));
    if (!Cookies.get("token")) {
      if (isNonAdminPath) return;
      navigate("/login", { replace: true });
    } else {
      if (isNonAdminPath) {
        navigate("/", { replace: true });
      }
    }
  }, [pathname]);

  // non admin layout
  if (nonadminpaths.some((x: any) => pathname.includes(x))) {
    return (
      <Container>
        <LeftContainer>{children}</LeftContainer>
        <RightContainer
          style={{
            width: "60%",
          }}
        ></RightContainer>
        <ImageContainer>
          <img
            style={{
              position: "absolute",
              left: "55%",
              height: "75%",
            }}
            src={require("../../assets/paige.png")}
          ></img>
          {/* <PaigeIcon
            style={{
              position: "absolute",
              left: "43.5%",
            }}
          ></PaigeIcon> */}
        </ImageContainer>
      </Container>
    );
  } else {
    // admin layout
    return (
      <LayoutContainer>
        <Navbar onTopMenuCLicked={handleTopMenuClicked} />
        <MainContainer className={topMenuOpened ? "showNavBar" : "hideNavBar"}>
          <Sidebar onLinkMenuCLicked={handleLinkMenuClicked} />
          <ContentContainer className={topMenuOpened ? "hidden" : ""}>
            {children}
          </ContentContainer>
        </MainContainer>
      </LayoutContainer>
    );
  }
};

export default RootLayout;
