import { useState, useMemo } from "react";
import { Button, DialogBox, TextButton, AlertDialog } from "@lib/Forms";
import { useTitle } from "@lib/Hooks";
import { privateAgent } from "@lib/Requests/AuthRequests";
import { Formik } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { routesName } from "@lib/RoutesName/Routes";
import { useParams } from "react-router-dom";
import { MRT_ColumnDef, MRT_Row } from "material-react-table";
import { AddReminderProps, ReminderProps } from "@lib/Types/ReminderProps";
import { DataTable } from "@lib/Forms/DataTable";
import {
  reminderIntialValue,
  editReminderIntitialValues,
  formattedReminderValues,
} from "@components/formFields/Reminders/reminder-helpers";
import AddReminderFields from "@components/formFields/Reminders/AddReminderFields";
import dayjs from "dayjs";
import { Mode } from "@components/shared/dropdown/mode";
import ReminderModeMenu from "@components/formFields/Reminders/ReminderModeMenu";
import Cookies from "js-cookie";
import { AddReminderValidationSchema } from "@components/formFields/Reminders/AddReminderValidationSchema";
import { Tag } from "@components/styles/general.styled";
import { StyledForm } from "src/styles/Forms";
import {
  ButtonContainer,
  TopContainerNoMargin,
  UsersContainer,
  TableContainer,
} from "src/styles/Containers";
import { TitleLarge } from "src/styles/Typography";
import PaigeSkeleton from "@components/formFields/skeleton/PaigeSkeleton";

type PAGE_FOR = "client" | "coach";

interface ReminderPageProps {
  pagefor: PAGE_FOR;
}

function ReminderPage({ pagefor }: ReminderPageProps) {
  useTitle("Client Reminder | Paige");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openAddBehaviorCategoryDialog, setOpenAddBehaviorCategoryDialog] =
    useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [reminderId, setReminderId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const params = useParams();
  const { clientId } = params;
  const [filteredData, setFilteredData] = useState([]);
  const [openAddReminderDialog, setOpenAddReminderDialog] = useState(false);
  const queryClient = useQueryClient();
  const [fieldInitialValues, setFieldInitialValues] =
    useState<AddReminderProps>(reminderIntialValue);

  let {  data: clientFullName } = useQuery(
    ["Client"],
    async () => {
      const userId = pagefor == 'coach' ? clientId : Cookies.get("userId");
      const { data } = await privateAgent.get(
        routesName.UserRoute({ id: userId }).get_single_user
      );

      if (data.status === 200) {
        const results = `${data.result.userdetails.firstName} ${data.result.userdetails.lastName}`;
        return results;
      }
    },
    { enabled: pagefor == "coach" }
  );

  let { isLoading, data: clientReminders } = useQuery(["Clients_Reminders"], async () => {
    const clientid = pagefor == "client" ? Cookies.get("userId") : clientId;
    const { data } = await privateAgent.get(
      routesName.ReminderRoute({ id: clientid }).get_reminders_by_clientId
    );

    if (data.status === 200) {
      const results = data.result.items.filter((item:any)=> {return !(item.isSystem && item.sequence != null && (item.sequence.type == "RECOVERY" || item.sequence.type == "RECOVERY_NEW"))});
      setFilteredData(results);
      return results;
    }
  });

  const handleSubmit = async (values: AddReminderProps) => {
    const clientid = pagefor == "coach" ? clientId : Cookies.get("userId");
    const value = formattedReminderValues(values, Number(clientid));
    try {
      if (isEdit) {
        const res = await privateAgent.put(
          routesName.ReminderRoute({ id: reminderId }).update_reminder,
          value
        );

        if (res.data && res.data.status === 200) {
          queryClient.invalidateQueries("Clients_Reminders");
          setOpenAddReminderDialog(false);
          enqueueSnackbar("Reminder updated successfully", { variant: "success" });
        }
      } else {
        const res = await privateAgent.post(
          routesName.ReminderRoute({}).add_reminder,
          value
        );

        if (res.status === 201) {
          queryClient.invalidateQueries("Clients_Reminders");
          setOpenAddReminderDialog(false);
          enqueueSnackbar("Reminder added successfully", { variant: "success" });
        }
      }
    } catch (error: any) {
      console.log("error ", error.response.data.error);
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
    }

    setFieldInitialValues(reminderIntialValue)
  };

  const handleDelete = async () => {
    try {
      const res = await privateAgent.delete(
        routesName.ReminderRoute({ id: reminderId }).delete_reminder
      );

      if (res.status === 200) {
        queryClient.invalidateQueries("Clients_Reminders");
        setOpenDeleteDialog(false);
        enqueueSnackbar("Reminder deleted successfully", {
          variant: "success",
        });
      }
    } catch (error: any) {
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
    }
  };

  const columns = useMemo<MRT_ColumnDef<ReminderProps>[]>(
    () => [
      {
        accessorFn: (row) => `${row.title}`,
        id: "title",
        header: "Reminder Title",
        Cell: ({ cell }) => (
          <div>
            {cell.row.original.title}
            {cell.row.original.isSystem &&
                <Tag>System</Tag>
            }

          </div>
        ),
      },
     
      {
        header: "Associated Note or Sequence",
        id: "noteId",
        accessorFn: (row) =>
          `${
            row?.reminderType === "NOTE"
              ? row?.note?.title
              : row?.sequence?.title
          }`,
        size: 100,
      },
      {
        header: "Date",
        id: "date",
        accessorFn: (row) =>
          `${dayjs(row?.datetimeStr).format("DD MMMM	 YYYY") ?? "-"}`,
        size: 100,
      },
      {
        header: "Time",
        id: "time",
        accessorFn: (row) => `${row?.reminderType === "NOTE"? dayjs(row?.datetimeStr).format("hh:mm A") : "-"}`,
        size: 100,
      },
      {
        header: "Frequency",
        id: "freqyency",
        accessorFn: (row) => `${row?.repeat ?? "-"}`,
        size: 100,
      },
      {
        id: "actions",
        header: "Actions",
        headerClassName: "header-row",
        flex: 1,
        muiTableBodyCellProps: {
          align: "right",
          className: "action",
        },
        size: 50,
        enableResizing: false, //disable resizing for this column
        Cell: ({ cell }) => (
          <ReminderModeMenu
            id={0}
            onItemClick={function (id: number, mode: Mode): void {
              const reminder = cell.row.original
              if (mode == "edit") {
                setOpenAddReminderDialog(true);
                setFieldInitialValues(
                  editReminderIntitialValues(cell.row.original)
                );
                setReminderId(`${cell.row.original.id}`);
                setIsEdit(true);
              }

              if (mode == "duplicate") {
                if(cell.row.original.isSystem){
                  enqueueSnackbar("You don't have permission to duplicate this reminder", { variant: "error" });
                  return
                }
                setOpenAddReminderDialog(true);
                setFieldInitialValues(
                  editReminderIntitialValues(cell.row.original)
                );
                setReminderId(`${cell.row.original.id}`);
                setIsEdit(false);
              }

              if (mode === "delete") {
                if(cell.row.original.isSystem){
                  enqueueSnackbar("You don't have permission to delete this reminder", { variant: "error" });
                  return
                }
                setReminderId(`${cell.row.original.id}`);
                setOpenDeleteDialog(true);
              }
            }}
          />
        ),
      },
    ],
    []
  );

  const handleRowClick = (column: any, row: any) => {
    
    if (column.id !== "title") return;
    const reminder = row.original
    setOpenAddReminderDialog(true);
    setFieldInitialValues(editReminderIntitialValues(row.original));
    setReminderId(`${row.original.id}`);
    setIsEdit(true);
  };

  if(isLoading){
    return <PaigeSkeleton />
  }

  return (
    <>
      <AlertDialog
        variety='error'
        onConfirm={() => handleDelete()}
        onClose={() => setOpenDeleteDialog(false)}
        isOpen={openDeleteDialog}
        title={"Are you sure you want to delete this reminder?"}
        content={
          "Deleting this reminder will erase all the data associated with this reminder."
        }
        btnText={{ cancel: "Cancel", action: "Delete" }}
      />
      <DialogBox
        isOpen={openAddReminderDialog}
        onClose={() => {
          setOpenAddReminderDialog(false);
        }}
        title={isEdit ? "Edit Reminder" : "Add Reminder"}
        content={
          <Formik
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={fieldInitialValues as AddReminderProps}
            onSubmit={handleSubmit}
            validationSchema={
               AddReminderValidationSchema 
            }
          >
            {({ isValid, setValues, values }) => (
              <StyledForm>
                <AddReminderFields
                  pageFor={pagefor}
                  isEdit={isEdit}
                  clientId={
                    pagefor == "coach" ? clientId : Cookies.get("userId")
                  }
                />
                <ButtonContainer>
                  <TextButton
                    label={"Cancel"}
                    onClick={() => {
                      setOpenAddReminderDialog(false);
                    }}
                  ></TextButton>
                  <Button
                    label={ isEdit ? "Save" : "Add"}
                    type={"submit"}
                    // disabled={isEdit? false: !isValid}
                  ></Button>
                </ButtonContainer>
              </StyledForm>
            )}
          </Formik>
        }
      />
      <TopContainerNoMargin>
        <TitleLarge>
          { pagefor == "coach" && clientFullName ? `${clientFullName}'s Reminder` : ""}
        </TitleLarge>
        <Button
          label={"+ Add Reminder"}
          onClick={() => {
            setFieldInitialValues(reminderIntialValue)
            setIsEdit(false)
            setOpenAddReminderDialog(true);
            
          }}
        ></Button>
      </TopContainerNoMargin>
      <UsersContainer>
        <TableContainer>
          {filteredData && filteredData.length > 0 && (
            <DataTable
              columns={columns}
              data={clientReminders}
              enableRowOrdering={true}
              enableSorting={false}
              onCellClick={handleRowClick}
              muiTableBodyRowDragHandleProps={({ table }: any) => ({
                onDragEnd: async () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    filteredData.splice(
                      (hoveredRow as MRT_Row<any>).index,
                      0,
                      filteredData.splice(draggingRow.index, 1)[0]
                    );

                    const Order = filteredData.map(
                      (data: ReminderProps, index: number) => {
                        return {
                          id: data.id,
                          ordering: index,
                        };
                      }
                    );

                    const order = await privateAgent.post(
                      routesName.ReminderRoute({}).reminders_reorder,
                      { notes: Order }
                    );
                    queryClient.invalidateQueries("Clients_Reminders");
                  }
                },
              })}
            />
          )}
        </TableContainer>
      </UsersContainer>
    </>
  );
}

export default ReminderPage;
