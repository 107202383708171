import SvgIcon from "@mui/material/SvgIcon";

function DescSortedIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <g id='sort_svgrepo.com'>
          <path
            id='Vector'
            d='M11.578 9.469L8.5935 13.188C8.328 13.532 7.8905 13.532 7.6245 13.188L4.64 9.469C4.3745 9.125 4.4995 8.844 4.9525 8.844H'
            fill='#172882'
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default DescSortedIcon;
