import { ReactNode } from "react";
import LogoIcon from "../../assets/Logo";
import { LogoContainer, FormContainer } from "src/styles/Containers";

interface BackgroundProps {
  children: ReactNode;
}

function LoginPageLayout({ children }: BackgroundProps) {
  return (
    <>
      <LogoContainer>
        <LogoIcon
          style={{ width: "unset", height: "unset" }}
          height='29px'
        ></LogoIcon>
        <img height='40px' src={require("../../assets/PaigeText.png")}></img>
      </LogoContainer>
      <FormContainer>{children}</FormContainer>
    </>
  );
}

export default LoginPageLayout;
