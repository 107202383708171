import { TextField } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { useField } from "formik";

type InputType = "text" | "password" | "email";

type InputFieldPrps = {
  name: string;
  placeholder: string;
  type?: InputType;

  [key: string]: any;
};

const LoginInputFields = styled(TextField)`
  height: 56px;
  width: 100%;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  & .Mui-error {
    text-align: right;
    margin-top: -3px;
    margin-right: 0;
  }
  & .MuiInputLabel-shrink {
    -webkit-transform: translate(15px, -8px) scale(0.75);
    -moz-transform: translate(15px, -8px) scale(0.75);
    -ms-transform: translate(15px, -8px) scale(0.75);
    transform: translate(15px, -8px) scale(0.75);
  }
  & .MuiInputBase-input {
    padding: 16.5px 14px !important;
  }
`;

const LoginInputField = (props: InputFieldPrps) => {
  const { name, type, placeholder, class: string, ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const touched = meta.touched;
    const error = meta.error;

    if (touched && error) {
      return error;
    }
  }

  function hasError() {
    const touched = meta.touched;
    const error = meta.error;
    if (touched && error) {
      return true;
    }
    return false;
  }

  return (
    <React.Fragment>
      <LoginInputFields
        label={placeholder}
        error={hasError()}
        {... rest}
        {...field}
        helperText={_renderHelperText()}
        type={type}
      />
    </React.Fragment>
  );
};

export default LoginInputField;

LoginInputField.defaultProps = {
  type: "text",
};
