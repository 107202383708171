import { AddNoteButtonOptions } from "@components/formFields/Notes/AddNoteBtn";


const useSystemAddButtonOptions = () => {

    const systemAddButtonOption: AddNoteButtonOptions[] = [
      {
        title: "System Regular Note",
        type: 'system-note',
      },
      {
        title: "System Link Note",
        type: 'system-link',
      },
      {
        title: "System Ritual Note",
        type: 'system-ritual-note',
      },
      {
        title: "Record Craving",
        type: 'system-record-stats',
      },
      {
        title: "Record Weight",
        type: 'system-record-weight',
      },
    ];
    
  return systemAddButtonOption;
};

const useAddButtonOptions = () => {

  const addButtonOption: AddNoteButtonOptions[] = [
    {
      title: "Regular Note",
      type: 'regular-note',
    },
    {
      title: "Link Note",
      type: 'link-note',
    }
  ];
  
return addButtonOption;
};

export  { useSystemAddButtonOptions, useAddButtonOptions };
