import { NoteProps } from "@lib/Types/NoteProps";

export const noteInitialValue = {
  title: "",
  notificationText: "",
  behaviorId: "",
  callToActionId: "",
  link: "",
  noteSteps: [{ text: "" }],
};

export const noteEditValue = (notes: NoteProps) => {
  const value: any = {
    title: notes.title,
    notificationText: notes.notificationText,
    behaviorId: notes.behaviorId,
    callToActionId: notes.callToActionId,
    noteSteps: notes.noteSteps,
    noteType: notes.noteType,
    link: notes.noteType.indexOf("LINK") > -1 ? notes.link : "",
  };
  for (let i = 0; i < notes.noteSteps.length; i++) {
    value[`noteSteps${i}`] = { text: notes.noteSteps[i].text };
  }
  return value;
};
