import { InputField, SelectField, TimeField } from "../../../lib";
import useClassesOptions from "../../../lib/Hooks/options/useClassesOption";
import { FieldArray, useFormikContext } from "formik";
import { AddNoteProps } from "../../../lib/Types/NoteProps";
import Editor from "../../shared/Quill/Editor";
import useCallToActionOptions from "../../../lib/Hooks/options/useCalltoActionOption";
import useBehaviorOptions from "../../../lib/Hooks/options/useBehaviorOption";
import MoveDotsIcon from "../../../assets/MoveDots";
import {
  EditorRowContainer,
  MainFieldContainer,
  MainFieldContainer1,
  FieldContainer,
  AddStep,
  Steps,
  Delete,
  RowTwoContainer,
  EditorContainer,
} from "src/styles/Containers";
import { Title } from "src/styles/Typography";

interface AddSequenceNoteFieldsProps {
  isEdit?: boolean;
}

function AddSequenceNoteFields({ isEdit }: AddSequenceNoteFieldsProps) {
  const formikProps = useFormikContext();
  const classesOptions = useClassesOptions();
  const callToActionOptions = useCallToActionOptions();
  const behaviorOptions = useBehaviorOptions();

  function isFieldDisabled(property: string | null = null) {
    let noteType = (formikProps.values as AddNoteProps).noteType?.toLowerCase();
    let disabled = false;
    if (noteType != undefined)
      switch (true) {
        case noteType.indexOf("RECOVERY") == 0:
          disabled = true;
          break;
      }
    return disabled ? "disabled" : "";
  }
  function isFieldDisabledBoolean(property: string | null = null) {
    let noteType = (formikProps.values as AddNoteProps).noteType?.toLowerCase();
    let disabled = false;
    if (noteType != undefined)
      switch (true) {
        case noteType.indexOf("RECOVERY") == 0:
          disabled = true;
          break;
      }
    return disabled;
  }

  return (
    <FieldContainer>
      <MainFieldContainer>
        <MainFieldContainer1>
          <Title>{"General"}</Title>
          <InputField
            placeholder={"Note Title*"}
            name={"title"}
            disabled={isFieldDisabled()}
          />
          <InputField
            placeholder={"Notification Text*"}
            name={"notificationText"}
            disabled={isFieldDisabled()}
          />
          <SelectField
            name={"behaviorId"}
            placeholder={"Behaviour to Eliminate or Create"}
            options={behaviorOptions}
            disabled={isFieldDisabledBoolean()}
          />
          <SelectField
            name={"callToActionId"}
            placeholder={"Call to Action"}
            options={callToActionOptions}
            disabled={isFieldDisabledBoolean()}
          />

          <RowTwoContainer>
            <InputField
              name='day'
              placeholder='Day'
              type='number'
              InputProps={{ inputProps: { min: 1} }}
              disabled={isFieldDisabledBoolean()}
            />
            <TimeField
              name={"time"}
              placeholder={"Time"}
              disabled={isFieldDisabledBoolean()}
            />
          </RowTwoContainer>
        </MainFieldContainer1>
      </MainFieldContainer>
      <div style={{ width: "100%" }}>
        <FieldArray
          name='noteSteps'
          render={(arrayHelpers) => (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              {(formikProps.values as AddNoteProps).noteSteps &&
              (formikProps.values as AddNoteProps).noteSteps.length > 0
                ? (formikProps.values as AddNoteProps).noteSteps.map(
                    (note: any, index: number) => (
                      <EditorContainer key={index}>
                        <EditorRowContainer>
                          <Steps>
                            <MoveDotsIcon
                              sx={{
                                height: "16px",
                                width: "16px",
                                verticalAlign: "baseline",
                              }}
                            ></MoveDotsIcon>{" "}
                            Step {index + 1}
                          </Steps>
                          <Delete
                            style={{
                              visibility:
                                (formikProps.values as AddNoteProps).noteSteps
                                  .length == 1 || isFieldDisabledBoolean()
                                  ? "hidden"
                                  : "visible",
                            }}
                            onClick={() => {
                              arrayHelpers.remove(index);
                              // formikProps.setFieldValue(
                              //   `noteSteps${index}.text`,
                              //   ""
                              // );

                              let deleteIndex = index;                              
                              let totalNotes =(formikProps.values as AddNoteProps).noteSteps.length - 1
                              while(deleteIndex <= totalNotes){
                                let nextIndex = deleteIndex + 1;
                                if(nextIndex <= totalNotes)
                                  formikProps.setFieldValue(
                                    `noteSteps${deleteIndex}.text`,
                                    (formikProps.values as any)[`noteSteps${nextIndex}`].text
                                    );
                                deleteIndex++;
                              }

                              //set last value to blank so that when we add step we get blank step
                              formikProps.setFieldValue(
                                `noteSteps${(formikProps.values as AddNoteProps).noteSteps.length - 1}.text`,
                                ''
                                );
 
                            }}
                          >
                            {" "}
                            Delete
                          </Delete>
                        </EditorRowContainer>
                        <EditorRowContainer>
                          <Editor
                            name={`noteSteps${index}.text`}
                            placeholder={"description"}
                            formikProps={formikProps}
                            readonly={isFieldDisabledBoolean()}
                          />
                        </EditorRowContainer>
                      </EditorContainer>
                    )
                  )
                : null}
              {!isFieldDisabledBoolean() && (
                <AddStep
                  onClick={() => {
                    arrayHelpers.push({ text: "" });
                  }}
                >
                  + Add Step
                </AddStep>
              )}
            </div>
          )}
        />
      </div>
    </FieldContainer>
  );
}

export default AddSequenceNoteFields;
