/* eslint-disable @typescript-eslint/ban-ts-comment */

import { TextField } from "@mui/material";
import React from "react";
import styled, { CreateStyled } from "@emotion/styled";
import { useField } from "formik";
import { extend } from "dayjs";

type InputType = "text" | "password" | "email" | "number";

interface InputFieldPrps {//extends CreateStyled {
  name: string;
  placeholder: string;
  type?: InputType;

  [key: string]: any;
};

export const TextFields = styled(TextField)`
  // height: 56px;
  height: 40px;
  // width: 440px;
  width: 100%;
  left: 0px;
  top: 0px;
  border-radius: 8px;
  & .Mui-error {
    text-align: right;
    margin-top: -3px;
    margin-right: 0;
  }
  & .MuiInputBase-input {
    font-weight: 600;
    color: #242424;
  }
  & .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
    color: #45565e;
  }
  & .MuiInputBase-root:has(.login) {
    height: 56px !important;
    padding: 16.5px 14px !important;
  }
  & .MuiInputLabel-shrink {
    -webkit-transform: translate(20px, -9px) scale(0.75);
    -moz-transform: translate(20px, -9px) scale(0.75);
    -ms-transform: translate(20px, -9px) scale(0.75);
    transform: translate(20px, -9px) scale(0.75);
  }
  & .MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
    padding: 8.5px 14px;
    transform: unset;
  }
  & .MuiOutlinedInput-notchedOutline {
    padding: 8.5px 14px;
  }
  & .MuiInputBase-input {
    padding: 8.5px 14px;
  }
`;

const InputField = (props: InputFieldPrps) => {
  const { name, type, placeholder, ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const touched = meta.touched;
    const error = meta.error;

    if (touched && error) {
      return error;
    }
  }

  function hasError() {
    const touched = meta.touched;
    const error = meta.error;
    if (touched && error) {
      return true;
    }
    return false;
  }

  return (
    <React.Fragment>
      <TextFields
        className='teset'
        error={hasError()}
        {...rest}
        {...field}
        label={placeholder}
        helperText={_renderHelperText()}
        type={type}
      />
    </React.Fragment>
  );
};

export default InputField;

InputField.defaultProps = {
  type: "text",
};
