import React, { useEffect, useState } from "react";
import {
  InputField,
  SelectField,
  TimeField,
  ToggleButtonField,
} from "../../../lib";
import { useFormikContext } from "formik";
import useReminderTypeOptions from "../../../lib/Hooks/options/useReminderTypeOption";
import DateField from "../../../lib/Forms/DateField";
import useNotesOptions from "../../../lib/Hooks/options/useNotesOption";
import { useParams } from "react-router-dom";
import useSequencesOptions from "../../../lib/Hooks/options/useSequencesOption";
import { AddSequenceProps, SequenceProps } from "../../../lib/Types/SequenceProps";
import { AddReminderProps, ReminderProps } from "../../../lib/Types/ReminderProps";
import useFrequencyOptions from "../../../lib/Hooks/options/useFrequencyOptions";
import {
  FieldContainer,
  RowContainer,
  WidthContainer,
} from "src/styles/Containers";
import { PAGE_FOR } from "@pages/pagefor";

interface AddReminderFieldsProps {
  isEdit?: boolean;
  clientId?: string;
  pageFor: PAGE_FOR;
}

function AddReminderFields({ isEdit, clientId, pageFor }: AddReminderFieldsProps) {
  const formikProps = useFormikContext();
  const reminderTypeOptions = useReminderTypeOptions();
  const [focus, setFocus] = React.useState(true);
  const params = useParams();
  const notesOptions = useNotesOptions(clientId,(isEdit && (formikProps.values as ReminderProps).isSystem));
  const sequenceOptions = useSequencesOptions(clientId);
  const repeatOptions = useFrequencyOptions();


  const [noteSequenceOptions, setNoteSequenceOptions] = useState(notesOptions);
  const [checkDuplicate, setCheckDuplicate] = useState<boolean>();
  const [type, setType] = useState<string>('Note');
  const [ frequency, setFrequency ] = useState(repeatOptions)

  const getRepeatOptions = (maxDays:number | undefined) =>{
    if(maxDays == undefined)
      maxDays = 0;
    return repeatOptions.filter((item:any)=>{
      return item.days>=maxDays! || item.days == 0;
    })
  }

  useEffect(()=>{
    if(isEdit){
      const reminder = (formikProps.values as ReminderProps)
      if(reminder.reminderType =="SEQUENCE"){
        setType("Sequence")
      }
    }

  },[isEdit])

  useEffect(() => {
    console.log(formikProps.values)
    const noteid = (formikProps.values as SequenceProps).noteId;
    const sequeceType = (formikProps.values as SequenceProps).reminderType;
    if (noteid && sequeceType=="SEQUENCE") {
      const selectedItem = (noteSequenceOptions as unknown[])?.find((item: any) => item.value === noteid) as selectednote | undefined;
      //console.log(selectedItem)
      if (selectedItem) {
        const hasDuplicate = selectedItem.hasDuplicate;
        if (hasDuplicate) {
          setCheckDuplicate(true);
          setFrequency([{ Enabled: 1, Name: "Does not repeat", value : "Does not repeat"}]);
        } else {
          setCheckDuplicate(false);
          setFrequency(getRepeatOptions(selectedItem.maxDays));
          (formikProps.values as ReminderProps).repeat ="Does not repeat";
        }
      } else {
        setCheckDuplicate(false)
      }
    }else{
      setFrequency(repeatOptions);
    }
  },[(formikProps.values as SequenceProps).noteId, (formikProps.values as SequenceProps).reminderType])

  type selectednote = {
    Name?: string;
    Enabled?: number;
    value?: number;
    hasDuplicate: boolean;
    maxDays?:number;
  };


  useEffect(() => {
    const reminder = (formikProps.values as AddReminderProps);
    if (reminder.reminderType == "NOTE") {
      setNoteSequenceOptions(notesOptions);
    
    }
    if (reminder.reminderType == "SEQUENCE") {
      setNoteSequenceOptions(sequenceOptions);

    }
  }, [
    (formikProps.values as AddReminderProps).reminderType,
    notesOptions,
    sequenceOptions,
  ]);

  const handleToggleChange = (options: any) => {
    const reminder = (formikProps.values as AddReminderProps);
    if (options == "SEQUENCE") {
      setNoteSequenceOptions(sequenceOptions);
      setType('Sequence');
      formikProps.setFieldValue( "noteId", "")
      
    } else {
      setNoteSequenceOptions(notesOptions);
      setType('Note');
      // formikProps.setValues({reminder, "noteId":""})
      formikProps.setFieldValue( "noteId", "")

    }
  };

  useEffect(() => {
    formikProps.setTouched({});
    setFocus(false);
  }, []);

  return (
    <FieldContainer>
      <InputField
        placeholder={"Reminder Title"}
        name={"title"}
        // ref={textFieldRef}
        inputRef={(input: any) => (focus ? input && input.focus() : null)}
        autoFocus
        onFocus={(e: any) => {
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          );
        }}

        // disabled={pageFor == 'client' && isEdit && (formikProps.values as ReminderProps).isSystem}
      />
      <RowContainer>
        <WidthContainer width={"auto"}>
          <ToggleButtonField
            onChange={handleToggleChange}
            name='reminderType'
            options={reminderTypeOptions}
            disabled={isEdit && (formikProps.values as ReminderProps).isSystem }
          />
        </WidthContainer>
        <WidthContainer width={"100%"}>
          <SelectField
            name={"noteId"}
            placeholder={"Associated Note*"}
            options={noteSequenceOptions}
            disabled={isEdit && (formikProps.values as ReminderProps).isSystem}
        />
        </WidthContainer>
      </RowContainer>

      <RowContainer>
        <WidthContainer width={"50%"}>
          <DateField name='date' placeholder='Date' disabled={isEdit && (formikProps.values as ReminderProps).isSystem}/>
        </WidthContainer>
      {
        type === 'Note' &&
        <WidthContainer width={"50%"}>
          <TimeField name='time' placeholder='Time' />
        </WidthContainer>
      }
      </RowContainer>
      <SelectField
        name={"repeat"}
        placeholder={"Frequency"}
        options={ frequency }
        disabled={isEdit && (formikProps.values as ReminderProps).isSystem}
      />
    </FieldContainer>
  );
}

export default AddReminderFields;
