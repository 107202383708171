

const useActiveOptions = () => {

    const activeOptions: any = [
      {
        Name: "Active",
        value: true,
        Enabled: 1,
      },
      {
        Name: "Inactive",
        value: false,
        Enabled: 1,
      },
    ];
    
  return activeOptions;
};

export default useActiveOptions;
