import SvgIcon from "@mui/material/SvgIcon";

function GoBackIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.80473 2.86201C8.06507 3.12236 8.06507 3.54447 7.80473 3.80482L4.27614 7.33342H13.3333C13.7015 7.33342 14 7.63188 14 8.00008C14 8.36828 13.7015 8.66675 13.3333 8.66675H4.27614L7.80473 12.1953C8.06507 12.4557 8.06507 12.8778 7.80473 13.1381C7.5444 13.3985 7.12227 13.3985 6.86193 13.1381L2.19526 8.47148C2.07024 8.34648 2 8.17688 2 8.00008C2 7.82328 2.07024 7.65368 2.19526 7.52868L6.86193 2.86201C7.12227 2.60166 7.5444 2.60166 7.80473 2.86201Z'
          fill='#172882'
        />
      </svg>
    </SvgIcon>
  );
}

export default GoBackIcon;
