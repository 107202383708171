import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const PaigeSkeleton = () => {
    return (
        <Stack spacing={1}>
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="text" sx={{ fontSize: '5rem' }} />
    
          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rounded" height={60} />

          <Skeleton variant="text" sx={{ fontSize: '5rem' }} />

          <Skeleton variant="text" sx={{ fontSize: '5rem' }} />
        </Stack>
      );
}

export default PaigeSkeleton