/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/ban-types */
import { routesName } from '@lib/RoutesName';
import { privateAgent } from '@lib/index';
import Cookies from 'js-cookie';
import * as React from 'react';
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  React.useEffect(()=>{
    (async()=>{

      const role = Cookies.get("role");
      if(role?.toLocaleLowerCase() === 'admin'){
        navigate("/users", { replace: true });
      }else if(role?.toLocaleLowerCase() ==='coach'){
        navigate("/coach/clients", { replace: true });
      }else if(role?.toLocaleLowerCase() ==='client'){
        navigate("/notes", { replace: true });
      }else{
        navigate("/users");
      }

      if(!role){
        const userId = Cookies.get("userId");
        const user = await privateAgent.get(routesName.UserRoute({id: userId}).get_single_user);
  
        if(user?.data?.result?.role){
          Cookies.set("role",user?.data?.result?.role?.name);
        }
        if(user?.data?.result?.role?.name.toLowerCase() === 'admin'){
          navigate("/users", { replace: true });
        }else if (user?.data?.result?.role?.name.toLowerCase() ==='coach'){
          navigate("/coach/clients", { replace: true });
        }else if (user?.data?.result?.role?.name.toLowerCase() ==='client'){
          navigate("/notes", { replace: true });
        }
        else{
          navigate("/users");
        } 
      }

    })();
   
  },[])
  
  return (
   <>
      Loading...
   </>
       

 );
}

export default Dashboard;