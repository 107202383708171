import styled from "@emotion/styled";

export const Tag = styled.span`
  margin-left: 10px;
  padding: 5px 6px;
  color: #fff;
  border-radius: 100px;
  background: #f08812;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
