import SvgIcon from "@mui/material/SvgIcon";

function LogOutIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 20 20'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          d='M11.6666 16.6667H4.99998C4.0795 16.6667 3.33331 15.9205 3.33331 15V5C3.33331 4.07953 4.0795 3.33334 4.99998 3.33334H11.6666M8.33331 10H17.5M17.5 10L15 12.5M17.5 10L15 7.5'
          stroke='#172882'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
}

export default LogOutIcon;
