import * as Yup from "yup";

export default function ResetPwValidationSchema() {
  return Yup.object().shape({
    password: Yup.string().required("Required").min(3).max(20),
    confirmPassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password")], "Your passwords do not match."),
  });
}
