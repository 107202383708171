import { useState, useMemo } from "react";
import {
  privateAgent,
  Button,
  DialogBox,
  TextButton,
  AlertDialog,
  useTitle,
} from "../../../lib";
import { DataTable } from "../../../lib/Forms/DataTable";
import { Formik, Form } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { routesName } from "../../../lib/RoutesName/Routes";
import { Mode } from "../../../components/shared/dropdown/mode";
import { useSnackbar } from "notistack";
import AddClassesValidationSchema from "../../../components/formFields/Classes/AddClassesValidationSchema";
import ClassModeMenu from "../../../components/formFields/Classes/ClassModeMenu";
import { AddCallsToActionProps } from "../../../lib/Types/CallsToActionProps";
import AddCallsToActionFields from "../../../components/formFields/CallsToAction/AddCallsToActionFields";
import {
  callToActionInitialValues,
  editIntitialValues,
} from "../../../components/formFields/CallsToAction/calls-to-action-helpers";
import { editClasesIntitialValues } from "../../../components/formFields/Classes/classes-helpers";
import { MRT_ColumnDef } from "material-react-table";
import {
  ButtonContainer,
  TopContainerNoMargin,
  UsersContainer,
  TableContainer,
} from "src/styles/Containers";
import { TitleLarge } from "src/styles/Typography";

function CallsToActionPage() {
  useTitle("Calls To Action | Paige");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openAddCallsToActionDialog, setOpenAddCallsToActionDialog] =
    useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [callToActionId, setCallToActionId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [fieldInitialValues, setFieldInitialValues] =
    useState<AddCallsToActionProps>(callToActionInitialValues);
  const queryClient = useQueryClient();

  let { isLoading, data } = useQuery(["CallsToActionQuery"], async () => {
    const { data } = await privateAgent.get(
      routesName.CallsToActionRoute({}).get_callstoaction
    );

    if (data.status === 200) {
      const results = data.result.items;
      return results;
    }
  });

  const handleSubmit = async (values: AddCallsToActionProps) => {
    try {
      if (isEdit) {
        const res = await privateAgent.put(
          routesName.CallsToActionRoute({ id: callToActionId })
            .update_callstoaction,
          values
        );
        if (res.data && res.data.status === 200) {
          queryClient.invalidateQueries("CallsToActionQuery");
          setOpenAddCallsToActionDialog(false);
          enqueueSnackbar("Calls to action edited successfully", {
            variant: "success",
          });
        }
      } else {
        const res = await privateAgent.post(
          routesName.CallsToActionRoute({}).add_callstoaction,
          values
        );
        if (res.status === 201) {
          queryClient.invalidateQueries("CallsToActionQuery");
          setOpenAddCallsToActionDialog(false);
          enqueueSnackbar("Calls to action  added successfully", {
            variant: "success",
          });
        }
      }
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.error ?? "", { variant: "error" });
    }
  };

  const handleDelete = async () => {
    try {
      const res = await privateAgent.delete(
        routesName.CallsToActionRoute({ id: callToActionId })
          .delete_callstoaction
      );
      if (res.status === 200) {
        queryClient.invalidateQueries("CallsToActionQuery");
        setOpenDeleteDialog(false);
        enqueueSnackbar("Class deleted successfully", { variant: "success" });
      }
    } catch (error: any) {
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
    }
  };

  const handleRowClick = (column: any, row: any) => {
    if (column.id === "name") {
      setOpenAddCallsToActionDialog(true);
      setFieldInitialValues(editClasesIntitialValues(row.original));
      setCallToActionId(row.original.id);
      setIsEdit(true);
    }
  };

  type CallsToAction = {
    id: number;
    name: string;
  };

  //grid defn
  const columns = useMemo<MRT_ColumnDef<CallsToAction>[]>(
    () => [
      {
        accessorFn: (row) => `${row.name}`,
        minSize: 150,
        id: "name",
        header: "Name",
      },
      {
        id: "actions",
        header: "Actions",
        headerClassName: "header-row",
        flex: 1,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
          className: "action",
        },
        size: 50,
        enableResizing: false, //disable resizing for this column
        Cell: ({ cell }) => (
          <ClassModeMenu
            id={0}
            onItemClick={function (id: number, mode: Mode): void {
              if (mode == "edit") {
                setOpenAddCallsToActionDialog(true);
                setFieldInitialValues(editIntitialValues(cell.row.original));
                setCallToActionId(cell.row.original.id.toString());
                setIsEdit(true);
              }
              if (mode === "delete") {
                // const test = params.row.id
                setCallToActionId(cell.row.original.id.toString());
                setOpenDeleteDialog(true);
              }
              if (mode === "duplicate") {
                // const test = params.row.id
                setOpenAddCallsToActionDialog(true);
                setCallToActionId(cell.row.original.id.toString());
                setFieldInitialValues(editIntitialValues(cell.row.original));
                setIsEdit(true);
              }
            }}
          />
        ),
      },
    ],
    []
  );
  //end of grid defn

  return (
    <>
      <AlertDialog
        onConfirm={() => handleDelete()}
        onClose={() => setOpenDeleteDialog(false)}
        isOpen={openDeleteDialog}
        title={"Are you sure you want to delete this class?"}
        content={
          "Deleting this class will permanently erase any data associated with this class."
        }
        btnText={{ cancel: "Cancel", action: "Delete" }}
      />
      <DialogBox
        isOpen={openAddCallsToActionDialog}
        onClose={() => setOpenAddCallsToActionDialog(false)}
        title={isEdit ? "Edit Call to Action" : "Add Call to Action"}
        content={
          <Formik
            initialValues={fieldInitialValues}
            onSubmit={handleSubmit}
            validationSchema={AddClassesValidationSchema}
          >
            {(formikProps) => (
              <Form>
                <AddCallsToActionFields />
                <ButtonContainer>
                  <TextButton
                    label={"Cancel"}
                    onClick={() => setOpenAddCallsToActionDialog(false)}
                  ></TextButton>
                  <Button
                    label={"Save"}
                    type={"submit"}
                    disabled={!formikProps.isValid}
                  ></Button>
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        }
      />
      <TopContainerNoMargin>
        <TitleLarge>Calls to Action</TitleLarge>
        <Button
          label={"+ Add Call to Action"}
          onClick={() => {
            setOpenAddCallsToActionDialog(true);
            setIsEdit(false);
            setFieldInitialValues(callToActionInitialValues);
          }}
        ></Button>
      </TopContainerNoMargin>
      <UsersContainer>
        <TableContainer>
          {data && data.length > 0 && (
            <DataTable
              columns={columns}
              data={data}
              onCellClick={handleRowClick}
            />
          )}
        </TableContainer>
      </UsersContainer>
    </>
  );
}

export default CallsToActionPage;
