import { NoteProps, NoteType } from "@lib/Types/NoteProps";

export const noteInitialValue: {[key: string]: any, noteType: NoteType|undefined} = {
  title: "",
  notificationText: "",
  behaviorId: "",
  callToActionId: "",
  noteType: undefined,
  isSystem: false,
  link: "",
  noteSteps: [{ text: "" }],
};

export const noteEditValue = (notes: NoteProps) => {
  const value: any = {
    title: notes.title,
    notificationText: notes.notificationText,
    behaviorId: notes.behaviorId,
    callToActionId: notes.callToActionId,
    noteSteps: notes.noteSteps,
    noteType: notes.noteType,
    isSystem: notes.isSystem,
    link: notes.noteType.indexOf("LINK") > -1 ? notes.link : "",
  };
  for (let i = 0; i < notes.noteSteps.length; i++) {
    value[`noteSteps${i}`] = { text: notes.noteSteps[i].text };
  }
  return value;
};


class NoteService  {

  note: NoteProps;
  constructor(note: NoteProps){
    this.note = note
  }
  isSystem(note: NoteProps){
    return note.isSystem
  }
  isRitual(note:NoteProps){
    return note.noteType == "RITUAL"
  }
  isRecordStats(note:NoteProps){
    return note.noteType == "RECORD_STATS"
  }
  isRecordWeight(note: NoteProps){
    return note.noteType == "RECORD_WEIGHTS"
  }

}
export default NoteService


class NoteNavigationService {

  clientId: number;
  noteId: number;
  constructor(clientId: number, noteId: number){
    this.clientId = clientId
    this.noteId = noteId
  }

  navigateTo(){
    return ""
  }
}


