/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SButton, BtnFields } from "../../styles/Buttons";

export type variety = "normal" | "error";

// type BtnFields = {
//   label?: string;
//   loading?: boolean;
//   disabled?: boolean;
//   width?: string;
//   variety?: variety;
//   onClick?: (e: React.MouseEvent<HTMLElement>) => void;
// };

// const SButton = styled(Button)<BtnFields>`
//   height: 42px;
//   width: auto;
//   left: 0px;
//   top: 0px;
//   border-radius: 4px;
//   padding: 10px 24px 10px 24px;
//   background-color: ${(props) =>
//     props.variety == "error" ? "#DD4343" : "#172882"};
//   color: white;
//   text-transform: none;
//   font-weight: 700;
//   font-size: 1rem;

//   &.Mui-disabled {
//     pointer-events: none;
//     opacity: 0.5;
//     color: white;
//     background-color: #172882 !important;
//   }
// `;

const MyLoadingButton = ({
  label,
  loading,
  disabled,
  onClick,
  variety,
}: BtnFields) => {
  return (
    <>
      {/* <LoadingButton
        color='secondary'
        onClick={onClick}
        loading={loading}
        loadingPosition='start'
        startIcon={<SaveIcon />}
        variant='contained'
      >
        <span>{label}</span>
      </LoadingButton> */}
    </>
  );
};

export default MyLoadingButton;

MyLoadingButton.defaultProps = {
  type: "text",
  disabled: false,
};
