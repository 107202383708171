import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import { Navigate, Route, useLocation, useNavigate, useParams } from "react-router-dom";
import { tab } from "@testing-library/user-event/dist/types/setup/directApi";
import SystemNotePage from "./SystemNotePage";
import NavigateBack from "@components/shared/NavigateBack";
import NotePage from "@pages/Admin/SystemData/Notes/NotePage";
import ReminderPage from "@pages/Admin/SystemData/Reminder/ReminderPage";
import SequencePage from "@pages/Admin/SystemData/Sequence/SequencePage";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface CoachTabsProps{
  tabIndex?: number
}

export default function SystemDataTabs({tabIndex}: CoachTabsProps) {
  const [value, setValue] = React.useState(Number(tabIndex ?? '0'));

  const params = useParams();
  const { clientId } = params;
  const navigate = useNavigate()
  const location = useLocation();

  React.useEffect(()=>{
    if(location.pathname.includes('notes')){
      setValue(0)
    }
    if(location.pathname.includes('sequences')){
      setValue(1)
    }
    if(location.pathname.includes('reminders')){
      setValue(2)
    }
  }, [location.pathname])

  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if(value == newValue) return

      let url = `/system/notes`
      if(newValue == 1){
        url = `/system/sequences`
      }
      if(newValue == 2){
        url = `/system/reminders`
      }

      navigate(url, {replace: true})

    
    // navigate(0)
 
  };
  const StyledBox = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `;

  const StyledTabs = styled(Tabs)`
    & .MuiTab-root {
      text-transform: none;
      font-family: Nunito;
      font-size: 16px;
      font-weight: 500;
    }
    & .Mui-selected {
      font-weight: 700;
    }
  `;

  return (
    <StyledBox sx={{ width: "100%" }}>
      <NavigateBack text='' link={"/"} />
      <Box sx={{}}>
        <StyledTabs
          id='asdf'
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='Notes' {...a11yProps(0)} />
          <Tab label='Sequences' {...a11yProps(1)} />
          <Tab label='Reminder' {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <NotePage pagefor='admin'/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ReminderPage pagefor='admin'/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SequencePage pagefor='admin'/>
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <SequencePage pagefor={'coach'}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ReminderPage pagefor={'coach'}/>
      </TabPanel> */}
    </StyledBox>
  );
}
