/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { useEffect, useState } from 'react';

type InputType = 'text' | 'date' | 'datetime-local'
type InputFieldPrps = {
  label: string;
  name: string;
  placeholder: string;
  value: string;
  error?: any;
  type: InputType
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>

  ) => void;

  onBlur?: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;

  onFocus?: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
};




const InputField = ({ name, label, placeholder, value, onChange, onBlur, onFocus, error, type }: InputFieldPrps) => {


  return (
    <>
      <div>
        <label htmlFor="crud-form-1" className="form-label">
          {label}
        </label>
        <textarea
          name={name}
          id={name}
          // @ts-ignore
          onChange={(e) => onChange(e)}
          className="form-control w-full"
          placeholder={placeholder}
        >{value}</textarea>
        {error && error[`${name}`] && <div className='text-[#f87171]'> {error[`${name}`]}</div>}
      </div>
    </>
  );
};

export default InputField;

InputField.defaultProps = {
  type: "text",
};
