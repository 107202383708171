

export const Helpers = {

  FullName(firstName:string, middleName:string, lastName:string){
    if(!firstName || firstName.trim() ===''){
      return "-"
    }
    return this.CapitaliseWords(`${firstName} ${middleName ? middleName:""} ${lastName?lastName:''}`);
  },

  CapitaliseWords(sentence: string){
    if(!sentence) return ""
    const words = sentence.split(' ');

    const capitalizedWords = words.map(word => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return firstLetter + restOfWord;
    });

    return capitalizedWords.join(' ');
  }
}
