import SvgIcon from "@mui/material/SvgIcon";

function ClassesIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <g id='pencil-file_svgrepo.com'>
          <path
            id='Vector'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.66667 0.666668C6.48985 0.666668 6.32029 0.736908 6.19526 0.861928L2.19526 4.86193C2.07024 4.98695 2 5.15652 2 5.33333V13.3333C2 14.4379 2.89543 15.3333 4 15.3333H4.66667C5.03485 15.3333 5.33333 15.0349 5.33333 14.6667C5.33333 14.2985 5.03485 14 4.66667 14H4C3.63181 14 3.33333 13.7015 3.33333 13.3333V6H6.66667C7.03487 6 7.33333 5.70152 7.33333 5.33333V2H12C12.3682 2 12.6667 2.29848 12.6667 2.66667V4.66667C12.6667 5.03485 12.9651 5.33333 13.3333 5.33333C13.7015 5.33333 14 5.03485 14 4.66667V2.66667C14 1.5621 13.1046 0.666668 12 0.666668H6.66667ZM6 4.66667H4.27614L6 2.94281V4.66667ZM14.7475 7.13807C13.9665 6.35702 12.7002 6.35702 11.9191 7.13807L10.7993 8.25793L7.5286 11.5286C7.44313 11.6141 7.38253 11.7211 7.35327 11.8383L6.6866 14.505C6.62977 14.7321 6.69633 14.9725 6.86193 15.1381C7.02753 15.3037 7.26787 15.3702 7.495 15.3134L10.1617 14.6467C10.2789 14.6175 10.3859 14.5569 10.4714 14.4714L13.7037 11.2391L14.8619 10.0809C15.643 9.2998 15.643 8.03354 14.8619 7.25247L14.7475 7.13807ZM12.2045 8.73827L12.8619 8.08087C13.1223 7.82053 13.5444 7.82053 13.8047 8.08087L13.9191 8.19527C14.1795 8.4556 14.1795 8.87774 13.9191 9.13807L13.2415 9.81573L12.2045 8.73827ZM11.2615 9.68127L12.2985 10.7587L9.6592 13.398L8.2496 13.7504L8.602 12.3408L11.2615 9.68127Z'
            fill='white'
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default ClassesIcon;
