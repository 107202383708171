import dayjs, { Dayjs } from "dayjs";
//import { AddUserProps } from "../../../lib"
//import { AddReminderProps } from "../../../lib/Types/ReminderProps"
import { AddUserProps } from "@lib/Types";
import { AddReminderProps, ReminderType } from "../../../../lib/Types/ReminderProps"

export const reminderIntialValue :AddReminderProps =  {
    title: "",
    reminderType: "NOTE",
    datetime: null,
    repeat: "Daily",
    userId: 0,
    date: dayjs(),
    time: dayjs(),
    datetimeStr:"",
    isActive:1
} 

export const editReminderIntitialValues=(item: any): AddReminderProps=>{
    return {
        title:item.title ?? '',
        repeat: item.repeat ?? '',
        datetime: item?.datetime ?? '', 
        reminderType: item?.reminderType,
        userId: item.userId,
        noteId: item?.reminderType ==="NOTE" ? item?.noteId: item?.sequenceId,
        date: dayjs(item?.datetime),
        time: dayjs(item?.datetime),
        datetimeStr: item?.datetimeStr,
        isActive:1
    }
}

export const formattedReminderValues=(values: any, clientId: number): AddReminderProps =>{
    

    const dateString = values.date.format('YYYY-MM-DD');
    const timeString = values.time.format('HH:mm:ss');

    const datetime = dayjs(`${dateString} ${timeString}`, 'YYYY-MM-DD HH:mm:ss');
    

    return {
        title:values.title ?? '',
        repeat: values.repeat ?? '',
        datetime:  datetime.toDate(),
        datetimeStr: `${dateString} ${timeString}`,
        noteId: values?.reminderType as ReminderType == "NOTE" ? values.noteId: null,
        sequenceId: values?.reminderType as ReminderType == "SEQUENCE" ? values.noteId : null, 
        reminderType: values?.reminderType ,
        userId: clientId,
        isActive: 1,
        createdBy: clientId,
    }
}