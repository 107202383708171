/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Button, styled } from "@mui/material";
import React from "react";
import { AltButton } from "src/styles/Buttons";
import { variety } from "./Button";

type BtnFields = {
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  width?: string;
  variety?: variety;
  hide?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

// const SButton = styled(Button)(({ theme }) => {
//   return {
//     color: theme.palette.paigeBlue.primary,
//     textTransform: "none",
//     fontSize: "1rem",
//     fontWeight: "700",
//   };
// });

const TextButton = ({ label, loading, disabled, onClick }: BtnFields) => {
  return (
    <React.Fragment>
      <AltButton onClick={onClick}>{label}</AltButton>
    </React.Fragment>
  );
};

export default TextButton;

TextButton.defaultProps = {
  type: "text",
  disabled: false,
};
