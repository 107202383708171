import { InputField, SelectField } from "../../../lib";
import { useFormikContext } from "formik";
import useBehaviorOptions from "../../../lib/Hooks/options/useBehaviorOption";
import useCallToActionOptions from "../../../lib/Hooks/options/useCalltoActionOption";
import {
  FieldContainer,
  MainFieldContainer,
  MainFieldContainer1,
} from "src/styles/Containers";
import { Title } from "src/styles/Typography";

interface AddLinkNoteFieldsProps {
  isEdit?: boolean;
}

function AddLinkNoteFields({ isEdit }: AddLinkNoteFieldsProps) {
  const formikProps = useFormikContext();
  const behaviorOptions = useBehaviorOptions();
  const callToActionOptions = useCallToActionOptions();

  return (
    <FieldContainer>
      <MainFieldContainer>
        <MainFieldContainer1>
          <Title>{"General"}</Title>
          <InputField placeholder={"Note Title*"} name={"title"} />
          <InputField
            placeholder={"Notification Text*"}
            name={"notificationText"}
          />
          <InputField placeholder={"Link*"} name={"link"} />
          <SelectField
            name={"behaviorId"}
            placeholder={"Behaviour to Eliminate or Create"}
            options={behaviorOptions}
          />
          <SelectField
            name={"callToActionId"}
            placeholder={"Call to Action"}
            options={callToActionOptions}
          />
        </MainFieldContainer1>
      </MainFieldContainer>
    </FieldContainer>
  );
}

export default AddLinkNoteFields;
