
import axios from 'axios';
//@ts-ignore
import Cookies from 'js-cookie';
import { BASE_API_ROUTE, routesName } from '../RoutesName/Routes';


const baseURL = BASE_API_ROUTE;

const privateAgent = axios.create({
  baseURL,
});

const publicAgent = axios.create({
  baseURL,
});

privateAgent.interceptors.request.use(
  (config:any) => {
    const accessToken = Cookies.get('token');
    if (accessToken && config.headers)
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
privateAgent.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = Cookies.get('refreshToken');
    if(!refreshToken)
      return;

      try {

        const response = await axios.post(routesName.AuthRoute({}).refreshToken,{token:`${refreshToken}`});
        if(response.status == 200){
          const token = response.data.result.jwtToken;
          const refreshToken = response.data.result.refreshToken
          Cookies.set("token", token);
          Cookies.set("refreshToken", refreshToken);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        } 
        return privateAgent(originalRequest);
        
      } catch (error:any) {
        if(error?.response?.data?.status == 404){
          Cookies.remove("token")
          Cookies.remove("refreshToken")
        }
       
        return Promise.reject(error);
      }
  }
  return Promise.reject(error);
});

export { privateAgent, publicAgent };
