import styled from "@emotion/styled";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

export const SingleRowSelectContainer = styled.div`
  width: 39%;
  
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const FormInputActionIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 16px;

  flex: none;
  order: 2;
  flex-grow: 0;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 12px;
`;

export const PaigeAiFormBtnContainer = styled.div`
  display: flex;
  width: 49.5%;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  text-align: right;
  
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const MainFieldContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 5px 0px #0000000;
  width: 100%;
  
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
export const MainFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 5px 0px #0000000;
  width: 100%;
`;
export const EvenColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
export const MainFieldContainer1 = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  @media (max-width: 800px) {
    width: 90%;
  }
  @media (min-width: 800px) and (max-width: 1000px) {
    width: 60%;
  }
`;
export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  margin-bottom: 16px;
`;
export const FieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
`;
export const SelectFieldContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  text-align: right;
  // flex-wrap: wrap;
`;
export const ButtonContainerEnd = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  text-align: right;
  // flex-wrap: wrap;
`;
export const EditorRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & .text-editor {
    width: 100%;
    @media (max-width: 800px) {
      width: 100%;
    }
    @media (min-width: 800px) and (max-width: 1000px) {
      width: 100%;
    }
  }
  & .ql-editor {
    min-height: 100px;
    padding: 12px 16px;
  }
`;
export const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
  & .disabled-quill{
    opacity:0.6;
  }
`;
export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  & .MuiToggleButtonGroup-root {
    height: 40px;
  }
`;
export const SeqRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;
export const PreviewContainer = styled.div`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  & p {
    color: #172882;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const TableContainer = styled.div`thead tr th:last-child, tbody tr td:last-child{ position: sticky; right: 0; }`;
export const DataTableContainer = styled.div`
  & .MuiPaper-root {
    box-shadow: none !important;
    .MuiTablePagination-displayedRows:before {
      content: "Showing ";
      @media (max-width: 800px) {
        content: "";
      }
    }
    & .isRowDraggable {
      & td:first-of-type {
        padding: 0;
      }
      & td:first-of-type button {
        padding: 0;
      }
      & th:first-of-type {
        padding: 0 !important;
        width: 16px !important;
      }
      & td:nth-of-type(2) {
        color: #172882;
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        cursor: pointer;
      }
    }
    & .isRowExpandable {
      & th:nth-of-type(2) {
        padding: 0 !important;
        width: 28px !important;
      }
      & td:nth-of-type(2) {
        padding: 0 !important;
      }
      & td:nth-of-type(3) {
        color: #172882 !important;
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        cursor: pointer;
      }
    }
    & .subRow.isRowExpandable {
      & td:nth-of-type(3) {
        padding-left: 30px !important;
      }
    }
    & .subRow.isRowDraggable.isRowExpandable {
      & td:first-of-type button,
      td:nth-of-type(2) button {
        display: none;
      }
    }
    & table {
      .Mui-TableHeadCell-ResizeHandle-Wrapper:active > hr {
        background-color: #172882;
      }
      & .subRow {
        td {
          background: #f5f7ff;
        }
        td:last-of-type {
          padding-right: 30px !important;
        }
      }
      & th {
        align-items: center;
        align-self: stretch;
        font-family: Nunito;
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        color: rgb(36, 36, 36);
        & .Mui-TableHeadCell-Content-Wrapper {
          white-space: nowrap;
        }
        & .Mui-TableHeadCell-ResizeHandle-Wrapper {
          display: none;
        }
      }
      & th:hover {
        & .Mui-TableHeadCell-ResizeHandle-Wrapper {
          display: block;
        }
      }
      & .MuiTableRow-root {
        & td:first-of-type {
          color: #172882;
          font-family: Nunito;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          cursor: pointer;
        }
      }
      & td {
        align-self: stretch;
      }
    }
    & .MuiToolbar-root {
      & .MuiBox-root {
        & .MuiBox-root {
          width: 100%;
          display: inline-block;
          & .MuiTablePagination-root {
            & .MuiTablePagination-displayedRows {
              position: absolute;
              left: 0;
            }
            & .MuiInputBase-root {
              border-radius: 8px;
              border: 1px solid #e2e6e8;
            }
          }
        }
      }
    }
    & .MuiTableCell-sizeMedium {
      padding: 12px 16px;
    }
    & .action button {
      padding: 0;
    }
    & .MuiTablePagination-actions .Mui-disabled {
      color: rgba(23, 40, 130, 0.26);
    }
    & .MuiTablePagination-actions {
      position: absolute;
      left: 145px;
      & button{
        padding-left:0;
        padding-right:2px;
        padding-top: 0;
        padding-bottom: 0;
      }
      & svg {
        color: #172882;
      }
    }
    
  @media (max-width: 800px) {
    & .MuiTablePagination-actions {
      position: absolute;
      left: 99px;
      margin-left: 0;
      & button{
        padding-left:0;
        padding-right:2px;
        svg{
          width:22px;
        }
      }
    }
  }
    & .MuiMenu-list {
      & .Mui-selected {
        background-color: rgba(23, 40, 130, 0.12);
      }
    }
    & .MuiTablePagination-actions .MuiButtonBase-root:hover {
      background-color: rgba(23, 40, 130, 0.04);
    }
    & .MuiBadge-standard {
      display: none;
    }
  }
`;
export const Width30Container = styled.div`
  width: 30%;
`;
export const NoteContainer = styled.div``;
export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  // margin-bottom: 8px;
  flex-wrap: wrap;
`;
export const TopContainerNoMargin = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-wrap: wrap;
`;
export const TopContainerSmallMargin = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-wrap: wrap;
`;
export const UsersContainer = styled.div`
  padding: 16px;
  border-radius: 4px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px #0000000;
`;

export const AddStep = styled.div`
  color: #172882;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  padding: 10px 16px;
`;
export const Steps = styled.div`
  color: #242424;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const Delete = styled.div`
  color: #dd4343;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
`;

export const RowTwoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
`;
export const SearchContainer = styled.div`
  display: flex;
  & .MuiInputLabel-shrink {
    -webkit-transform: translate(20px, -9px) scale(0.75);
    -moz-transform: translate(20px, -9px) scale(0.75);
    -ms-transform: translate(20px, -9px) scale(0.75);
    transform: translate(20px, -9px) scale(0.75);
  }
  & .MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
    padding: 8.5px 14px;
    transform: unset;
  }
  & .MuiOutlinedInput-notchedOutline {
    padding: 8.5px 14px;
  }
  & .MuiInputBase-input {
    padding: 8.5px 14px;
  }
`;

//#region Layout
export const LayoutContainer = styled.div`
  background: #f5f6f6;
  height: 100%;
  & .hidden {
    display: none;
  }
  @media (max-width: 800px) {
    .hideNavBar .sideBarContainer {
      display: none;
    }
    & .showNavBar .sideBarContainer {
      width: 100% !important;
      display: flex;
    }
  }
`;
export const MainContainer = styled.div`
  display: flex;
  height: 100%;
  // height: calc(100% - 48px);
  // flex-direction: row;
  // overflow: auto;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  // height: calc(100vh - 259px);
  // position: absolute;
  // top: 56px;
  // left: 211px;
  padding: 24px;
  // gap: 24px;
  width: calc(100% - 259px);
  //width: calc(100vw - 280px);
  vertical-align: top;
  //height: calc(100% - 56px);
  // height: calc(100vh - 259px);
  // width: 100%;
  margin-bottom: 60px;
`;
export const Container = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  // flex-direction: row;
`;

export const RightContainer = styled.div`
  position: absolute;
  // left: 40%;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 1) 19.5%,
      rgba(241, 243, 255, 1) 19.5%,
      rgba(241, 243, 255, 1) 100%
    );
    z-index: -1;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const LeftContainer = styled.div`
  display: inline-block;
  width: 40%;
  height: 100%;
  // margin-left: 4em;
  // margin-right: 4em;
  // margin-top: 2em;
  position: relative;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
export const ImageContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: -1;
  top: 0;
  @media (max-width: 800px) {
    display: none;
  }
`;
//#endregion

//#region NavBar

export const NavContainer = styled.div`
  height: 24px;
  padding: 16px 24px 16px 24px;
  justify-content: space-between;
  background: #ffffff;
  display: flex;
  flex-direction: row;
`;

export const NavLogoContainer = styled.div`
  height: 24px;
  gap: 12px;
  display: flex;
  flex-direction: row;
`;

export const MenuRounded = styled(MenuRoundedIcon)`
  fill: #172882;
  cursor: pointer;
  @media (min-width: 800px) {
    display: none;
  }
`;

export const NavLogOutContainer = styled.div`
  width: Hug (164px);
  height: Hug (22px);
  gap: 16px;
  display: flex;
  flex-direction: row;
`;

//#endregion
export const LinkButton = styled.div`
  color: #172882;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;

  & span {
    display: inline-block;
    vertical-align: middle;
  }

  & svg {
    height: 16px;
    display: inline-block;
    vertical-align: middle;
  }
`;
export const Tag = styled.span`
  margin-left: 10px;
  padding: 5px 20px;
  background-color: #172882;
  color: #fff;
  border-radius: 15px;
  font-size: 14px;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  padding-left: 4em;
  padding-top: 4em;

  // position: absolute;
  // left: 5.56%;
  // right: 84.62%;
  // top: 7.81%;
  // bottom: 88.28%;
`;
export const FormContainer = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;
export const Dot = styled.div`
  height: 5px;
  width: 5px;
  background-color: #f08812;
  margin-left: 4px;
  vertical-align: super;
  border-radius: 50%;
  display: inline-block;
`;

export interface WidthProps {
  width?: string;
}

export const WidthContainer = styled.div<WidthProps>`
  width: ${(props) => (props.width ? props.width : "100%")};
`;

export const RitualAnswersContainer = styled.div`
  padding: 16px;
  border-radius: 4px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px #0000000;
`;