import { device } from "./Breakpoints";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";

export const Title = styled(Typography)`
  font-family: Nunito;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.28px;
  letter-spacing: 0em;
  text-align: left;
  color: #242424;
`;
export const TitleLarge = styled(Typography)`
  font-family: Nunito;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color: #242424;
`;
export const Header = styled(Typography)`
  font-family: Nunito;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #242424;
  display: flex;
  align-items: center;
`;
export const SeqHeader = styled(Typography)`
  font-family: Nunito;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #242424;
  display: flex;
  align-items: center;
`;

export const UserName = styled(Typography)`
  font-weight: 400;
  size: 1rem;
  color: #45565e;
`;

export const Label = styled(Typography)`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  color: #242424;
`