import { CheckBox, InputField, SelectField } from "../../../lib";
import useCallToActionOptions from "../../../lib/Hooks/options/useCalltoActionOption";
import useBehaviorOptions from "../../../lib/Hooks/options/useBehaviorOption";
import useStatusOptions from "../../../lib/Hooks/options/useStatusOptions";
import {
  MainFieldContainer,
  MainFieldContainer1,
  FieldContainer,
} from "src/styles/Containers";
import { Title } from "src/styles/Typography";

interface AddSequenceFieldsProps {
  isEdit?: boolean;
}

function AddSequenceFields({ isEdit }: AddSequenceFieldsProps) {
  const callToActionOptions = useCallToActionOptions();
  const behaviorOptions = useBehaviorOptions();
  const statusOptions = useStatusOptions();

  return (
    <FieldContainer>
      <MainFieldContainer>
        <MainFieldContainer1>
          <Title>{"General"}</Title>
          <InputField placeholder={"Sequence Name*"} name={"title"} />
          <SelectField
            name={"behaviorId"}
            placeholder={"Behaviour to Eliminate or Create"}
            options={behaviorOptions}
          />
          <SelectField
            name={"isActive"}
            placeholder={"Sequence Status"}
            options={statusOptions}
          />
          <CheckBox
            name={"overrideRegular"}
            placeholder={"Override Regular Notes During Sequence"}
          />
          <CheckBox name={"isRitualDisable"} placeholder={"Disable Ritual"} />
        </MainFieldContainer1>
      </MainFieldContainer>
    </FieldContainer>
  );
}

export default AddSequenceFields;
