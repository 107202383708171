import { useState, useEffect } from 'react'
import { privateAgent } from '../../Requests/AuthRequests';
import { routesName } from '../../RoutesName/Routes';
import { ClassesProps } from '../../Types';
import { BehaviorCategoryProps } from '../../Types/BehaviorCategoryProps';
import { NoteProps } from '../../Types/NoteProps';

 const useNotesOptions = (clientId?: string,showSystem:boolean = false) => {
 const [options, setOptions] = useState([]);

 const fetchNotesOfClient = async()=>{

    const { data: userData } = await privateAgent.get(
        routesName.UserRoute({ id: clientId }).get_single_user
      );

     const res = await privateAgent.get(routesName.NoteRoute({id:clientId}).get_notes_by_clientId)
     if(res.status==200){
        const options = res.data.result.items.filter((item:NoteProps) =>{
            if(showSystem){
                return true
            }
            else if(item.isSystem && item.noteType == "RITUAL" && !userData?.result?.hasAdvancedSettings){
                return false
            }else
                return    !item.isSystem || (item.isSystem && item.noteType != "RECORD_STATS" && item.noteType != "RECORD_WEIGHTS");
        }).map((item: NoteProps) => {

            return {
                'Name': `${item.title}`,
                'value': item.id,
                'Enabled': 1
            }
        },{
            enabled: !!clientId
        })
        setOptions(options)
     }else{
      setOptions([])
     }

 }

 useEffect(() => {
    fetchNotesOfClient()

  return () => {
    setOptions([])
  };
 }, []);

 return options;
};

export default useNotesOptions
