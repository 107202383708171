import { useState, useEffect } from 'react'
import { privateAgent } from '../../Requests/AuthRequests';
import { routesName } from '../../RoutesName/Routes';
import { UserRoleProps } from '../../Types';

 const useUserRoleOptions = () => {
 const [userRoleOptions, setUserRoleOptions] = useState([]);

 const fetchUserTypes = async()=>{
     const res = await privateAgent.get(routesName.UserRole({}).get_userrole)
     if(res.status==200){
        const classes = res.data.result.items.map((item: UserRoleProps) => {

            return {
                'Name': `${item.name}`,
                'value': item.id,
                'Enabled': 1
            }
        })
       
        setUserRoleOptions(classes)
     }else{
      setUserRoleOptions([])
     }

 }

 useEffect(() => {
    fetchUserTypes()

  return () => {
    setUserRoleOptions([])
  };
 }, []);

 return userRoleOptions;
};

export default useUserRoleOptions
