
function NoPermissionPage() {
  
  return (
    <>
      You don't have permission to access this page
    </>
  );
}

export default NoPermissionPage;
