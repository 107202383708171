import SvgIcon from "@mui/material/SvgIcon";

function UsersIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          d='M13.6667 14C14.5871 14 15.3333 13.2538 15.3333 12.3333C15.3333 10.7817 14.0321 10.1325 12.6667 9.86113M9.99999 7.33333C11.4727 7.33333 12.6667 6.13943 12.6667 4.66667C12.6667 3.19391 11.4727 2 9.99999 2M2.33332 14.0001H9.66666C10.5871 14.0001 11.3333 13.2539 11.3333 12.3334C11.3333 9.61293 7.33332 9.66673 5.99999 9.66673C4.66666 9.66673 0.666656 9.61293 0.666656 12.3334C0.666656 13.2539 1.41285 14.0001 2.33332 14.0001ZM8.66666 4.66667C8.66666 6.13943 7.47272 7.33333 5.99999 7.33333C4.52723 7.33333 3.33332 6.13943 3.33332 4.66667C3.33332 3.19391 4.52723 2 5.99999 2C7.47272 2 8.66666 3.19391 8.66666 4.66667Z'
          stroke='white'
          strokeWidth='1.25'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
}

export default UsersIcon;
