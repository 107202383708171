import { useState, useEffect } from "react";
import { privateAgent } from "../../Requests/AuthRequests";
import { routesName } from "../../RoutesName/Routes";
import { UserRoleProps } from "../../Types";

const useStatusOptions = () => {
  const [statusOptions, setStatusOptions] = useState([]);

  const status = [
    {
      Name: "Active",
      value: 1,
      Enabled: 1,
    },
    {
      Name: "Inactive",
      value: 0,
      Enabled: 1,
    },
  ];

  return status;
};

export default useStatusOptions;
