/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { useEffect, useState } from 'react';

type InputFieldPrps = {
  label: string;
  name: string;
  placeholder:string;
  value: string;
  error?: any;
  rows: number;

  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;

  onBlur?: (
    e: React.ChangeEvent<HTMLTextAreaElement>

  ) => void;

  onFocus?: (
    e: React.ChangeEvent<HTMLTextAreaElement>

  ) => void;
};




const TextArea = ({name, label, placeholder, value, rows, onChange, error}: InputFieldPrps) => {


  return (
    <>
      <div>
        <label htmlFor="crud-form-1" className="form-label">
         {label}
        </label>
        <textarea
          id = {name}
          value={value}
          onChange={(e) => onChange(e)}
          className="form-control w-full"
          placeholder={placeholder}
          rows={rows}
        >
          {value}
        </textarea>
         {error && error[`${name}`] && <div className='text-[#f87171]'> {error[`${name}`]}</div>}
      </div>
    </>
  );
};

export default TextArea;

TextArea.defaultProps  = {
  rows: 6,
};
