
import React, { useEffect, useState } from 'react';
import  styled  from '@emotion/styled';
import { Typography } from '@mui/material';


type InputType = 'text' | 'date' | 'datetime-local' | 'password' | 'number'
type TitleProps = {
  label: string;
  size?: number
};


const Label = styled(Typography)`
  font-family: Nunito;
  font-size: 40px;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: left;

`;


const Title = ({ label, size }: TitleProps) => {


  return (
    <React.Fragment>
      <Label variant="h1" >
        {label}
      </Label>
    </React.Fragment>
  );
};

export default Title;

