import { DataTable } from "@lib/Forms/DataTable";
import { useTitle } from "@lib/Hooks";
import { RitualAnswersParamI, routesName } from "@lib/RoutesName";
import { PaigeResponse, PaigeResponsePaginator } from "@lib/Types/Response";
import { RitualAnswersI } from "@lib/Types/RitualAnwersProps";
import { privateAgent } from "@lib/index";
import { TableContainer } from "@mui/material";
import { MRT_ColumnDef, MRT_PaginationState } from "material-react-table";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { RitualAnswersContainer, TopContainerNoMargin } from "src/styles/Containers";
import { TitleLarge } from "src/styles/Typography";

function RitualAnswers(props: { userId: number, userName?: string }) {
    //useTitle("Ritual Answers | Paige");

    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    
    const [tempPagination, setTempPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const {
        data: ritualAnswers,
        isLoading,
        isRefetching,
        isError
    } = useQuery<PaigeResponsePaginator<RitualAnswersI>>({
        queryKey: [
            'RitualAnswers',
            pagination.pageIndex,
            pagination.pageSize
        ],
        queryFn: async () => {
            let filters: RitualAnswersParamI = {
                userId: props.userId,
                pageNum: pagination.pageIndex,
                RecordPerPage: pagination.pageSize
            }
            const { data } = await privateAgent.get<PaigeResponse<PaigeResponsePaginator<RitualAnswersI>>>(routesName.RitualAnswerRoute(filters).get_ritual_answers);

            return data.result
        },
        keepPreviousData: true
    })

    const dateToLocalString = (dateString: string) => {
        let date = new Date(dateString);

        return date.toLocaleDateString(undefined,{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
    }

    const columns = useMemo<MRT_ColumnDef<RitualAnswersI>[]>(
        () => [
          {
            id: "answer",
            header: "Answer",
            accessorFn: (row) => row.answer,
          },
          {
            id: "createdAt",
            header: "Created At",
            accessorFn: (row) => dateToLocalString(row.createdAt),
          },
        ],
        []
    )

    const onCellClick = () => {

    }

    return (<>
        {/* <TopContainerNoMargin>
            <TitleLarge>Ritual Answers</TitleLarge>
        </TopContainerNoMargin> */}

        {/* <RitualAnswersContainer> */}
            <TableContainer>
            <DataTable
              columns={columns}
              data={ritualAnswers?.items ?? []}
              onCellClick={ onCellClick }
              state={{
                pagination,
                isLoading,
                showAlertBanner: isError,
                showProgressBars: isRefetching,
              }}
              rowCount={ritualAnswers?.totalItems ?? 0}
              //onPaginationChange={ setPagination }
              muiTablePaginationProps={{
                rowsPerPageOptions:[10,20,50]
              }}
              onPaginationChange={
                (a) => {
                    /**
                     * TODO;
                     * bug: pagination is not a updating,have to double click
                     * issue: directly updating pagination keeps resetting the page to 0(triggering 3 times)
                     */
                    setTempPagination(a);
                    setPagination(tempPagination);
                }
              }
              muiToolbarAlertBannerProps={ isError ? { color: 'error', children: 'Error loading data', } : undefined }
              manualPagination={ true }
            />
            </TableContainer>
        {/* </RitualAnswersContainer> */}
    </>)
}

export default RitualAnswers;