import { useState } from "react";
import { Button, TextButton } from "@lib/Forms";
import { useTitle } from "@lib/Hooks";
import { privateAgent } from "@lib/Requests/AuthRequests";

import { Formik } from "formik";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { routesName } from "@lib/RoutesName/Routes";
import NavigateBack from "@components/shared/NavigateBack";
import { NoteProps } from "@lib/Types/NoteProps";
import AddSequenceNoteFields from "@components/formFields/Sequence/AddSequenceNoteFields";
import dayjs from "dayjs";
import {
  sequenceNoteInitialValue,
  sequenceNoteEditValue,
} from "./sequence-helper";
import { PAGE_FOR } from "@pages/pagefor";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { AddSequenceNoteValidationSchema } from "@components/formFields/Sequence/sequenceValidationSchema";
import { StyledForm } from "src/styles/Forms";
import {
  ButtonContainer,
  TopContainerSmallMargin,
  TableContainer,
  PreviewContainer,
} from "src/styles/Containers";
import { SeqHeader } from "src/styles/Typography";
import PlayIcon from "@assets/Play";
import ViewNotePage from "@pages/ViewNotePage";

interface AddNotePageProps {
  isEdit?: boolean;
  pagefor: PAGE_FOR;
}

function AddSequenceNotePage(props: AddNotePageProps) {
  const { isEdit, pagefor } = props;
  useTitle("Client Note | Paige");
  const navigate = useNavigate();
  const location = useLocation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();
  const { clientId, noteId, sequenceId, sequenceNoteId } = params;
  const [notes, setNotes] = useState<NoteProps>();
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const [fieldInitialValues, setFieldInitialValues] = useState(
    sequenceNoteInitialValue
  );

  let { isLoading, data: editSequenceNotes } = useQuery(
    ["singleSequenceNote"],
    async () => {
      const { data } = await privateAgent.get(
        routesName.SequenceNoteRoute({ id: sequenceNoteId })
          .get_single_sequencenotes
      );

      if (data.status === 200) {
        const results = data.result;
        setFieldInitialValues(sequenceNoteEditValue(results));
        return results;
      }
    }
  );

  const handleSubmit = async (values: any) => {
    const modifiedStepNotes = values.noteSteps.map(
      (stepnote: any, index: number) => {
        return {
          text: values[`noteSteps${index}`]?.text ?? "",
        };
      }
    );
    values.noteSteps = modifiedStepNotes;
    values.userId = pagefor == "coach" ? clientId : Cookies.get("userId");
    values.noteType = "REGULAR"
    values.link = ""
    values.time = dayjs(values.time).format("HH:mm:ss");

    if (isEdit) {
      const res = await privateAgent.put(
        routesName.SequenceNoteRoute({ id: sequenceNoteId })
          .update_sequencenotes,
        values
      );
      if (res.status === 200) {
        enqueueSnackbar("Sequence note edited successfully", {
          variant: "success",
        });
        navigate(-1);
      }
    } else {
      values.sequenceId = sequenceId;
      const res = await privateAgent.post(
        routesName.SequenceNoteRoute({}).add_sequencenotes,
        values
      );
      if (res.status === 201) {
        enqueueSnackbar("Sequence note added successfully", {
          variant: "success",
        });
        navigate(-1);
      }
    }
  };

  const handlePreview = async (values: any) => {
    setOpenPreviewDialog(true)

    // const test = formikProps.values as AddNoteProps;
  };


  return (
    <>
      <NavigateBack text='Sequences' />

      <Formik
        enableReinitialize={true}
        initialValues={fieldInitialValues}
        onSubmit={handleSubmit}
        validationSchema={AddSequenceNoteValidationSchema}
      >
        {({ isValid, setValues, values }) => (
          <StyledForm>
            <TopContainerSmallMargin>
              <SeqHeader>
                {isEdit
                  ? "Edit Sequence Note"
                  : `Add Sequence Note to ${location?.state?.title ?? ""} `}
                  <PreviewContainer onClick={handlePreview}>
                  <PlayIcon />
                  <p>Preview</p>
                </PreviewContainer>
              </SeqHeader>
              <ButtonContainer>
                <TextButton
                  label={"Cancel"}
                  onClick={() => navigate(-1)}
                ></TextButton>
                <Button
                  label={"Save"}
                  type={"submit"}
                ></Button>
              </ButtonContainer>
            </TopContainerSmallMargin>
            <TableContainer>
              <TableContainer>
              <ViewNotePage
                  // note={previewNote.note}
                  isOpen={openPreviewDialog}
                  setIsOpen={setOpenPreviewDialog}
                  onClose={() => {
                    // setOpenPreviewDialog(false);
                  }}
                />
                <AddSequenceNoteFields />
              </TableContainer>
            </TableContainer>
          </StyledForm>
        )}
      </Formik>
    </>
  );
}

export default AddSequenceNotePage;
