
import React, { useEffect, useState } from 'react';
import  styled  from '@emotion/styled';
import { Typography } from '@mui/material';


type InputType = 'text' | 'date' | 'datetime-local' | 'password' | 'number'
type TitleProps = {
  label: string;
  size?: number
};


const Label = styled(Typography)`
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;


const SubTitle = ({ label, size }: TitleProps) => {


  return (
    <React.Fragment>
      <Label variant="h1" >
        {label}
      </Label>
    </React.Fragment>
  );
};

export default SubTitle;

