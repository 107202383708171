import { useState, useEffect } from 'react'
import { privateAgent } from '../../Requests/AuthRequests';
import { routesName } from '../../RoutesName/Routes';
import { ClassesProps } from '../../Types';
import { BehaviorCategoryProps } from '../../Types/BehaviorCategoryProps';

 const useBehaviorCategoryOptions = () => {
 const [options, setOptions] = useState([]);

 const fetchBehaviorCategory = async()=>{
     const res = await privateAgent.get(routesName.BehaviorCategoryRoute({}).get_behavior_category)
     if(res.status==200){
        const options = res.data.result.items.map((item: BehaviorCategoryProps) => {

            return {
                'Name': `${item.name}`,
                'value': item.id,
                'Enabled': 1
            }
        })
        setOptions(options)
     }else{
      setOptions([])
     }

 }

 useEffect(() => {
  fetchBehaviorCategory()

  return () => {
    setOptions([])
  };
 }, []);

 return options;
};

export default useBehaviorCategoryOptions
