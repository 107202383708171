import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "../../assets/Close";

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 600px;
  }
  & .MuiDialogTitle-root {
    color: #242424;
    font-size: 20px;
    font-weight: 700;
    padding: 16px 24px;
  }
  & .MuiDialogContent-root {
    border: none;
    color: #45565e;
  }
  &.preview {
    & .MuiPaper-root h2 {
      display: block;
    }
    & .MuiDialog-paper {
      width: auto;
    }
    & .MuiDialogContent-root {
      max-height: 600px;
      min-height:600px;
      padding: 0 !important;
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    & .MuiDialogContent-root::-webkit-scrollbar {
      display: none;
    }
  }

  
`;

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function StyledDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface DialogBoxProps {
  content: React.ReactNode;
  isOpen: boolean;
  title: string;
  className?: string;
  onClose?: () => void;
}

export default function DialogBox({
  title,
  isOpen,
  content,
  onClose,
  className,
}: DialogBoxProps) {
  const [open, setOpen] = React.useState(isOpen);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div>
      <StyledDialog
        onBackdropClick={handleClose}
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={isOpen}
        className={className}
      >
        <StyledDialogTitle id='customized-dialog-title' onClose={handleClose}>
          {title}
        </StyledDialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
      </StyledDialog>
    </div>
  );
}
