import SvgIcon from "@mui/material/SvgIcon";

function ReceptionIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 19 11'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='19'
        height='11'
        viewBox='0 0 19 11'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.85 0C15.2977 0 14.85 0.447715 14.85 1V10C14.85 10.5523 15.2977 11 15.85 11H17.15C17.7022 11 18.15 10.5523 18.15 10V1C18.15 0.447715 17.7022 0 17.15 0H15.85ZM9.90004 3.19991C9.90004 2.64763 10.3478 2.19991 10.9 2.19991H12.2C12.7523 2.19991 13.2 2.64763 13.2 3.19991V9.99991C13.2 10.5522 12.7523 10.9999 12.2 10.9999H10.9C10.3478 10.9999 9.90004 10.5522 9.90004 9.99991V3.19991ZM5.94991 4.40004C5.39763 4.40004 4.94991 4.84776 4.94991 5.40004V10C4.94991 10.5523 5.39762 11 5.94991 11H7.24991C7.8022 11 8.24991 10.5523 8.24991 10V5.40004C8.24991 4.84776 7.8022 4.40004 7.24991 4.40004H5.94991ZM1.1 11C0.492487 11 0 10.5075 0 9.9V7.15C0 6.54249 0.492487 6.05 1.1 6.05H2.2C2.80751 6.05 3.3 6.54249 3.3 7.15V9.9C3.3 10.5075 2.80751 11 2.2 11H1.1Z'
          fill='black'
        />
      </svg>
    </SvgIcon>
  );
}

export default ReceptionIcon;
