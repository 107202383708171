import * as Yup from 'yup';

export default function AddNoteValidationSchema(){
    return Yup.object().shape({
        title: Yup.string().required('Required'),
        notificationText: Yup.string().required('Required'),
        behaviorId: Yup.string().required('Behavior is required'),
        callToActionId: Yup.string().required('Call to Action is required')
        // noteSteps0 : Yup.string().required('Required field')

    })

}