import ArrowRightCircle from "@assets/ArrowRightCircle";
import { CheckBox, InputField, SelectField, TextArea } from "../../../lib";
import useCallToActionOptions from "../../../lib/Hooks/options/useCalltoActionOption";
import { Button } from "@lib/Forms";
import { Formik } from "formik";
import {
  FieldContainer,
  EvenColumnContainer,
  ButtonContainer,
  MainFieldContainer2,
  LabelContainer,
  PaigeAiFormBtnContainer,
  SingleRowSelectContainer,
} from "src/styles/Containers";
import { Label, Title } from "src/styles/Typography";
import { ObjectSchema } from "yup";
import { StyledForm } from "src/styles/Forms";

interface PaigeAIFormFieldsProps {
  isEdit?: boolean;
  initialValues: any;
  handleSubmit: (value: any) => Promise<void> | Promise<any> & ((values: any) => Promise<any>);
  validationSchema: any | ObjectSchema<any>;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
  resetData?: () => void;
  name: string;
  placeholder: string;
  label: string
}

function PaigeAIClientFormFields({ label, initialValues, handleSubmit, validationSchema, loading, setLoading, resetData, name, placeholder }: PaigeAIFormFieldsProps) {

  return (
    <Formik
        //enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
    >
        {({ isValid, setValues, values }) => (
            <StyledForm>
                <FieldContainer>

                    <MainFieldContainer2>
                        
                        <EvenColumnContainer style={{position: 'relative'}}>
                        <Title>{label}</Title>
                            <InputField
                                style={{
                                    height: 'auto',
                                    fontSize: '14px'
                                }}
                                rows={8}
                                placeholder={placeholder}
                                name={name}
                                value={values[name]}
                                multiline={true}
                            />
                        </EvenColumnContainer>
                        
                        <EvenColumnContainer>

                            
                        </EvenColumnContainer>

                    </MainFieldContainer2>

                    <PaigeAiFormBtnContainer>
                        <Button label={"Save"} type={"submit"} loading={loading} ></Button>
                    </PaigeAiFormBtnContainer>

                </FieldContainer>
            </StyledForm>
        )}
    </Formik>
  );
}

export default PaigeAIClientFormFields;
