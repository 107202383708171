import SvgIcon from "@mui/material/SvgIcon";

function MoveDotsIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          d='M5.5 3.25C5.91421 3.25 6.25 2.91421 6.25 2.5C6.25 2.08579 5.91421 1.75 5.5 1.75C5.08579 1.75 4.75 2.08579 4.75 2.5C4.75 2.91421 5.08579 3.25 5.5 3.25Z'
          stroke='#172882'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.5 8.75C5.91421 8.75 6.25 8.41421 6.25 8C6.25 7.58579 5.91421 7.25 5.5 7.25C5.08579 7.25 4.75 7.58579 4.75 8C4.75 8.41421 5.08579 8.75 5.5 8.75Z'
          stroke='#172882'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.5 14.25C5.91421 14.25 6.25 13.9142 6.25 13.5C6.25 13.0858 5.91421 12.75 5.5 12.75C5.08579 12.75 4.75 13.0858 4.75 13.5C4.75 13.9142 5.08579 14.25 5.5 14.25Z'
          stroke='#172882'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.4957 3.25C10.9099 3.25 11.2457 2.91421 11.2457 2.5C11.2457 2.08579 10.9099 1.75 10.4957 1.75C10.0815 1.75 9.74573 2.08579 9.74573 2.5C9.74573 2.91421 10.0815 3.25 10.4957 3.25Z'
          stroke='#172882'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.4957 8.75C10.9099 8.75 11.2457 8.41421 11.2457 8C11.2457 7.58579 10.9099 7.25 10.4957 7.25C10.0815 7.25 9.74573 7.58579 9.74573 8C9.74573 8.41421 10.0815 8.75 10.4957 8.75Z'
          stroke='#172882'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.4957 14.25C10.9099 14.25 11.2457 13.9142 11.2457 13.5C11.2457 13.0858 10.9099 12.75 10.4957 12.75C10.0815 12.75 9.74573 13.0858 9.74573 13.5C9.74573 13.9142 10.0815 14.25 10.4957 14.25Z'
          stroke='#172882'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
}

export default MoveDotsIcon;
