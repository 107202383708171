import SvgIcon from "@mui/material/SvgIcon";

function DeleteIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <g id='delete_svgrepo.com'>
          <path
            id='Vector'
            d='M6.66663 7.33434V11.3343'
            stroke='#172882'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_2'
            d='M9.33337 7.33434V11.3343'
            stroke='#172882'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_3'
            d='M2.66663 4.66766H13.3333'
            stroke='#172882'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_4'
            d='M4 4.66766H8H12V12.001C12 13.1056 11.1046 14.001 10 14.001H6C4.89543 14.001 4 13.1056 4 12.001V4.66766Z'
            stroke='#172882'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_5'
            d='M6 3.33433C6 2.59795 6.59695 2.001 7.33333 2.001H8.66667C9.40307 2.001 10 2.59795 10 3.33433V4.66767H6V3.33433Z'
            stroke='#172882'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default DeleteIcon;
