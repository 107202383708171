import Copy from "@assets/Copy";
import Frown from "@assets/Frown";
import Meh from "@assets/Meh";
import Smile from "@assets/Smile";
import PaigeAIFormFields from "@components/formFields/PaigeAi/PaigeAiFormFields";
import { PageAiSchema } from "@components/formFields/PaigeAi/PaigeAiSchema";
import PaigeSkeleton from "@components/formFields/skeleton/PaigeSkeleton";
import { TextFields } from "@lib/Forms/InputField";
import { useTitle } from "@lib/Hooks";
import { InputField, privateAgent, UserProps } from "@lib/index";
import { routesName } from "@lib/RoutesName";
import { AiFeedbackProps, AiProps } from "@lib/Types/AiProps";
import { PaigeResponse } from "@lib/Types/Response";
import { IconButton, Tooltip } from "@mui/material";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { FieldContainer, FormInputActionIconsContainer, LabelContainer, MainFieldContainer, MainFieldContainer1, MainFieldContainer2, SeqRowContainer, TopContainerNoMargin, UsersContainer } from "src/styles/Containers";
import { Title, TitleLarge } from "src/styles/Typography";

type PAGE_FOR = "client" | "coach";

export interface PaigeAIPageProps {
    pagefor: PAGE_FOR;
}

type PaigeAIResponse = {
    result: string;
    success: boolean;
    id: string;
    timestamp: string;
    error?: string;
}

type PaigeAIFeedBackResponse = {
  success: boolean;
  message: string;
  feedback: number
  uuid: string;
  timestamp: string;
}

export default function PaigeAIPage({ pagefor }: PaigeAIPageProps) {
    useTitle("Paige AI | Paige");

    const [user, setUser] = useState<UserProps>()
    const [aiResult, setAiResult] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false);
    const [uuid, setUuid] = useState<string>("");


    const queryClient = useQueryClient();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();

    let initialFormValue: AiProps = {
      action: 'process_client_input',
      user_input: '',
      you_path_input: '',
      lizard_path_input: ''
    }

    const { clientId } = params;

    let { isLoading, data: clientFullName } = useQuery(
    ["Client"],
    async () => {
      const userId = pagefor == 'coach' ? clientId : Cookies.get("userId")
      const { data } = await privateAgent.get(
        routesName.UserRoute({ id: userId }).get_single_user
      );

      if (data.status === 200) {
        const results = `${data.result.userdetails.firstName} ${data.result.userdetails.lastName}`;
        setUser(data.result)
        return results;
      }
    },
    { enabled: pagefor == "coach" || pagefor == "client" }
  );

  let { isLoading: clientInfoLoading, data: aiClientInfo } = useQuery(
    ["ClientInfo"],
    async () => {
     
      const { data } = await privateAgent.get(
        routesName.PaigeAiRoute({ id: clientId }).get_client_info
      );

      if (data.status === 200) {
        initialFormValue.you_path_input = data.result.youPath ?? ''
        initialFormValue.lizard_path_input = data.result.lizardPath ?? ''
        return data.result
      }
    },
    { enabled: !! clientId  }
  );

  // useEffect(() => {
  //   if(aiClientInfo){
  //     initialFormValue.you_path_input = aiClientInfo.youPath
  //     initialFormValue.lizard_path_input = aiClientInfo.lizardPath
  //   }
    
  // },[aiClientInfo])

  if(aiClientInfo){
    initialFormValue.you_path_input = aiClientInfo.youPath ?? ''
    initialFormValue.lizard_path_input = aiClientInfo.lizardPath ?? ''
  }


  const onSubmit = async(v: any) => {
    if(loading) return;
    setLoading(true);

    const values: AiProps = v

      try {
        const { data } = await privateAgent.post<PaigeResponse<PaigeAIResponse>>(
          routesName.PaigeAiRoute({ id: clientId }).add_refuter,
          values
        );

        if (data.status === 201) {
          if(data.result.success) {
            setAiResult(data.result.result)
            setUuid(data.result.id)
            enqueueSnackbar("AI processed successfully", {
              variant: "success",
            });
        }else{
          enqueueSnackbar(data.result.error ?? "Error processing AI", {
            variant: "error",
          });
        }
         
        }
        setLoading(false);
      } catch (error) {
        enqueueSnackbar("Error processing AI", {
          variant: "error",
        });
        setLoading(false);
      }

  }

  const copyAiOutput = async () => {
    await copyToClipboard(aiResult)
  }

    const copyToClipboard = async(text: string) => {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        try {
          await navigator.clipboard.writeText(text)
          enqueueSnackbar({ message: 'Copied !', variant: 'info' })
        } catch (error) {
          
        }
       
      } else {
        // Fallback for browsers that do not support the Clipboard API
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          enqueueSnackbar({ message: 'Copied !', variant: 'info' })
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textarea);
      }
    };

  const [postingFeeback, setPostingFeedback] = useState(false);

  const sendFeedback = async (rating: 0 | 1 | 2) => {
    if(postingFeeback || aiResult == '') return;

    setPostingFeedback(true)
    let feedbackData: AiFeedbackProps = {
      action: 'process_coach_feedback',
      coach_feedback: rating,
      feedback_uuid: uuid
    }

    try {
      const { data } = await privateAgent.post<PaigeResponse<PaigeAIFeedBackResponse>>(
        routesName.PaigeAiRoute({id: clientId }).add_refuter_feedback,
        feedbackData
      );
  
      if(data.status == 201) {
        if(data.result.success) {
        enqueueSnackbar(data.result.message, {
          variant: "success",
        });
      }else{
        enqueueSnackbar(data.result.message ?? "Error processing feedback", {
          variant: "error",
        });
      }
      }
      
      setPostingFeedback(false)
    } catch (error) {
      enqueueSnackbar("Error processing feedback.", {
        variant: "error",
      });
      setPostingFeedback(false)
    }
  }

  const clearAiOutputs = () => {
    setAiResult('')

  }

  if ( clientInfoLoading) {
    return <PaigeSkeleton/>;
  }


  return <>
      <TopContainerNoMargin>
        <TitleLarge>
            {clientFullName ? `${clientFullName}'s Paige AI` : ""}
        </TitleLarge>
        <SeqRowContainer>
        </SeqRowContainer>
      </TopContainerNoMargin>

      <UsersContainer>
        <PaigeAIFormFields
          setLoading={setLoading}
          loading={loading}
          handleSubmit={onSubmit}
          initialValues={initialFormValue}
          validationSchema={PageAiSchema}
          resetData={clearAiOutputs}
        />
      </UsersContainer>

      <UsersContainer style={{marginTop: '16px'}}>
        <FieldContainer>
              <MainFieldContainer1 style={{minWidth: '50%'}}>
                  <Title>{"AI Output"}</Title>

                  <TextFields
                      style={{
                          height: 'auto',
                          fontSize: '14px'
                      }}
                      rows={6}
                      placeholder={"AI output will show here"}
                      name={"ai_output"}
                      multiline={true}
                      value={aiResult}
                  >
                  
                  </TextFields>
                  
                  <FormInputActionIconsContainer>
                    <Tooltip title={'Happy'}>
                      <IconButton onClick={() => {sendFeedback(2)}}>
                        <Smile/>
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={'Smiley'}>
                      <IconButton onClick={() => {sendFeedback(1)}}>
                        <Meh/>
                      </IconButton>
                    </Tooltip>
                    
                    <Tooltip title={'Sad'}>
                      <IconButton onClick={() => {sendFeedback(0)}}>
                        <Frown/>
                      </IconButton>
                    </Tooltip>
                    |
                    <Tooltip title={'Copy'}>
                      <IconButton onClick={copyAiOutput}>
                        <Copy/>
                      </IconButton>
                    </Tooltip>
                  </FormInputActionIconsContainer>

              </MainFieldContainer1>
        </FieldContainer>
      </UsersContainer>
  </>
}