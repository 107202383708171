import SvgIcon from "@mui/material/SvgIcon";

function MoveToSequenceIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 14 14'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
      >
        <path
          d='M1.56198 10.5547V4.3729H12.8016V8.56866L13.3636 9.13626V2.12497H7.2942L6.61982 1.00101H2.68595L2.01157 2.12497H1V11.1167H6.05784V10.5547H1.56198ZM1.56198 2.68695H2.32965L3.00403 1.56299H6.30174L6.97612 2.68695H12.8016V3.81092H1.56198V2.68695ZM13.4659 10.8357L11.3146 13.001L10.9162 12.6042L12.4037 11.1066H7.1818V10.5446H12.3841L10.9167 9.06657L11.3152 8.67094L13.4659 10.8357Z'
          fill='#172882'
          stroke='#172882'
          strokeWidth='0.5'
        />
      </svg>
    </SvgIcon>
  );
}

export default MoveToSequenceIcon;
