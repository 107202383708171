import SvgIcon from "@mui/material/SvgIcon";

function CloseIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 21 21'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
      >
        <g id='Feather Icons/fi:x'>
          <path
            id='Vector'
            d='M15.9995 5L5.9986 15.0009'
            stroke='#172882'
            strokeWidth='1.66678'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_2'
            d='M5.9986 5L15.9995 15.0009'
            stroke='#172882'
            strokeWidth='1.66678'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default CloseIcon;
