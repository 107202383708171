import React, { useState } from "react";
import { IconButton } from "@mui/material";
import LogoIcon from "../../../assets/Logo";
import SettingsIcon from "../../../assets/Settings";
import LogOutIcon from "../../../assets/LogOut";
import {
  AlertDialog,
  Button,
  DialogBox,
  privateAgent,
  TextButton,
} from "../../../lib";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../../assets/Edit";
import { Form, Formik } from "formik";
import { changePwInitialValues } from "../../formFields/ChangePassword/change-pw-helpers";
import { AddChangePwProps } from "../../../lib/Types/ChangePwProps";
import ChangePwValidationSchema from "../../formFields/ChangePassword/ChangePwValidationSchema";
import ChangePasswordFields from "../../formFields/ChangePassword/ChangePasswordFields";
import { routesName } from "../../../lib/RoutesName/Routes";
import { useSnackbar } from "notistack";
import { useQuery, useQueryClient } from "react-query";
import {
  NavContainer,
  NavLogoContainer,
  NavLogOutContainer,
  MenuRounded,
  ButtonContainer,
} from "src/styles/Containers";
import { UserName } from "src/styles/Typography";
import { MenuStyled, SelectMenu } from "src/styles/Inputs";

interface NavbarProps {
  onTopMenuCLicked: () => void;
}

function Navbar({ onTopMenuCLicked }: NavbarProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [openLogOutDialog, setOpenLogOutDialog] = useState(false);
  const [openChangePwDialog, setOpenChangePwDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  let { isLoading, data: fullName } = useQuery(["User"], async () => {
    const userId = Cookies.get("userId");
    const { data } = await privateAgent.get(
      routesName.UserRoute({ id: userId }).get_single_user
    );

    if (data.status === 200) {
      const results = `${data.result.userdetails.firstName} ${data.result.userdetails.lastName}`;
      return results;
    }
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const OnMenuItemClicked = () => {
    handleClose();
    setOpenChangePwDialog(true);
    // onItemClick(id, mode);
  };

  const handleConfirm = () => {
    Cookies.remove("token");
    Cookies.remove("expireDate");
    Cookies.remove("refreshToken");
    Cookies.remove("userId");
    navigate("/login", { replace: true });
  };

  const handleSubmit = async (values: AddChangePwProps) => {
    try {
      const userId = Cookies.get("userId");
      const resp = await privateAgent.post(
        routesName.AuthRoute({}).changePassword,
        { ...values, userId: userId }
      );
      if (resp.status == 201) {
        enqueueSnackbar(resp.data.result ?? "", { variant: "success" });
        setOpenChangePwDialog(false);
      }
    } catch (error: any) {
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
      setOpenChangePwDialog(false);
    }
  };

  return (
    <>
      <AlertDialog
        variety='error'
        onConfirm={() => handleConfirm()}
        onClose={() => setOpenLogOutDialog(false)}
        isOpen={openLogOutDialog}
        title={"Log Out"}
        content={"Are you sure you want to Log out?"}
        btnText={{ cancel: "Cancel", action: "Log out" }}
      />

      <NavContainer>
        <NavLogoContainer>
          <MenuRounded onClick={() => onTopMenuCLicked()} />
          <LogoIcon></LogoIcon>
          <img
            height='24px'
            src={require("../../../assets/PaigeText.png")}
          ></img>
        </NavLogoContainer>
        <NavLogOutContainer>
          <UserName>{fullName}</UserName>
          <IconButton
            aria-label='more'
            id='long-button'
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup='true'
            onClick={handleClick}
          >
            <SettingsIcon
              style={{
                height: "20px",
              }}
            />
          </IconButton>
          <MenuStyled
            id='long-menu'
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <SelectMenu onClick={() => OnMenuItemClicked()} disableRipple>
              <EditIcon />
              <div>Change password</div>
            </SelectMenu>
          </MenuStyled>

          <LogOutIcon
            style={{
              height: "20px",
            }}
            onClick={() => setOpenLogOutDialog(true)}
          ></LogOutIcon>
        </NavLogOutContainer>
      </NavContainer>

      <DialogBox
        isOpen={openChangePwDialog}
        onClose={() => setOpenChangePwDialog(false)}
        title={"Change password"}
        content={
          <Formik
            initialValues={changePwInitialValues as AddChangePwProps}
            onSubmit={handleSubmit}
            validationSchema={ChangePwValidationSchema}
          >
            {({ isValid, setValues, values }) => (
              <Form>
                <ChangePasswordFields />
                <ButtonContainer>
                  <TextButton
                    label={"Cancel"}
                    onClick={() => setOpenChangePwDialog(false)}
                  ></TextButton>
                  <Button label={"Save"} type={"submit"}></Button>
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        }
      />
    </>
  );
}

export default Navbar;
