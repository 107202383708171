import { useState } from "react";
import { Button, TextButton } from "@lib/Forms";
import { useTitle } from "@lib/Hooks";
import { privateAgent } from "@lib/Requests/AuthRequests";
import { Formik } from "formik";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { routesName } from "@lib/RoutesName/Routes";
import NavigateBack from "@components/shared/NavigateBack";
import AddSequenceFields from "@components/formFields/Sequence/AddSequenceFields";
import { sequenceInitialValue, sequenceEditValue } from "./sequence-helper";
import { PAGE_FOR } from "@pages/pagefor";
import Cookies from "js-cookie";
import { AddSequenceValidationSchema } from "@components/formFields/Sequence/sequenceValidationSchema";
import { StyledForm } from "src/styles/Forms";
import {
  ButtonContainer,
  TopContainerSmallMargin,
  TableContainer,
} from "src/styles/Containers";
import { SeqHeader } from "src/styles/Typography";

interface AddSequencePageProps {
  isEdit?: boolean;
  pagefor: PAGE_FOR;
}

function AddSequencePage(props: AddSequencePageProps) {
  const { isEdit, pagefor } = props;
  useTitle("Client Note | Paige");
  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();
  const { clientId, noteId } = params;
  const [fieldInitialValues, setFieldInitialValues] =
    useState(sequenceInitialValue);

  let { isLoading, data: editNotes } = useQuery(
    ["singleSequence"],
    async () => {
      const { data } = await privateAgent.get(
        routesName.SequenceRoute({ id: noteId }).get_single_sequence
      );

      if (data.status === 200) {
        const results = data.result;
        setFieldInitialValues(sequenceEditValue(results));
        return results;
      }
    },
    {
      enabled: !!noteId,
    }
  );

  const handleSubmit = async (values: any) => {
    values.userId = pagefor == "coach" ? clientId : Cookies.get("userId");
    values.type = values.type == undefined ? "REGULAR" : values.type;

    if (isEdit) {
      const res = await privateAgent.put(
        routesName.SequenceRoute({ id: noteId }).update_sequence,
        values
      );
      if (res.status === 200) {
        enqueueSnackbar("Sequence edited successfully", { variant: "success" });
        navigate(-1);
      }
    } else {
      const res = await privateAgent.post(
        routesName.SequenceRoute({}).add_sequence,
        values
      );
      if (res.status === 201) {
        enqueueSnackbar("Sequence added successfully", { variant: "success" });
        navigate(-1);
      }
    }
  };

  return (
    <>
      <NavigateBack text='Sequences' />

      <Formik
        enableReinitialize={true}
        initialValues={fieldInitialValues}
        onSubmit={handleSubmit}
        validationSchema={AddSequenceValidationSchema}
      >
        {({ isValid, setValues, values }) => (
          <StyledForm>
            <TopContainerSmallMargin>
              <SeqHeader>{isEdit ? "Edit Sequence" : "Add Sequence"}</SeqHeader>
              <ButtonContainer>
                <TextButton
                  label={"Cancel"}
                  onClick={() => navigate(-1)}
                ></TextButton>
                <Button label={"Save"} type={"submit"}></Button>
              </ButtonContainer>
            </TopContainerSmallMargin>
            <TableContainer>
              <TableContainer>
                <AddSequenceFields />
              </TableContainer>
            </TableContainer>
          </StyledForm>
        )}
      </Formik>
    </>
  );
}

export default AddSequencePage;
