import RemindersIcon from "@assets/Reminders";
import BehaviorsIcon from "../../../assets/Behaviors";
import CallsToActionIcon from "../../../assets/CallsToAction";
import CheckInsIcon from "../../../assets/CheckIns";
import ClassesIcon from "../../../assets/Classes";
import ClientsIcon from "../../../assets/Clients";
import MeasurementsIcon from "../../../assets/Measurements";
import UsersIcon from "../../../assets/Users";
import NotesIcon from "@assets/Notes";
import AnswersIcon from "@assets/Answers";

export const SideBarRoutes = [
  {
    image: UsersIcon,
    name: "Users",
    path: "/users",
    permission: ["admin"],
  },
  {
    image: ClassesIcon,
    name: "Classes",
    path: "/classes",
    permission: ["admin"],
  },
  {
    image: BehaviorsIcon,
    name: "Behaviors",
    path: "/behaviors",
    permission: ["admin"],
  },
  {
    image: CallsToActionIcon,
    name: "Calls to Action",
    path: "/callstoaction",
    permission: ["admin"],
  },
  {
    image: MeasurementsIcon,
    name: "Measurements",
    path: "/measurements",
    permission: ["admin"],
  },
  {
    image: MeasurementsIcon,
    name: "System Data",
    path: "/system/notes",
    permission: ["admin"],
    restrictedURL: true
  },

  {
    image: ClientsIcon,
    name: "Clients",
    path: "/coach/clients",
    permission: ["coach"],
  },

  {
    image: NotesIcon,
    name: "Notes",
    path: "/notes",
    permission: ["client"],
  },
  {
    image: RemindersIcon,
    name: "Reminders",
    path: "/reminders",
    permission: ["client"],
  },
  {
    image: AnswersIcon,
    name: "Happiness Answers",
    path: "/happiness/answers",
    permission: ["client"],
  },
  // {
  //     image: CheckInsIcon,
  //     name: "Check-Ins",
  //     path: "/checkins",
  //     permission: ["client"],
  //   },
];
