import React, { useEffect, useState } from "react";
import { useDebounce } from "../../../lib";
import NonFormikSelectField from "../../../lib/Forms/NonFormikSelectField";
import { SelectChangeEvent } from "@mui/material/Select";
import useBehaviorCategoryOptions from "../../../lib/Hooks/options/useBehaviorCategoryOption";
import { SelectFieldContainer, SearchContainer } from "src/styles/Containers";
import { StyledInputField } from "src/styles/Inputs";

interface SearchFieldsProps {
  onSearchQuery: (searchTerm: SearchParams) => void;
}

export interface SearchParams {
  searchQuery: string;
  behaviorCategoryId: string;
}

export const defaultSearchParams: SearchParams = {
  searchQuery: "",
  behaviorCategoryId: "0",
};

function BehaviorSearchFields({ onSearchQuery }: SearchFieldsProps) {
  const behaviorCatOptions = useBehaviorCategoryOptions();

  const [searchQuery, setSearchQuery] = useState("");
  const debounceSearchQuery = useDebounce(searchQuery, 500);

  const [searchTerm, setSearchTerm] =
    useState<SearchParams>(defaultSearchParams);

  useEffect(() => {
    setSearchTerm((prevState) => ({
      ...prevState,
      searchQuery: searchQuery,
    }));

    if (onSearchQuery) {
      onSearchQuery({ ...searchTerm, searchQuery: searchQuery });
    }
  }, [debounceSearchQuery]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleBehaviorCategoryChange = (event: SelectChangeEvent) => {
    setSearchTerm((prevState) => ({
      ...prevState,
      behaviorCategoryId: event.target.value,
    }));

    if (onSearchQuery) {
      onSearchQuery({ ...searchTerm, behaviorCategoryId: event.target.value });
    }
  };

  return (
    <SearchContainer>
      <StyledInputField label={"Search"} onChange={handleSearchChange} />
      <SelectFieldContainer>
        <NonFormikSelectField
          name={"behaviorCategoryId"}
          placeholder={"Behavior Category*"}
          options={behaviorCatOptions}
          onChange={handleBehaviorCategoryChange}
        />
      </SelectFieldContainer>
    </SearchContainer>
  );
}

export default BehaviorSearchFields;
