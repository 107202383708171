import { useState, useEffect } from "react";
import { privateAgent } from "../../Requests/AuthRequests";
import { routesName } from "../../RoutesName/Routes";
import { SequenceProps } from "../../Types/SequenceProps";
import { SequenceNoteProps } from "@lib/Types/SequenceNoteProps";

const useSystemSequencesOption = (clientId?: string) => {
  const [options, setOptions] = useState([]);

  const fetchSequencesByClientId = async () => {
    if (!clientId) return;
    const res = await privateAgent.get(
      routesName.SystemSequenceRoute({}).get_sequences
    );
    if (res.status == 200) {
      const options = res.data.result.items.map(
        (item: SequenceProps) => {
         // const hasDuplicate = hasDuplicateTitles(item?.sequenceNotes);
          return {
            Name: `${item.title}`,
            value: item.id,
            Enabled: 1,
            //hasDuplicate: hasDuplicate,
          };
        },
        {
          enabled: !!clientId,
        }
      );
      setOptions(options);
    } else {
      setOptions([]);
    }
  };

  const hasDuplicateTitles = (sequenceNotes:SequenceNoteProps[]) => {
    if (sequenceNotes.length > 1) {
        for (const sequenceNote of sequenceNotes) {
            if (sequenceNote.day > 1) {
                return true
            }
        }
    }
    else return false;
    
    // const days = {};
    // for (const note of sequenceNotes) {
    //   if (days[note.day]) {
    //     return true; 
    //   }
    //   days[note.day] = true;
    // }
    // return false; 
  };

  useEffect(() => {
    fetchSequencesByClientId();

    return () => {
      setOptions([]);
    };
  }, [clientId]);

  return options;
};

export default useSystemSequencesOption;
