
function NotFoundPage() {
  
  return (
    <>
      Page Not Found
    </>
  );
}

export default NotFoundPage;
