import PropTypes from "prop-types";
import { Field, useField } from "formik";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styled from "@emotion/styled";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const StyledFormHelperText = styled(FormHelperText)`
  color: #d32f2f;
`;

const SelectMenu = styled(MenuItem)`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  color: #172882;
`;
const SelectFormControl = styled(FormControl)`
  margin: 0;
  height: 40px;
  & .MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
    padding: 8.5px 14px;
    transform: unset;
  }
  & .Mui-error {
    text-align: right;
    margin-top: -3px;
    margin-right: 0;
  }
  // & .MuiOutlinedInput-notchedOutline {
  //   padding: 8.5px 14px;
  // }
  & .MuiInputBase-input {
    padding: 8.5px 14px;
  }
`;

export interface SelectOptions {
  Name: string;
  value: string;
}

type SelectFieldProps = {
  name: string;

  options: any[];
  disabled?: boolean;
  onChange?: (option: any) => void;

  [key: string]: any;
};

function ToggleButtonField(props: SelectFieldProps) {
  let { options, name, disabled, ...rest } = props;
  const [field, meta, helpers] = useField(props);
  let { touched, error, value } = meta;
  const { setValue, setTouched } = helpers;
  const isError = touched && error && true;

  if (typeof value !== "string" && !value && value && !value.Id) {
    value = null;
  }

  // const handleChange = (event: SelectChangeEvent) => {
  //   if (props.onChange) {
  //     props.onChange(event);
  //   } else {
  //     setValue(event.target.value);
  //   }
  // };
  const ToggleButtonContainer = styled(ToggleButtonGroup)`
    & .MuiButtonBase-root {
      padding: 9px 16px;
      color: #172882;
      font-family: Nunito;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border: 2px solid #172882;
      text-transform: none !important;
    }
    & .Mui-selected {
      background: #172882 !important;
      color: #ffffff !important;
    }
  `;

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (props.onChange) {
      setValue(newAlignment);
      props.onChange(newAlignment);
    } else {
      setValue(newAlignment);
    }
    // setValue(newAlignment);
  };

  function _renderHelperText() {
    if (isError) {
      return <StyledFormHelperText>{error}</StyledFormHelperText>;
    }
  }

  function hasError() {
    if (isError) return true;
    return false;
  }

  return (
    <>
      <Field name={field.name}>
        {({}) => (
          <ToggleButtonContainer
            color='primary'
            value={value}
            exclusive
            onChange={handleChange}
            aria-label='Platform'
            disabled={ disabled }
          >
            {options.map((option) => {
              return (
                <ToggleButton key={option.value} value={option.value}>
                  { option.Name }
                </ToggleButton>
              );
            })}
          </ToggleButtonContainer>
        )}
      </Field>
    </>
  );
}

export default ToggleButtonField;
