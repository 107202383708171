import { useState, useEffect } from 'react'
import { privateAgent } from '../../Requests/AuthRequests';
import { routesName } from '../../RoutesName/Routes';
import { ClassesProps, UserProps } from '../../Types';

 const useCoachesOptions = () => {
 const [coachesOptions, setCoachesOption] = useState([]);

 const fetchCoaches = async()=>{
     const res = await privateAgent.get(routesName.UserRoute({}).get_coaches)
     if(res.status==200){
        const coaches = res.data.result.items.map((item: UserProps) => {

            return {
                'Name': `${item.userdetails.firstName} ${item.userdetails.lastName}`,
                'value': item.id,
                'Enabled': 1
            }
        })
        setCoachesOption(coaches)
     }else{
        setCoachesOption([])
     }

 }

 useEffect(() => {
    fetchCoaches()

  return () => {
    setCoachesOption([])
  };
 }, []);

 return coachesOptions;
};

export default useCoachesOptions
