import { AddMeasurementProps } from "../../../lib/Types/MeasurementProps"
export const measurementInitialValues :AddMeasurementProps =  {
    name: ""
} 

export const editMeasurementIntitialValues=(item: any): AddMeasurementProps=>{

    return {
         name:item?.name,
    }
}