import * as Yup from "yup";

export const  AddUserValidationSchema = ()=> {
  return Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/,'Invalid email').required('Required'),
    password: Yup.string().min(2, 'Password should be at least 3 chars').max(20, 'Password should not exceed 20 chars').required('Required'),
    classId: Yup.number()
    .test(
      'classId',
      "Required",
      function (item) {

          if (this.parent.userTypeId && (this.parent.userTypeId =='1' || this.parent.userTypeId =='2')) {
              return true

          } else {
            if(!item)
              return false
          }
          return true
      }
  ),
  coachId: Yup.number()
  .test(
    'coachId',
    "Required",
    function (item) {

        if (this.parent.userTypeId && (this.parent.userTypeId =='1' || this.parent.userTypeId =='2')) {
            return true

        } else {
          if(!item)
            return false
        }
        return true
    }
),
    userTypeId: Yup.string().required('Required'),
    isActive: Yup.number().required("Required")
  });
}


export const  EditUserValidationSchema = ()=> {
  return Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/,'Invalid email').required('Required'),
    // password: Yup.string().min(2, 'Password should be at least 3 chars').max(20, 'Password should not exceed 20 chars').required('Required'),
    classId: Yup.number()
    .test(
      'classId',
      "Required",
      function (item) {

          if (this.parent.userTypeId && (this.parent.userTypeId =='1' || this.parent.userTypeId =='2')) {
              return true

          } else {
            if(!item)
              return false
          }
          return true
      }
  ),
  coachId: Yup.number()
  .test(
    'coachId',
    "Required",
    function (item) {

        if (this.parent.userTypeId && (this.parent.userTypeId =='1' || this.parent.userTypeId =='2')) {
            return true

        } else {
          if(!item)
            return false
        }
        return true
    }
),
    userTypeId: Yup.string().required('Required'),
    isActive: Yup.number().required("Required")
  });
}
