import React, { useEffect } from "react";
import { AddUserProps, CheckBox, InputField, SelectField } from "../../../lib";
import useClassesOptions from "../../../lib/Hooks/options/useClassesOption";
import useUserRoleOptions from "../../../lib/Hooks/options/useUserRoleOptions";
import useStatusOptions from "../../../lib/Hooks/options/useStatusOptions";
import useCoachesOptions from "../../../lib/Hooks/options/useCoachesOption";
import { useFormikContext } from "formik";
import { FieldContainer } from "src/styles/Containers";
import useMeasurementValuesOptions from "@lib/Hooks/options/useMeasurementValuesOption";

interface AddUserFieldsProps {
  isEdit?: boolean;
}

function AddUserFields({ isEdit }: AddUserFieldsProps) {
  const formikProps = useFormikContext();
  const classesOptions = useClassesOptions();
  const userRoleOptions = useUserRoleOptions();
  const statusOptions = useStatusOptions();
  const coachesOptions = useCoachesOptions();
  const measurementValuesOptions = useMeasurementValuesOptions(2);
  const [focus, setFocus] = React.useState(true);

  useEffect(() => {
    formikProps.setTouched({});
    setFocus(false);
  }, []);

  useEffect(() => {
    const userTypeId = (formikProps.values as AddUserProps).userTypeId;
    //Admin
    if (userTypeId == "1" || userTypeId == "2") {
      formikProps.setFieldValue("coachId", "");
      formikProps.setFieldValue("classId", "");
      formikProps.setFieldValue("hasAdvancedSettings", false);
    }
  }, [(formikProps.values as AddUserProps).userTypeId]);

  const IsAdminOrCoachSelected = () => {
    const userTypeId = (formikProps.values as AddUserProps).userTypeId;
    return userTypeId == "1" || userTypeId == "2";
  };

  const textFieldRef = React.useRef<HTMLInputElement>(null);

  return (
    <FieldContainer>
      <InputField
        placeholder={"First Name"}
        name={"firstName"}
        // ref={textFieldRef}
        inputRef={(input: any) => (focus ? input && input.focus() : null)}
        autoFocus
        onFocus={(e: any) => {
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          );
        }}
      />
      <InputField placeholder={"Last Name"} name={"lastName"} />
      <InputField placeholder={"Email"} name={"email"} />

      {!isEdit && (
        <InputField
          placeholder={"Password"}
          name={"password"}
          type={"password"}
        />
      )}
      <SelectField
        name={"classId"}
        placeholder={"Class"}
        options={classesOptions}
        disabled={IsAdminOrCoachSelected()}
      />
      <SelectField
        name={"coachId"}
        placeholder={"Coach"}
        options={coachesOptions}
        disabled={IsAdminOrCoachSelected()}
      />
      <SelectField
        name={"userTypeId"}
        placeholder={"User Type"}
        options={userRoleOptions}
      />
      <SelectField
        name={"isActive"}
        placeholder={"User Status"}
        options={statusOptions}
      />
      <SelectField
        name={"weightMeasurementValueId"}
        placeholder={"Weight Measurement"}
        options={measurementValuesOptions}
      />
      <CheckBox
        name={"hasAdvancedSettings"}
        placeholder={"Advanced User Settings"}
        disabled={IsAdminOrCoachSelected()}
      />
      <CheckBox
        name={"isTrial"}
        placeholder={"Trial User"}
        disabled={IsAdminOrCoachSelected()}
      />
    </FieldContainer>
  );
}

export default AddUserFields;
