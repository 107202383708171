import { useState, useEffect } from 'react'
import { privateAgent } from '../../Requests/AuthRequests';
import { routesName } from '../../RoutesName/Routes';
import { CallsToActionProps } from '../../Types/CallsToActionProps';

 const useCallToActionOptions = () => {
 const [options, setOptions] = useState([]);

 const fetchCallToAction = async()=>{
     const res = await privateAgent.get(routesName.CallsToActionRoute({}).get_callstoaction)
     if(res.status==200){
        const options = res.data.result.items.map((item: CallsToActionProps) => {

            return {
                'Name': `${item.name}`,
                'value': item.id,
                'Enabled': 1
            }
        })
        setOptions(options)
     }else{
      setOptions([])
     }

 }

 useEffect(() => {
  fetchCallToAction()

  return () => {
    setOptions([])
  };
 }, []);

 return options;
};

export default useCallToActionOptions
