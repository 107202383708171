import React, { useEffect, useState } from "react";
import { useDebounce } from "@lib/Hooks";
import NonFormikSelectField from "@lib/Forms/NonFormikSelectField";
import useClassesOptions from "@lib/Hooks/options/useClassesOption";
import { SelectChangeEvent } from "@mui/material/Select";
import useActiveOptions from "@lib/Hooks/options/useActiveOption";
import useStrugglingOptions from "@lib/Hooks/options/useStrugglingOption";
import { SelectFieldContainer, SearchContainer } from "src/styles/Containers";
import { StyledInputField } from "src/styles/Inputs";

interface SearchFieldsProps {
  onSearchQuery: (searchTerm: SearchParams) => void;
}

export interface SearchParams {
  searchQuery: string;
  classId: string;
  strugglingId: string;
}

export const defaultSearchParams: SearchParams = {
  searchQuery: "",
  classId: "",
  strugglingId: "",
};

function ClientSearchFields({ onSearchQuery }: SearchFieldsProps) {
  const activeOptions = useActiveOptions();
  const classOptions = useClassesOptions();
  const strugglingOptions = useStrugglingOptions();

  const [searchQuery, setSearchQuery] = useState("");
  const debounceSearchQuery = useDebounce(searchQuery, 500);

  const isLastClassOptions: any = [
    {
      Name: "Yes",
      value: "Yes",
      Enabled: 1,
    },
    {
      Name: "No",
      value: "No",
      Enabled: 1,
    },
  ];

  const [searchTerm, setSearchTerm] =
    useState<SearchParams>(defaultSearchParams);

  useEffect(() => {
    setSearchTerm((prevState) => ({
      ...prevState,
      searchQuery: searchQuery,
    }));

    if (onSearchQuery) {
      onSearchQuery({ ...searchTerm, searchQuery: searchQuery });
    }
  }, [debounceSearchQuery]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleClassChange = (event: SelectChangeEvent) => {
    setSearchTerm((prevState) => ({
      ...prevState,
      classId: event.target.value,
    }));

    if (onSearchQuery) {
      onSearchQuery({ ...searchTerm, classId: event.target.value });
    }
  };
  const handleStrugglingChange = (event: SelectChangeEvent) => {
    setSearchTerm((prevState) => ({
      ...prevState,
      coachId: event.target.value,
    }));

    if (onSearchQuery) {
      onSearchQuery({ ...searchTerm, strugglingId: event.target.value });
    }
  };

  return (
    <SearchContainer>
      <StyledInputField label={"Search"} onChange={handleSearchChange} />
      <SelectFieldContainer>
        <NonFormikSelectField
          name={"class"}
          placeholder={"Class*"}
          options={isLastClassOptions}
          onChange={handleClassChange}
        />
        <NonFormikSelectField
          name={"coach"}
          placeholder={"Struggling*"}
          options={strugglingOptions}
          onChange={handleStrugglingChange}
        />
        {/* <NonFormikSelectField
          name={"usertype"}
          placeholder={"User Type*"}
          options={userTypeOptions}
          onChange={handleUserTypeChange}
        /> */}
      </SelectFieldContainer>
    </SearchContainer>
  );
}

export default ClientSearchFields;
