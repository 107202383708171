import styled from "@emotion/styled";
import { SideBarRoutes } from "./routes";
import { Link, useLocation } from "react-router-dom";
import { MenuTitle, SideBarContainer } from "../../styles/sidebar.styled";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { routesName } from "../../../lib/RoutesName/Routes";
import { privateAgent } from "../../../lib";
import Cookies from "js-cookie";

const LinkWithImage = styled(Link)`
  display: flex;
  padding: 5px 0px;
  align-items: center;
  align-self: stretch;
  text-decoration: none;
  border-radius: 8px;
  margin-top: 6px;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;
interface SidebarProps {
  onLinkMenuCLicked: () => void;
}

function Sidebar({ onLinkMenuCLicked }: SidebarProps) {
  const location = useLocation();
  const [role, setRole] = useState("");
  const queryClient = useQueryClient();

  let { isLoading, data } = useQuery(["Users"], async () => {
    const userId = Cookies.get("userId");
    const { data } = await privateAgent.get(
      routesName.UserRoute({ id: userId }).get_single_user
    );
    if (data.status === 200) {
      const results = `${data.result.role.name}`;
      setRole(results);
      return results;
    }
  });

  const isMenuSelected = (url: string, route: string) => {
    return url.toLowerCase().includes(route.toLowerCase());
  };

  return (
    <SideBarContainer className='sideBarContainer'>
      {SideBarRoutes.map((routes) => {
        if (
            ((routes.restrictedURL && window.location.href.toLowerCase().indexOf('localhost')>-1) 
            || !routes.restrictedURL)
            && routes.permission.includes(role.toLowerCase())
          ) {
          return (
            <LinkWithImage
              key={"link" + routes.name}
              to={routes.path}
              className={
                isMenuSelected(location.pathname, routes.path) ? "active" : ""
              }
              onClick={() => onLinkMenuCLicked()}
            >
              <routes.image
                style={{
                  height: "16px",
                  padding: "8px 16px 8px 12px",
                }}
              ></routes.image>
              <MenuTitle>{routes.name}</MenuTitle>
            </LinkWithImage>
          );
        }
      })}
    </SideBarContainer>
  );
}

export default Sidebar;
