import { useState, useEffect } from 'react'
import { privateAgent } from '../../Requests/AuthRequests';
import { routesName } from '../../RoutesName/Routes';
import { ClassesProps, UserProps } from '../../Types';
import { MeasurementValueDto } from '@lib/Types/MeasuementValuesProps';

const useMeasurementValuesOptions = (measurementId: number) => {
  const [measurementValuesOptions, setMeasurementValuesOption] = useState<MeasurementValueDto[]>([]);

  const fetchMeasurementValues = async() => {
      const res = await privateAgent.get(routesName.MeasurementValuesRoute({measurementId}).getMeasurementValuesByMeasurementId)
      
      if(res.status == 200){
        const measurementValues = res.data.result.items.map((item: MeasurementValueDto) => {
          return {
            'Name': `${item.value}`,
            'value': item.id,
            'Enabled': 1
          }
        })
        
        setMeasurementValuesOption(measurementValues)
      }else{
        setMeasurementValuesOption([])
      }

  }

  useEffect(() => {
    fetchMeasurementValues()

    return () => {
      setMeasurementValuesOption([])
    };
  }, []);

  return measurementValuesOptions;
};

export default useMeasurementValuesOptions
