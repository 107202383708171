import SvgIcon from "@mui/material/SvgIcon";

function AnswersIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg 
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='white'
        >
          <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m0 14H6l-2 2V4h16z"
      stroke='white'>
        </path></svg>
    </SvgIcon>
  );
}

export default AnswersIcon;
