import React, { useEffect } from "react";
import { InputField, SelectField } from "../../../lib";
import useBehaviorCategoryOptions from "../../../lib/Hooks/options/useBehaviorCategoryOption";
import { useFormikContext } from "formik";
import { FieldsContainer } from "src/styles/Containers";

function AddBehaviorFields() {
  const behaviorOptions = useBehaviorCategoryOptions();
  const formikProps = useFormikContext();
  const [focus, setFocus] = React.useState(true);

  useEffect(() => {
    formikProps.setTouched({});
    setFocus(false);
  }, []);

  return (
    <FieldsContainer>
      <InputField
        placeholder={"Behavior Name *"}
        name={"name"}
        inputRef={(input: any) => (focus ? input && input.focus() : null)}
        onFocus={(e: any) => {
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          );
        }}
      />
      <SelectField
        placeholder={"Behavior Category *"}
        name={"behaviorCategoryId"}
        options={behaviorOptions}
      />
    </FieldsContainer>
  );
}

export default AddBehaviorFields;
