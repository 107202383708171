import SvgIcon from "@mui/material/SvgIcon";

function CallsToActionIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          id='Vector'
          d='M14.17 0.435028H12.83C11.82 0.435028 11 1.25503 11 2.26503V2.71503L1.5 4.75503V4.24503C1.5 3.83503 1.16 3.49503 0.75 3.49503C0.34 3.49503 0 3.83503 0 4.24503V11.245C0 11.655 0.34 11.995 0.75 11.995C1.16 11.995 1.5 11.655 1.5 11.245V10.615L11 12.655V13.105C11 14.115 11.82 14.935 12.83 14.935H14.17C15.18 14.935 16 14.115 16 13.105V2.26503C16 1.25503 15.18 0.435028 14.17 0.435028ZM1.5 9.07499V6.28499L11 4.24503V11.115L1.5 9.07499ZM14.5 13.105C14.5 13.285 14.35 13.435 14.17 13.435H12.83C12.65 13.435 12.5 13.285 12.5 13.105V2.26503C12.5 2.08503 12.65 1.93503 12.83 1.93503H14.17C14.35 1.93503 14.5 2.08503 14.5 2.26503V13.105ZM8.97 13.625C8.6 14.785 7.53 15.565 6.3 15.565C4.76 15.565 3.5 14.325 3.5 12.815C3.5 12.705 3.5 12.585 3.52 12.475C3.57 12.065 3.95 11.775 4.36 11.825C4.77 11.875 5.06 12.245 5.01 12.655C5.01 12.705 5.01 12.755 5.01 12.805C5.01 13.495 5.59 14.055 6.31 14.055C6.88 14.055 7.38 13.695 7.55 13.165C7.67 12.765 8.1 12.545 8.49 12.675C8.89 12.795 9.1 13.225 8.98 13.615L8.97 13.625Z'
          fill='white'
        />
      </svg>
    </SvgIcon>
  );
}

export default CallsToActionIcon;
