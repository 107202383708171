import * as Yup from 'yup';

export const AddSequenceValidationSchema = () => {
    return Yup.object().shape({
        title: Yup.string().required('Required'),
        behaviorId: Yup.string().required('Behavior is required'),
        // callToActionId: Yup.string().required('Call to Action is required')
    })

}

export const AddSequenceNoteValidationSchema = () => {
    return Yup.object().shape({
        title: Yup.string().required('Required'),
        notificationText: Yup.string().required('Required'),
        behaviorId: Yup.string().required('Behavior is required'),
        callToActionId: Yup.string().required('Call to Action is required'),
        day: Yup.number().required("Day is required"),
        time: Yup.string().required("Time is required")
    })

}