import React, { useEffect } from "react";
import { InputField } from "../../../lib";
import { useFormikContext } from "formik";
import { FieldsContainer } from "src/styles/Containers";

function AddMeasurementFields() {
  const formikProps = useFormikContext();
  const [focus, setFocus] = React.useState(true);

  useEffect(() => {
    formikProps.setTouched({});
    setFocus(true);
  }, []);

  return (
    <FieldsContainer>
      <InputField
        placeholder={"Measurement *"}
        name={"name"}
        inputRef={(input: any) => (focus ? input && input.focus() : null)}
        onFocus={(e: any) => {
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          );
        }}
      />
    </FieldsContainer>
  );
}

export default AddMeasurementFields;
