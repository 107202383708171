import dayjs, { Dayjs } from "dayjs";
import { AddUserProps } from "../../../lib"
import { AddReminderProps } from "../../../lib/Types/ReminderProps"

export const reminderIntialValue :AddReminderProps =  {
    title: "",
    noteId:"",
    reminderType: "NOTE",
    datetime: null,
    repeat: "Does not repeat",
    userId: 0,
    date: dayjs(),
    time: dayjs(),
    datetimeStr:"",
    isActive:1
} 

export const editReminderIntitialValues=(item: any): AddReminderProps=>{

    const dateTimeWithoutTimeZone = dayjs(`${item?.datetimeStr}`, 'YYYY-MM-DD HH:mm');

    console.log("dateTimeWithoutTimeZone ", item?.datetimeStr)
    
    return {
        title:item.title ?? '',
        repeat: item.repeat ?? '',
        datetime: item?.datetime ?? '', 
        reminderType: item?.reminderType,
        userId: item.userId,
        noteId: item?.reminderType ==="NOTE" ? item?.noteId: item?.sequenceId,
        date: dayjs(dateTimeWithoutTimeZone),
        time: dayjs(dateTimeWithoutTimeZone),
        datetimeStr: item?.datetimeStr,
        isActive:1,
        isSystem:  item.isSystem
    }
}

export const formattedReminderValues=(values: any, clientId: number): AddReminderProps =>{
    
    const dateString = values.date.format('YYYY-MM-DD');
    const timeString = values.time.format('HH:mm');
    const datetime = dayjs(`${dateString} ${timeString}`, 'YYYY-MM-DD HH:mm');

    return {
        title:values.title ?? '',
        repeat: values.repeat ?? '',
        datetime:  datetime.toDate(),
        datetimeStr: `${dateString} ${timeString}`,
        noteId: values?.reminderType == "NOTE" ? values.noteId: null,
        sequenceId: values.noteId, 
        reminderType: values?.reminderType,
        userId: clientId,
        isActive: 1
    }
}