import { LoginInputField } from "../../../lib";
import { FieldContainer } from "src/styles/Containers";

function AddResetPasswordFields() {
  return (
    <FieldContainer>
      <LoginInputField
        type={"password"}
        placeholder={"New password"}
        name={"password"}
      />
      <LoginInputField
        type={"password"}
        placeholder={"Confirm password"}
        name={"confirmPassword"}
      />
    </FieldContainer>
  );
}

export default AddResetPasswordFields;
