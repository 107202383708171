import SvgIcon from "@mui/material/SvgIcon";

function BehaviorsIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <g
          id='category-variety-random-shuffle_svgrepo.com'
          clipPath='url(#clip0_393_172)'
        >
          <path
            id='Vector'
            d='M5.45509 6.88229H1.5904C1.1991 6.88193 0.823928 6.72635 0.547195 6.44971C0.270461 6.17306 0.114772 5.79793 0.114288 5.40663V1.58995C0.114772 1.19865 0.270461 0.823519 0.547195 0.546871C0.823928 0.270224 1.1991 0.114651 1.5904 0.114288H5.45509C5.84631 0.114772 6.22137 0.270398 6.498 0.547033C6.77464 0.823667 6.93026 1.19873 6.93075 1.58995V5.40663C6.93026 5.79785 6.77464 6.17291 6.498 6.44954C6.22137 6.72618 5.84631 6.8818 5.45509 6.88229ZM1.5904 1.25715C1.50213 1.25727 1.41751 1.29235 1.35505 1.35473C1.29259 1.4171 1.25739 1.50168 1.25715 1.58995V5.40663C1.25739 5.4949 1.29259 5.57948 1.35505 5.64185C1.41751 5.70422 1.50213 5.73931 1.5904 5.73943H5.45509C5.54331 5.73931 5.62789 5.70421 5.69028 5.64182C5.75267 5.57944 5.78777 5.49486 5.78789 5.40663V1.58995C5.78777 1.50172 5.75267 1.41714 5.69028 1.35475C5.62789 1.29237 5.54331 1.25727 5.45509 1.25715H1.5904Z'
            fill='white'
            stroke='white'
            strokeWidth='0.25'
          />
          <path
            id='Vector_2'
            d='M5.47383 15.8857H1.60914C1.2178 15.8852 0.842625 15.7296 0.565904 15.4528C0.289184 15.1761 0.13351 14.8009 0.133026 14.4096V10.5934C0.13351 10.2021 0.289199 9.82694 0.565932 9.5503C0.842666 9.27365 1.21784 9.11808 1.60914 9.11771H5.47383C5.86489 9.11844 6.23971 9.27417 6.51615 9.55078C6.79259 9.82739 6.94809 10.2023 6.94857 10.5934V14.4096C6.94821 14.8007 6.79276 15.1758 6.51631 15.4525C6.23986 15.7292 5.86497 15.885 5.47383 15.8857ZM1.60914 10.2606C1.52087 10.2607 1.43625 10.2958 1.37379 10.3582C1.31133 10.4205 1.27613 10.5051 1.27588 10.5934V14.4096C1.276 14.4979 1.31115 14.5826 1.37363 14.6451C1.4361 14.7076 1.52079 14.7427 1.60914 14.7429H5.47383C5.56209 14.7426 5.64667 14.7074 5.70905 14.645C5.77142 14.5825 5.80651 14.4979 5.80663 14.4096V10.5934C5.8065 10.5051 5.7714 10.4206 5.70902 10.3582C5.64663 10.2958 5.56205 10.2607 5.47383 10.2606H1.60914Z'
            fill='white'
            stroke='white'
            strokeWidth='0.25'
          />
          <path
            id='Vector_3'
            d='M14.41 15.8857H10.5454C10.154 15.8852 9.77884 15.7296 9.50212 15.4528C9.2254 15.1761 9.06973 14.8009 9.06924 14.4096V10.5934C9.06973 10.2021 9.22542 9.82694 9.50215 9.5503C9.77888 9.27365 10.1541 9.11808 10.5454 9.11771H14.41C14.8013 9.1182 15.1763 9.27382 15.453 9.55046C15.7296 9.82709 15.8852 10.2022 15.8857 10.5934V14.4096C15.8853 14.8009 15.7298 15.1761 15.4531 15.4528C15.1765 15.7295 14.8013 15.8852 14.41 15.8857ZM10.5454 10.2629C10.4571 10.263 10.3725 10.2981 10.31 10.3604C10.2475 10.4228 10.2123 10.5074 10.2121 10.5957V14.4119C10.2122 14.5002 10.2474 14.5849 10.3098 14.6474C10.3723 14.7099 10.457 14.745 10.5454 14.7451H14.41C14.4983 14.7449 14.5829 14.7097 14.6453 14.6472C14.7076 14.5848 14.7427 14.5002 14.7428 14.4119V10.5934C14.7427 10.5051 14.7076 10.4206 14.6452 10.3582C14.5828 10.2958 14.4983 10.2607 14.41 10.2606L10.5454 10.2629Z'
            fill='white'
            stroke='white'
            strokeWidth='0.25'
          />
          <path
            id='Vector_4'
            d='M12.48 6.88228C11.812 6.87732 11.1605 6.67471 10.6075 6.29999C10.0545 5.92527 9.62482 5.39522 9.37263 4.77666C9.12044 4.15811 9.05703 3.47873 9.19038 2.82419C9.32373 2.16964 9.64789 1.56923 10.122 1.09864C10.5961 0.628049 11.1989 0.308351 11.8544 0.179848C12.5099 0.0513446 13.1888 0.119788 13.8054 0.376551C14.4221 0.633314 14.949 1.0669 15.3196 1.62265C15.6902 2.1784 15.888 2.83144 15.888 3.49943C15.8835 4.3996 15.5222 5.26125 14.8834 5.89541C14.2445 6.52957 13.3802 6.88447 12.48 6.88228ZM12.48 1.25943C12.0381 1.26439 11.6075 1.39997 11.2424 1.6491C10.8774 1.89823 10.5942 2.24978 10.4285 2.65949C10.2628 3.06921 10.222 3.51879 10.3113 3.95164C10.4005 4.38449 10.6158 4.78126 10.9301 5.09202C11.2443 5.40278 11.6435 5.61363 12.0773 5.69805C12.5111 5.78246 12.9602 5.73666 13.3681 5.5664C13.7759 5.39615 14.1243 5.10905 14.3693 4.74125C14.6144 4.37345 14.7451 3.94138 14.7451 3.49943C14.7415 2.90189 14.501 2.3302 14.0763 1.90982C13.6516 1.48944 13.0775 1.25471 12.48 1.25714V1.25943Z'
            fill='white'
            stroke='white'
            strokeWidth='0.25'
          />
        </g>
        <defs>
          <clipPath id='clip0_393_172'>
            <rect width='16' height='16' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default BehaviorsIcon;
