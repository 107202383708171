

const useStrugglingOptions = () => {

    const options: any = [
      {
        Name: "Yes",
        value: 'Yes',
        Enabled: 1,
      },
      {
        Name: "No",
        value: 'No',
        Enabled: 1,
      },
    ];
    
  return options;
};

export default useStrugglingOptions;
