import { useState, useEffect } from 'react'
import { privateAgent } from '../../Requests/AuthRequests';
import { routesName } from '../../RoutesName/Routes';
import { ClassesProps } from '../../Types';

 const useClassesOptions = () => {
 const [classesOptions, setClassesOption] = useState([]);

 const fetchClasses = async()=>{
     const res = await privateAgent.get(routesName.ClassesRoute({}).get_classes)
     if(res.status==200){
        const classes = res.data.result.items.map((item: ClassesProps) => {

            return {
                'Name': `${item.name}`,
                'value': item.id,
                'Enabled': 1
            }
        })
        setClassesOption(classes)
     }else{
      setClassesOption([])
     }

 }

 useEffect(() => {
  fetchClasses()

  return () => {
    setClassesOption([])
  };
 }, []);

 return classesOptions;
};

export default useClassesOptions
