import { Link } from "react-router-dom";
import {
  Button,
  ResetPwProps,
  privateAgent,
  SubTitle,
  TextButton,
  Title,
} from "../../lib";
import { Formik } from "formik";
import { routesName } from "../../lib/RoutesName/Routes";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import ResetPwValidationSchema from "../../components/formFields/ForgotPassword/ResetPwValidationSchema";
import AddResetPasswordFields from "../../components/formFields/ForgotPassworda/addResetPasswordFields";
import LoginPageLayout from "../../components/shared/loginPageLayout";
import {
  ButtonContainer,
  FormContainer,
  LoginContainer,
  TitleContainer,
} from "../../components/styles/login.styled";

function ResetPasswordPage() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const { resetToken } = params;

  const handleSubmit = async (values: ResetPwProps) => {
    try {
      const { data } = await privateAgent.post(
        routesName.AuthRoute({ token: resetToken }).resetPassword,
        values
      );

      enqueueSnackbar(data.result ?? "", { variant: "success" });
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
    }
  };

  return (
    <LoginPageLayout>
      <Formik
        initialValues={{ password: "", confirmPassword: "" }}
        validationSchema={ResetPwValidationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <FormContainer>
            <LoginContainer>
              <TitleContainer>
                <Title label={"Reset your Password"} />
                <SubTitle
                  label={
                    "Don't worry, it happens. Please enter new password to reset your old password."
                  }
                />
              </TitleContainer>

              <AddResetPasswordFields />

              <ButtonContainer>
                <Button
                  width={"156px"}
                  type={"submit"}
                  label={"Update Password"}
                  disabled={!formikProps.isValid}
                ></Button>
                <Link to={`/login`}>
                  <TextButton label={"Back"} />
                </Link>
              </ButtonContainer>
            </LoginContainer>
          </FormContainer>
        )}
      </Formik>
    </LoginPageLayout>
  );
}

export default ResetPasswordPage;
