/* eslint-disable @typescript-eslint/ban-ts-comment */

import { TextField } from "@mui/material";
import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Field, useField } from "formik";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

type InputFieldPrps = {
  name: string;
  placeholder: string;
  [key: string]: any;
};

const TimeField = (props: InputFieldPrps) => {
  let { label, options, placeholder, name, ...rest } = props;
  const [field, meta, helpers] = useField(props);
  let { touched, error, value } = meta;
  const { setValue, setTouched } = helpers;
  const isError = touched && error && true;

  const handleChange = (newValue: Dayjs | null) => {
    if(newValue?.isValid()){
      setValue(newValue);
    }
  };
  const StyledTextField = styled(TextField)`
    & .MuiInputBase-input {
      padding: 8.5px 14px;
      transform: unset;
    }
    & .MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
      padding: 8.5px 14px;
      transform: unset;
    }
    &.error .MuiInputBase-input {
      border-color: red;
      color: red;
    }
    &.error .MuiInputLabel-outlined {
      color: red;
    }
  `;

  function _renderHelperText() {
    const touched = meta.touched;
    const error = meta.error;

    if (touched && error) {
      return error;
    }
  }

  function hasError() {
    const touched = meta.touched;
    const error = meta.error;
    if (touched && error) {
      return true;
    }
    return false;
  }

  return (
    <>
      <Field name={field.name}>
        {({}) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              inputFormat="hh:mm a"
              label='Time'
              value={value}
              onChange={handleChange}
              renderInput={(params) => (
                <StyledTextField {...params}
                  placeholder="hh:mm a"
                  className={isError ? 'error' : ''}
                />
              )}
            />
          </LocalizationProvider>
        )}
      </Field>
    </>
  );
};

export default TimeField;
