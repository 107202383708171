/* eslint-disable @typescript-eslint/ban-ts-comment */

import { CircularProgress } from "@mui/material";
import React from "react";
import { SButton, BtnFields } from "../../styles/Buttons";

export type variety = "normal" | "error";

// type BtnFields = {
//   label?: string;
//   loading?: boolean;
//   disabled?: boolean;
//   width?: string;
//   variety?: variety;
//   onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
// };

// const SButton = styled(Button)<BtnFields>`
//   height: 42px;
//   width: auto;
//   left: 0px;
//   top: 0px;
//   border-radius: 4px;
//   padding: 10px 24px 10px 24px;
//   @media (max-width: 800px) {
//     padding: 5px 12px 5px 12px;
//   }
//   @media (max-width: 450px) {
//     font-size: x-small;
//   }
//   @media (max-width: 450px) {
//     font-size: xx-small;
//   }
//   background-color: ${(props) =>
//     props.variety == "error" ? "#DD4343" : "#172882"};
//   color: white;
//   text-transform: none;
//   font-weight: 700;
//   font-size: 1rem;
//   // display:'flex';
//   gap: 5px;
//   &:hover {
//     background-image: linear-gradient(rgb(0 0 0/38%) 0 0);
//     background-color: ${(props) =>
//       props.variety == "error" ? "#DD4343" : "#172882"};
//   }
//   & > .MuiCircularProgress-root {
//     color: white;
//     margin-right: 0.5rem;
//   }
//   &.Mui-disabled {
//     opacity: 0.5;
//     background-color: ${(props) =>
//       props.variety == "error" ? "#DD4343" : "#172882"};
//     color: white;
//   }
// `;

const MyButton = ({
  label,
  loading,
  disabled,
  onClick,
  variety,
  hide,
  ...rest
}: BtnFields) => {
  return (
    <React.Fragment>
      { !hide && <SButton
        {...rest}
        variety={variety}
        variant='contained'
        type={"submit"}
        disabled={disabled}
        onClick={onClick}
      >
        {loading && <CircularProgress size={"1rem"} />}
        {label}
      </SButton>}
    </React.Fragment>
  );
};

export default MyButton;

MyButton.defaultProps = {
  type: "text",
  disabled: false,
  hide: false
};
