import { device } from "./Breakpoints";
import styled from "@emotion/styled";
import { Menu, MenuItem, TextField } from "@mui/material";

export const StyledInputField = styled(TextField)`
  flex: 1;
  margin-top: 8px;
`;
export const MenuStyled = styled(Menu)`
  border: 0px;
  padding: 0;
`;
export const SelectMenu = styled(MenuItem)`
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 0;
  background: #fff;
  margin: 0;
  // padding: 8px;
  color: #172882;
  & > :first-of-type {
    box-sizing: border-box;
    height: 16px;
  }
`;
