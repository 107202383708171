

const useReminderTypeOptions = () => {

    const typeOptions: any = [
      {
        Name: "Note",
        value: "NOTE",
        Enabled: 1,
      },
      {
        Name: "Sequence",
        value: "SEQUENCE",
        Enabled: 1,
      },
    ];
    
  return typeOptions;
};

export default useReminderTypeOptions;
