import * as Yup from "yup";

// title: string;
//   userId: number;
//   createdBy?: number;
//   sequenceId?: number;
//   noteId?: number;
//   reminderType: ReminderType;
//   datetime: Date | null;
//   date?:any;
//   time?:any;
//   datetimeStr: string;
//   repeat: FREQUENCY;
//   isActive: number;

export const  AddReminderValidationSchema = ()=> {
  return Yup.object().shape({
    title: Yup.string().required('Required'),
    noteId: Yup.number().required('Required')
  
  });
}


