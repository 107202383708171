import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// fonts
import "@fontsource/nunito"; // Defaults to weight 400
import "@fontsource/nunito/400.css"; // Specify weight
import "@fontsource/nunito/400-italic.css"; // Specify weight and style

import { SnackbarProvider } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";

declare module "@mui/material/styles" {
  interface Palette {
    paigeBlue: {
      primary: string;
    };
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    paigeBlue: {
      primary: string;
    };
  }
}

const theme = createTheme({
  palette: {
    paigeBlue: {
      primary: "#172882",
    },
    primary: {
      main: "#172882",
    },
    grey: {
      "100": "#242424",
      "200": "#45565E",
      "300": "#75868E",
      "400": "#ADB7BC",
      "500": "#E2E6E8",
      "600": "#F5F6F6",
    },
  },
  typography: {
    fontFamily: "Nunito, Roboto, Arial, sans-serif", // Add your desired font here
  },
});
const emotionTheme = {
  colors: {
    primary: "blue",
    secondary: "green",
  },
};

const combinedTheme = {
  ...theme,
  ...emotionTheme,
};

const container = document.getElementById("root")!;
const root = createRoot(container);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <ThemeProvider theme={combinedTheme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </SnackbarProvider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);
