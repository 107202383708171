import { useNavigate } from "react-router-dom";
import GoBackIcon from "../../assets/GoBack";
import { LinkButton } from "src/styles/Containers";

interface NavigateBackProps {
  text: string;
  link?: string;
}
function NavigateBack(props: NavigateBackProps) {
  const navigate = useNavigate();
  const { text, link } = props;

  return (
    <LinkButton
      onClick={() => {
        navigate(-1);
      }}
    >
      <GoBackIcon />
      <span>{text}</span>
    </LinkButton>
  );
}

export default NavigateBack;
