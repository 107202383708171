import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const SideBarContainer = styled.div`
  width: 163px;
  display: flex;
  flex-direction: column;
  // height: calc(100% - 56px);
  height: calc(100% - 104px);
  padding: 24px;
  gap: 16px;
  background: linear-gradient(180deg, #162780 0%, #223ab9 100%);
  & .active {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const MenuTitle = styled(Typography)`
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
`;
export const LinkWithImage = styled(Link)`
  display: flex;
  padding: 8px 0px;
  align-items: center;
  align-self: stretch;
  text-decoration: none;
  border-radius: 8px;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;
