import { device } from "./Breakpoints";
import { Button } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

export type variety = "normal" | "error";

export type BtnFields = {
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  width?: string;
  variety?: variety;
  hide?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const SButton = styled(Button)<BtnFields>`
  height: 42px;
  width: auto;
  left: 0px;
  top: 0px;
  border-radius: 4px;
  padding: 10px 24px 10px 24px;
  // @media ${device.md} {
  //   min-height: 40px;
  //   tap-highlight-color: transparent;
  //   -webkit-tap-highlight-color: transparent;
  // }
  // @media (max-width: 800px) {
  //   height: 25px;
  //   min-height: 25px;
  //   padding: 5px 12px 5px 12px;
  // }
  @media (max-width: 450px) {
    font-size: x-small;
    height: auto;
    // height: 20px;
    // min-height: 20px;
  }
  background-color: ${(props) =>
    props.variety == "error" ? "#DD4343" : "#172882"};
  color: white;
  text-transform: none;
  font-weight: 700;
  font-size: 1rem;
  // display:'flex';
  gap: 5px;
  &:hover {
    background-image: linear-gradient(rgb(0 0 0/38%) 0 0);
    background-color: ${(props) =>
      props.variety == "error" ? "#DD4343" : "#172882"};
  }
  & > .MuiCircularProgress-root {
    color: white;
    margin-right: 0.5rem;
  }
  &.Mui-disabled {
    opacity: 0.5;
    background-color: ${(props) =>
      props.variety == "error" ? "#DD4343" : "#172882"};
    color: white;
  }
`;
export const AltButton = styled(Button)<BtnFields>`
  color: #172882;
  text-transform: none;
  font-size: 1rem;
  font-weight: 700;
  height: 42px;
  width: auto;
  left: 0px;
  top: 0px;
  border-radius: 4px;
  padding: 10px 24px 10px 24px;
  gap: 5px;

  // @media ${device.md} {
  //   min-height: 40px;
  //   tap-highlight-color: transparent;
  //   -webkit-tap-highlight-color: transparent;
  // }
  // @media (max-width: 800px) {
  //   height: 25px;
  //   min-height: 25px;
  //   padding: 5px 12px 5px 12px;
  // }
  @media (max-width: 450px) {
    height: auto;
    // height: 20px;
    // min-height: 20px;
    font-size: x-small;
  }
`;
