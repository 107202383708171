import PaigeAIClientFormFields from "@components/formFields/PaigeAi/PaigeAiClientFormFields";
import {
  PageAiClientLizardPathSchema,
  PageAiClientYouPathSchema,
} from "@components/formFields/PaigeAi/PaigeAiSchema";
import PaigeSkeleton from "@components/formFields/skeleton/PaigeSkeleton";
import { useTitle } from "@lib/Hooks";
import { privateAgent, UserProps } from "@lib/index";
import { routesName } from "@lib/RoutesName";
import { AiClientInfoProps, AiProps } from "@lib/Types/AiProps";
import { PaigeResponse } from "@lib/Types/Response";
import Cookies from "js-cookie";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { QueryClient, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  SeqRowContainer,
  TopContainerNoMargin,
  UsersContainer,
} from "src/styles/Containers";
import { TitleLarge } from "src/styles/Typography";

type PAGE_FOR = "client" | "coach";

export interface ClientInfoPageProps {
  pagefor: PAGE_FOR;
}

let youPathInitialValue = {
  you_path_input: "",
};

let lizarPathInitialValue = {
  lizard_path_input: "",
};

type PaigeAIClientResponse = {
  result: string;
  status: number;
  message: string;
};

export default function ClientInfo({ pagefor }: ClientInfoPageProps) {
  useTitle("Paige AI | Paige");

  const [user, setUser] = useState<UserProps>();
  const [loadingYouPath, setYouPathLoading] = useState<boolean>(false);
  const [loadingLizardPath, setLizardPathLoading] = useState<boolean>(false);
  const params = useParams();
  const queryClient =useQueryClient();

  const { clientId } = params;
  let { isLoading, data: clientFullName } = useQuery(
    ["Client"],
    async () => {
      const userId = pagefor == "coach" ? clientId : Cookies.get("userId");
      const { data } = await privateAgent.get(
        routesName.UserRoute({ id: userId }).get_single_user
      );

      if (data.status === 200) {
        const results = `${data.result.userdetails.firstName} ${data.result.userdetails.lastName}`;
        setUser(data.result);
        return results;
      }
    },
    { enabled: pagefor == "coach" || pagefor == "client" }
  );

  let { isLoading: clientInfoLoading, data: aiClientInfo } = useQuery(
    ["ClientInfo"],
    async () => {
     
      const { data } = await privateAgent.get(
        routesName.PaigeAiRoute({ id: clientId }).get_client_info
      );

      if (data.status === 200) {
        const result = data.result;
        return result
      }
    },
    { enabled: !! clientId  }
  );


  useEffect(() => {
    if(aiClientInfo){
      youPathInitialValue.you_path_input = aiClientInfo.youPath
      lizarPathInitialValue.lizard_path_input = aiClientInfo.lizardPath
    }
  },[aiClientInfo])

  const postClientInfo = async (values: AiClientInfoProps) => {
    return await privateAgent.post(
      routesName.PaigeAiRoute({ id: clientId }).add_or_edit_client_info,
      values
    );
  };

  const onSubmitYouPath = async (v: any) => {
    const values: AiClientInfoProps = v;

    setYouPathLoading(true);

    try {
      const { data  } = await postClientInfo(values)

      if (data.status === 201) {
          enqueueSnackbar(data.result, {
            variant: "success",
          });

          queryClient.invalidateQueries("ClientInfo");
      
      }
    } catch (error:any) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      setYouPathLoading(false);
    }
  };

  const onSubmitLizardPath = async (v: any) => {
    const values: AiClientInfoProps = v;
    setLizardPathLoading(true);
    try {
      const { data } = await postClientInfo(values);

      if (data.status === 201) {
          enqueueSnackbar(data.result, {
            variant: "success",
          });
          queryClient.invalidateQueries("ClientInfo");
        }else{
            enqueueSnackbar("something went wrong", {
                variant: "error",
              }); 
        }
      
    } catch (error) {
      enqueueSnackbar("something went wrong", {
        variant: "error",
      });
    } finally {
      setLizardPathLoading(false);
    }
  };

  if( clientInfoLoading ){ 
    return <PaigeSkeleton />
  }

  return (
    <>
      <TopContainerNoMargin>
        <TitleLarge>
          {clientFullName ? `${clientFullName}'s Client info` : ""}
        </TitleLarge>
        <SeqRowContainer></SeqRowContainer>
      </TopContainerNoMargin>

      <UsersContainer>
        <PaigeAIClientFormFields
          setLoading={setYouPathLoading}
          loading={loadingYouPath}
          handleSubmit={onSubmitYouPath}
          initialValues={youPathInitialValue}
          validationSchema={PageAiClientYouPathSchema}
          resetData={() => {}}
          label="You Path"
          name="you_path_input"
          placeholder="Add client 'You path' here"
        />
      </UsersContainer>
      <TopContainerNoMargin />
      <UsersContainer>
        <PaigeAIClientFormFields
          setLoading={setLizardPathLoading}
          loading={loadingLizardPath}
          handleSubmit={onSubmitLizardPath}
          initialValues={lizarPathInitialValue}
          validationSchema={PageAiClientLizardPathSchema}
          resetData={() => {}}
          label="Lizard Path"
          name="lizard_path_input"
          placeholder="Add client 'Lizard path' here"
        />
      </UsersContainer>
    </>
  );
}
