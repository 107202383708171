import SvgIcon from "@mui/material/SvgIcon";

function NotesIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          d='M8.66663 13.3327C8.60336 13.3333 8.53236 13.3333 8.45129 13.3333H4.7979C4.05262 13.3333 3.67943 13.3333 3.39449 13.1881C3.14361 13.0603 2.93978 12.8561 2.81195 12.6053C2.66663 12.3201 2.66663 11.9469 2.66663 11.2001V4.80013C2.66663 4.05339 2.66663 3.67975 2.81195 3.39453C2.93978 3.14365 3.14361 2.93982 3.39449 2.81199C3.67971 2.66667 4.05335 2.66667 4.80009 2.66667H11.2001C11.9468 2.66667 12.3197 2.66667 12.6049 2.81199C12.8558 2.93982 13.0603 3.14365 13.1881 3.39453C13.3333 3.67947 13.3333 4.05266 13.3333 4.79794V8.4498C13.3333 8.53153 13.3333 8.603 13.3327 8.66667M8.66663 13.3327C8.85709 13.3311 8.97749 13.3241 9.09249 13.2965C9.22856 13.2639 9.35856 13.2098 9.47783 13.1367C9.61236 13.0543 9.72769 12.9393 9.95829 12.7087L12.7086 9.95833C12.9392 9.72773 13.0539 9.6124 13.1364 9.47787C13.2094 9.3586 13.2635 9.22827 13.2962 9.09227C13.3238 8.97727 13.3309 8.857 13.3327 8.66667M8.66663 13.3327V9.7334C8.66663 9.36 8.66663 9.1732 8.73929 9.0306C8.80323 8.90513 8.90509 8.80327 9.03056 8.73933C9.17316 8.66667 9.35969 8.66667 9.73303 8.66667H13.3327'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
}

export default NotesIcon;
