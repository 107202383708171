import { useState, useEffect } from 'react'
import { privateAgent } from '../../Requests/AuthRequests';
import { routesName } from '../../RoutesName/Routes';
import { ClassesProps } from '../../Types';
import { BehaviorProps } from '../../Types/BehaviorProps';

 const useBehaviorOptions = () => {
 const [options, setOptions] = useState([]);

 const fetchBehavior = async()=>{
     const res = await privateAgent.get(routesName.BehaviorRoute({}).get_behavior)
     if(res.status==200){
        const options = res.data.result.items.map((item: BehaviorProps) => {

            return {
                'Name': `${item.name}`,
                'value': item.id,
                'Enabled': 1
            }
        })
        setOptions(options)
     }else{
      setOptions([])
     }

 }

 useEffect(() => {
  fetchBehavior()

  return () => {
    setOptions([])
  };
 }, []);

 return options;
};

export default useBehaviorOptions
