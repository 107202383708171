import { useParams } from "react-router-dom";
import { useTitle } from "@lib/Hooks";

import styled from "@emotion/styled";
import { useState } from "react";
import { Button, DialogBox } from "@lib/Forms";
import SittingIcon from "@assets/Sitting";
import ReceptionIcon from "@assets/Reception";
import WifiIcon from "@assets/Wifi";
import BatteryIcon from "@assets/Battery";
import JumpingIcon from "@assets/Jumping";
import TitleBackgroundIcon from "@assets/TitleBackground";
import { useFormikContext } from "formik";
import useCallToActionOptions from "@lib/Hooks/options/useCalltoActionOption";

const Container = styled.div`
  max-width: 390px;
  background: #fff;
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 13px;
`;
const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: #000;
  gap: 18px;
  font-style: normal;
  font-weight: 600;

  & svg {
    padding-left: 5px;
  }
`;
const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #172882;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 18px;
  & div {
    cursor: pointer;
  }
`;
const HeaderContainer = styled.div`
  min-height: 150px;
  position: relative;
  display: flex;
  flex-direction: row;

  & .title {
    width: 65%;
    padding: 20px;
    position: relative;
    z-index: 2;
    color: #333;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
const someContainer = styled.div`
  width: 100%;
  position: absolute;
`;

const ImageContainer = styled.div`
  width: 35%;
  z-index: 2;
  display: flex;
  align-items: center;
  position: relative;
`;
const ActionContainer = styled.div`
  width: 100%;
  & button {
    padding: 8px 20px;
    width: 100%;
  }
`;
const CallToActionContainer = styled.div`
  & p {
    color: #45565e;
    text-align: center;
    font-family: Noto Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    // line-height: 150%; /* 30px */
  }
`;
interface ViewNotePageProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen : (isOpen: boolean) =>void
}

function ViewNotePage({ isOpen, onClose , setIsOpen}: ViewNotePageProps) {
  // console.log("note any ", note);
  const calltoActionOptions = useCallToActionOptions()

  const formikProps = useFormikContext();
  const data = formikProps.values  as any
  useTitle("Note | Paige");
  const [currentStep, setCurrentStep] = useState(0);
  const params = useParams();
  const today = new Date();

  const time =
    (today.getHours() == 0 ? "12" : today.getHours()) +
    ":" +
    String(today.getMinutes()).padStart(2, "0");

  const handleBackClick = (event: React.MouseEvent<HTMLElement>) => {
    if (currentStep > 0) setCurrentStep(currentStep - 1);
    else {
      setIsOpen(false);
      setCurrentStep(0)
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (currentStep < data?.noteSteps?.length - 1) setCurrentStep(currentStep + 1);
    else {
      setIsOpen(false);
      setCurrentStep(0)
    }
  };

  return (
    <>
      <DialogBox
        className='preview'
        isOpen={isOpen}
        onClose={() => {
           setIsOpen(false);
           setCurrentStep(0)
         }}
        title={""}
        content={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Container>
              <TimeContainer>
                <div>{time}</div>
                <div>
                  <ReceptionIcon></ReceptionIcon>
                  <WifiIcon></WifiIcon>
                  <BatteryIcon></BatteryIcon>
                </div>
              </TimeContainer>
              <NavigationContainer>
                <div onClick={handleBackClick}>Back</div>
                <div onClick={handleClick}>
                  {currentStep == data?.noteSteps?.length - 1
                  //@ts-ignore
                  ? calltoActionOptions.find((call : any)=>call.value == data.callToActionId)?.Name ?? "Done and Done !!!"
                    : "Next"}
                </div>
              </NavigationContainer>
              <HeaderContainer>
                <div className='title'>{data?.title}</div>
                <ImageContainer>
                  <JumpingIcon
                    style={{ width: "100% !important" }}
                  ></JumpingIcon>
                </ImageContainer>
                <div
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: "0",
                  }}
                >
                  <TitleBackgroundIcon
                    style={{
                      width: "100% !important",
                      top: "0",
                      right: "0",
                    }}
                  ></TitleBackgroundIcon>
                </div>
              </HeaderContainer>
              <div>
                {data?.noteSteps?.map((noteStep: { text: string; }, index: number) => {
                  return (
                    <div
                      style={{
                        display: index == currentStep ? "block" : "none",
                      }}
                    >

                      <div dangerouslySetInnerHTML={{ __html: data['noteSteps'+ index]?.text }} />

                      {/* { data['noteSteps'+ index].text} */}
                    </div>
                  );
                })}
              </div>
              {/* <CallToActionContainer
                style={{
                  display:
                    currentStep == note.noteSteps.length -1 ? "flex" : "none",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "200px",
                  }}
                >
                  <SittingIcon
                    style={{ height: "100% !important" }}
                  ></SittingIcon>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              </CallToActionContainer> */}

              <ActionContainer>
                <Button
                  label={
                    currentStep == data?.noteSteps?.length - 1
                    //@ts-ignore
                      ? calltoActionOptions.find((call : any)=>call.value == data.callToActionId)?.Name ?? "Done and Done !!!"
                      : "Next"
                  }
                  type={"button"}
                  onClick={handleClick}
                ></Button>
              </ActionContainer>
            </Container>
          </div>
        }
      />
    </>
  );
}

export default ViewNotePage;
