import { LoginInputField } from "../../../lib";
import { FieldContainer } from "src/styles/Containers";

function AddForgotPasswordFields() {
  return (
    <FieldContainer>
      <LoginInputField placeholder={"Email"} name={"email"} />
    </FieldContainer>
  );
}

export default AddForgotPasswordFields;
