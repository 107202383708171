import { useState } from "react";
import { Button, TextButton } from "@lib/Forms";

import { Formik } from "formik";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { routesName } from "@lib/RoutesName/Routes";
import AddLinkNoteFields from "@components/formFields/Notes/AddLinkNoteFields";
import NavigateBack from "@components/shared/NavigateBack";
import { useNavigate, useParams } from "react-router-dom";
import { noteEditValue, noteInitialValue } from "./notes-helper";
import { useTitle } from "@lib/Hooks";
import { privateAgent } from "@lib/Requests/AuthRequests";
import { PAGE_FOR } from "@pages/pagefor";
import Cookies from "js-cookie";
import { StyledForm } from "src/styles/Forms";
import {
  TableContainer,
  TopContainer,
  ButtonContainerEnd,
  NoteContainer,
} from "src/styles/Containers";
import { Title, Header } from "src/styles/Typography";

// const TableContainer = styled.div`
//   padding: 16px;
//   border-radius: 4px;
//   gap: 16px;
//   background: #ffffff;
//   box-shadow: 0px 2px 5px 0px #0000000;
//   display: flex;
//   flex-direction: column;
// `;
interface AddLinkNotePageProps {
  isEdit?: boolean;
  pagefor: PAGE_FOR;
}

function AddLinkNotePage(props: AddLinkNotePageProps) {
  const { isEdit, pagefor } = props;
  useTitle("Client Note | Paige");
  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();
  const { clientId, noteId } = params;
  const [fieldInitialValues, setFieldInitialValues] =
    useState(noteInitialValue);

  let { isLoading, data: editNotes } = useQuery(["singleNote"], async () => {
    const { data } = await privateAgent.get(
      routesName.NoteRoute({ id: noteId }).get_single_note
    );

    if (data.status === 200) {
      const results = data.result;
      setFieldInitialValues(noteEditValue(results));
      return results;
    }
  });

  const handleSubmit = async (values: any) => {
    console.log("values after submit ", values);
    values.noteSteps = [];
    values.userId = pagefor == "coach" ? clientId : Cookies.get("userId");
    values.noteType = values.noteType == undefined ? "LINK" : values.noteType;
    values.callToActionId = 1;
    values.isSystem = false

    if (isEdit) {
      const res = await privateAgent.put(
        routesName.NoteRoute({ id: noteId }).update_note,
        values
      );
      if (res.status === 200) {
        enqueueSnackbar("Link note edited successfully", {
          variant: "success",
        });
        navigate(-1);
      }
    } else {
      const res = await privateAgent.post(
        routesName.NoteRoute({ }).add_note,
        values
      );
      if (res.status === 201) {
        enqueueSnackbar("Link note added successfully", { variant: "success" });
        navigate(-1);
      }
    }
  };

  return (
    <>
      <NavigateBack text='Notes' />

      <Formik
        enableReinitialize={true}
        initialValues={fieldInitialValues}
        onSubmit={handleSubmit}
      >
        {({ isValid, setValues, values }) => (
          <StyledForm>
            <TopContainer>
              <Header>{isEdit ? "Edit Link Note" : "Add Link Note"}</Header>
              <ButtonContainerEnd>
                <TextButton
                  label={"Cancel"}
                  onClick={() => navigate(-1)}
                ></TextButton>
                <Button label={"Save"} type={"submit"}></Button>
              </ButtonContainerEnd>
            </TopContainer>
            <TableContainer>
              <NoteContainer>
                <AddLinkNoteFields />
              </NoteContainer>
            </TableContainer>
          </StyledForm>
        )}
      </Formik>
    </>
  );
}

export default AddLinkNotePage;
