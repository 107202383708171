import { AddUserProps } from "../../../lib"

export const UserIntialFields :AddUserProps =  {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    classId: '',
    coachId: '',
    userTypeId:'',
    isActive: '1',
    hasAdvancedSettings:'',
    isTrial: '',
    weightMeasurementValueId: null,
} 

export const CreateUsersListObject=(item: any)=>{

    return {
         id: item.id,
         firstName:item.userdetails?.firstName ?? '',
         name: item.userdetails?  `${item.userdetails?.firstName ?? ''} ${item?.userdetails?.lastName ?? ''}`: '' , 
         email: item?.email ?? '', 
         class: item?.classes[0]?.name ?? '', 
         userType: item?.role?.name ?? '', 
         coach: `${item?.coach?.users?.userdetails?.firstName} ${item?.coach?.users?.userdetails?.lastName}`?? '', 
         advancedSettings: 'No', 
         status: item?.isActive ?? false, 
    }
}

export const editUserIntitialValues=(item: any): AddUserProps=>{

    return {
         firstName:item.userdetails?.firstName ?? '',
         lastName: item.userdetails?.lastName ?? '',
         email: item?.email ?? '', 
         password: item?.password,
         classId: item?.classes[0]?.id , 
         userTypeId: item?.role?.id ?? '', 
         coachId: `${item?.coach?.users?.id}`,
         isActive: item?.isActive ? '1': '0', 
         hasAdvancedSettings: item?.hasAdvancedSettings ?'1': '0',
         weightMeasurementValueId: item.weightMeasurementValueId,
         isTrial: item?.isTrial ?'1': '0',
    }
}