import SvgIcon from "@mui/material/SvgIcon";

function EditIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 12 12'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
      >
        <path
          id='Vector'
          d='M2.08 7.7562L1 10.7784L3.88 10.4004L10.666 3.9264C10.8151 3.78453 10.9348 3.61468 11.0183 3.42655C11.1018 3.23842 11.1474 3.03568 11.1525 2.82993C11.1576 2.62417 11.1222 2.41942 11.0482 2.22737C10.9742 2.03531 10.8631 1.85971 10.7212 1.7106C10.5793 1.56148 10.4095 1.44177 10.2214 1.35829C10.0332 1.27482 9.83049 1.22921 9.62473 1.22409C9.41898 1.21896 9.21423 1.25441 9.02217 1.32842C8.83011 1.40242 8.65452 1.51353 8.5054 1.6554L2.08 7.7562Z'
          stroke='#172882'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
}

export default EditIcon;
