import { useState, useMemo } from "react";
import {
  privateAgent,
  Button,
  DialogBox,
  TextButton,
  AlertDialog,
  AddClassesProps,
  useTitle,
} from "../../../lib";
import { DataTable } from "../../../lib/Forms/DataTable";
import { Formik, Form } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { routesName } from "../../../lib/RoutesName/Routes";
import { Mode } from "../../../components/shared/dropdown/mode";
import { useSnackbar } from "notistack";
import {
  classesInitialValues,
  editClasesIntitialValues,
} from "../../../components/formFields/Classes/classes-helpers";
import AddClassesFields from "../../../components/formFields/Classes/AddClassesFields";
import AddClassesValidationSchema from "../../../components/formFields/Classes/AddClassesValidationSchema";
import ClassModeMenu from "../../../components/formFields/Classes/ClassModeMenu";
import { MRT_ColumnDef } from "material-react-table";
import {
  ButtonContainer,
  TopContainerNoMargin,
  UsersContainer,
  TableContainer,
  Tag,
} from "src/styles/Containers";
import { TitleLarge } from "src/styles/Typography";

function ClassPage() {
  useTitle("Class | Paige");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openAddClassesDialog, setOpenAddClassesDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [classId, setClassId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [fieldInitialValues, setFieldInitialValues] =
    useState<AddClassesProps>(classesInitialValues);
  const queryClient = useQueryClient();

  let { isLoading, data } = useQuery(["ClassesQuery"], async () => {
    const { data } = await privateAgent.get(
      routesName.ClassesRoute({}).get_classes
    );
    if (data.status === 200) {
      const results = data.result.items;
      return results;
    }
  });

  const handleSubmit = async (values: AddClassesProps) => {
    try {
      if (isEdit) {
        const res = await privateAgent.put(
          routesName.ClassesRoute({ id: classId }).update_classes,
          values
        );
        if (res.data && res.data.status === 200) {
          queryClient.invalidateQueries("ClassesQuery");
          setOpenAddClassesDialog(false);
          enqueueSnackbar("Class edited successfully", { variant: "success" });
        }
      } else {
        const res = await privateAgent.post(
          routesName.ClassesRoute({}).add_classes,
          values
        );
        if (res.status === 201) {
          queryClient.invalidateQueries("ClassesQuery");
          setOpenAddClassesDialog(false);
          enqueueSnackbar("Class added successfully", { variant: "success" });
        }
      }
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.error ?? "", { variant: "error" });
    }
  };

  const handleDelete = async () => {
    try {
      const res = await privateAgent.delete(
        routesName.ClassesRoute({ id: classId }).delete_classes
      );
      if (res.status === 200) {
        queryClient.invalidateQueries("ClassesQuery");
        setOpenDeleteDialog(false);
        enqueueSnackbar("Class deleted successfully", { variant: "success" });
      }
    } catch (error: any) {
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
    }
  };

  const handleRowClick = (column: any, row: any) => {
    if (column.id === "name") {
      setOpenAddClassesDialog(true);
      setFieldInitialValues(editClasesIntitialValues(row.original));
      setClassId(row.original.id);
      setIsEdit(true);
    }
  };

  type Class = {
    id: number;
    name: string;
    isLast: boolean;
    isActive: string;
  };

  //grid defn
  const columns = useMemo<MRT_ColumnDef<Class>[]>(
    () => [
      {
        accessorFn: (row) => `${row.name}`,
        minSize: 150,
        id: "name",
        header: "Name",
        Cell: ({ cell }) => (
          <div>
            {cell.row.original.name}
            {cell.row.original.isLast && <Tag>Recent</Tag>}
          </div>
        ),
      },
      {
        id: "actions",
        header: "Actions",
        headerClassName: "header-row",
        flex: 1,
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
          className: "action",
        },
        size: 50,
        enableResizing: false, //disable resizing for this column
        Cell: ({ cell }) => (
          <>
            <ClassModeMenu
              id={0}
              onItemClick={function (id: number, mode: Mode): void {
                console.log(cell.row.original);

                if (mode == "edit") {
                  setOpenAddClassesDialog(true);
                  setFieldInitialValues(
                    editClasesIntitialValues(cell.row.original)
                  );
                  setClassId(cell.row.original.id.toString());
                  setIsEdit(true);
                }
                if (mode == "duplicate") {
                  setOpenAddClassesDialog(true);
                  setFieldInitialValues(
                    editClasesIntitialValues(cell.row.original)
                  );
                  setClassId(cell.row.original.id.toString());
                  setIsEdit(false);
                }
                if (mode === "delete") {
                  setClassId(cell.row.original.id.toString());
                  setOpenDeleteDialog(true);
                }
              }}
            />
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <AlertDialog
        variety='error'
        onConfirm={() => handleDelete()}
        onClose={() => setOpenDeleteDialog(false)}
        isOpen={openDeleteDialog}
        title={"Are you sure you want to delete this class?"}
        content={
          "Deleting this class will permanently erase any data associated with this class."
        }
        btnText={{ cancel: "Cancel", action: "Delete" }}
      />
      <DialogBox
        isOpen={openAddClassesDialog}
        onClose={() => setOpenAddClassesDialog(false)}
        title={isEdit ? "Edit Class" : "Add Class"}
        content={
          <Formik
            initialValues={fieldInitialValues}
            onSubmit={handleSubmit}
            validationSchema={AddClassesValidationSchema}
          >
            {(formikProps) => (
              <Form>
                <AddClassesFields />
                <ButtonContainer>
                  <TextButton
                    label={"Cancel"}
                    onClick={() => setOpenAddClassesDialog(false)}
                  ></TextButton>
                  <Button
                    label={"Save"}
                    type={"submit"}
                    disabled={!formikProps.isValid}
                  ></Button>
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        }
      />
      <TopContainerNoMargin>
        <TitleLarge>Classes</TitleLarge>
        <Button
          label={"+ Add Class"}
          onClick={() => {
            setOpenAddClassesDialog(true);
            setIsEdit(false);
            setFieldInitialValues(classesInitialValues);
          }}
        ></Button>
      </TopContainerNoMargin>
      <UsersContainer>
        <TableContainer>
          {data && data.length > 0 && (
            <DataTable
              columns={columns}
              data={data}
              onCellClick={handleRowClick}
            />
          )}
        </TableContainer>
      </UsersContainer>
    </>
  );
}

export default ClassPage;
