import { useState, useEffect } from 'react'
import { privateAgent } from '../../Requests/AuthRequests';
import { routesName } from '../../RoutesName/Routes';
import { ClassesProps } from '../../Types';
import { BehaviorCategoryProps } from '../../Types/BehaviorCategoryProps';
import { NoteProps } from '../../Types/NoteProps';

 const useSystemNotesOption = (clientId?: string) => {
 const [options, setOptions] = useState([]);

 const fetchNotesOfClient = async()=>{
     const res = await privateAgent.get(routesName.SystemNoteRoute({}).get_notes)
     //console.log("RESPONSE FROM SYSTEM REMINDER", res)
     if(res.status==200){
        const options = res.data.result.items.map((item: NoteProps) => {

            return {
                'Name': `${item.title}`,
                'value': item.id,
                'Enabled': 1
            }
        },{
            enabled: !!clientId
        })
        setOptions(options)
     }else{
      setOptions([])
     }

 }

 useEffect(() => {
    fetchNotesOfClient()

  return () => {
    setOptions([])
  };
 }, []);

 return options;
};

export default useSystemNotesOption
