import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import styled from "@emotion/styled";
import EditIcon from "../../../assets/Edit";
import DeleteIcon from "../../../assets/Delete";
import DuplicateIcon from "../../../assets/Duplicate";
import { Mode } from "../../shared/dropdown/mode";
import MoveToSequenceIcon from "../../../assets/MoveToSequence";

const MenuStyled = styled(Menu)`
  border: 1px solid #e2e6e8;
  & .MuiPaper-root {
    // min-width: 200px;
  }
`;
const SelectMenu = styled(MenuItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 0;
  background: #fff;
  margin: 8px 4px;
  color: #172882;
  & > :first-of-type {
    box-sizing: border-box;
    height: 16px;
  }
`;

export interface HoverMenuProps {
  id: number;
  onItemClick: (id: number, mode: Mode) => void;
}

export default function ReminderModeMenu({ id, onItemClick }: HoverMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const OnMenuItemClicked = (mode: Mode) => {
    handleClose();
    onItemClick(id, mode);
  };

  return (
    <div>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon style={{ fill: "#172882" }} />
      </IconButton>
      <MenuStyled
        id='long-menu'
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <SelectMenu onClick={() => OnMenuItemClicked("edit")} disableRipple>
          <EditIcon />
          Edit
        </SelectMenu>

        <SelectMenu
          onClick={() => OnMenuItemClicked("duplicate")}
          disableRipple
        >
          <DuplicateIcon />
          Duplicate
        </SelectMenu>

        <SelectMenu onClick={() => OnMenuItemClicked("delete")} disableRipple>
          <DeleteIcon />
          Delete
        </SelectMenu>
      </MenuStyled>
    </div>
  );
}
