import * as Yup from 'yup';

export const PageAiSchema = () => {
    return Yup.object().shape({
        action: Yup.string().required('Required'),
        user_input: Yup.string().required('Rationalization is required'),
        you_path_input: Yup.string().trim().when('lizard_path_input', {
            is: (lizard_path_input: any) => Boolean(lizard_path_input),
            then: () => Yup.string().required('You Path Required when Lizard Path provided'),
          }),
          lizard_path_input: Yup.string().trim().when('you_path_input', {
            is: (you_path_input:any) => Boolean(you_path_input),
            then: () => Yup.string().required('Lizard Path Required when You Path provided')
          })},
        [['you_path_input','lizard_path_input'],['lizard_path_input','you_path_input']])

}


export const PageAiClientYouPathSchema = () => {
    return Yup.object().shape({
        you_path_input: Yup.string().required('You Path is required'),
    })

}

export const PageAiClientLizardPathSchema = () => {
    return Yup.object().shape({
        lizard_path_input: Yup.string().required('Lizard Path is required'),
    })

}