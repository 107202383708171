import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/Login/LoginPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import ForgetPasswordPage from "./pages/ForgotPassword/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ForgotPassword/ResetPasswordPage";
import { RootLayout } from "./components/layout";
import UserPage from "./pages/Admin/User/UserPage";
import { useQuery, useQueryClient } from "react-query";
import ClassPage from "./pages/Admin/Classes/ClassPage";
import BehaviorTabs from "./pages/Admin/Behaviors/BehavoirTabs";
import CallsToActionPage from "./pages/Admin/CallsToAction/CallsToActionPage";
import MeasurementPage from "./pages/Admin/Measurements/MeasurementPage";
import { Global, css } from "@emotion/react";
import { PagePermission } from "./routes/roleroute";
import Cookies from "js-cookie";
import { privateAgent } from "./lib";
import { routesName } from "./lib/RoutesName/Routes";
import PermissionRoute from "./routes/roleroute";
import NotFoundPage from "./pages/NotFound/NotFoundPage";
import ClientPage from "./pages/Coach/ClientPage";
import CoachTabs from "./pages/Coach/coachTabs";
import AddNotePage from "./pages/Notes/AddNotePage";
import AddLinkNotePage from "./pages/Notes/AddLinkNotePage";
import AddSequencePage from "./pages/Sequence/AddSequencePage";
import AddSequenceNotePage from "./pages/Sequence/AddSequenceNotePage";
import ClientTabs from "./pages/Client/ClientTabs";
import ReminderPage from "./pages/Reminder/ReminderPage";
import SequencePage from "@pages/Sequence/SequencePage";
import SystemDataTabs from "@pages/Admin/SystemData/systemDataTabs";
import AddSystemNotePage from "@pages/Admin/SystemData/Notes/AddSystemNotePage";
import AddSystemLinkNotePage from "@pages/Admin/SystemData/Notes/AddSystemLinkNotePage";
import AddSystemSequencePage from "@pages/Admin/SystemData/Sequence/AddSystemSequencePage";
import AddSystemSequenceNotePage from "@pages/Admin/SystemData/Sequence/AddSystemSequenceNotePage";
import HappinessAnswersPage from "@pages/Admin/HappinessAnswersPage/HappinessAnswersPage";


const globalStyles = css`
  .MuiButton-root + .Mui-disabled {
    pointer-events: none !important;
    opacity: 0.5 !important;
    color: white !important;
    background-color: #172882 !important;
  }

  .MuiInputBase-root {
    border-radius: 8px !important;
  }
  .alertDialog .MuiDialog-container > div {
    padding: 24px;
  }
  .alertDialog .MuiDialog-container h2 {
    font-weight: 700;
  }
  .alertDialog
    .MuiDialog-container
    .MuiDialogContent-root
    #alert-dialog-description {
    color: #000 !important;
  }
`;

function App() {
  const [role, setRole] = React.useState<PagePermission>("");
  const queryClient = useQueryClient();
  let { isLoading, data } = useQuery(["User-Role"], async () => {
    const userId = Cookies.get("userId");
    const { data } = await privateAgent.get(
      routesName.UserRoute({ id: userId }).get_single_user
    );
    if (data.status === 200) {
      const results = `${data.result?.role?.name?.toLowerCase()}`;
      setRole(results as PagePermission);
      return results;
    }
  });

  const onLoginSuccess = () => {
    setRole("");
    queryClient.invalidateQueries("User-Role");
  };

  return (
    <>
      <Global styles={globalStyles} />
      <RootLayout>
        <Routes>
          <Route
            path='/login'
            element={<LoginPage onSuccess={onLoginSuccess} />}
          />
          <Route path='/' element={<Dashboard />} />
          <Route path='/forgotpassword' element={<ForgetPasswordPage />} />
          <Route
            path='/resetpassword/:resetToken'
            element={<ResetPasswordPage />}
          />
          {/* Admin routes */}
          <Route
            path='/users'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <UserPage />
              </PermissionRoute>
            }
          />
          <Route
            path='/classes'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <ClassPage />
              </PermissionRoute>
            }
          />
          <Route
            path='/behaviors'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <BehaviorTabs />
              </PermissionRoute>
            }
          />
          <Route
            path='/callstoaction'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <CallsToActionPage />
              </PermissionRoute>
            }
          />
          <Route
            path='/measurements'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <MeasurementPage />
              </PermissionRoute>
            }
          />

          <Route
            path='/system/notes'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <SystemDataTabs tabIndex={0}/>
              </PermissionRoute>
            }
          />
          <Route
            path='/system/sequences'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <SystemDataTabs tabIndex={1}/>
              </PermissionRoute>
            }
          />
          <Route
            path='/system/reminders'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <SystemDataTabs tabIndex={2}/>
              </PermissionRoute>
            }
          />

          {/* ADMIN ROUTES SYSTEM */}
          <Route
            path='/system/addnote'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <AddSystemNotePage pagefor={"admin"} />
              </PermissionRoute>
            }
          />
          <Route
            path='/system/editnote/:noteId'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <AddSystemNotePage isEdit={true} pagefor={"admin"} />
              </PermissionRoute>
            }
          />

          <Route
            path='/system/addlinknote'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <AddSystemNotePage pagefor={"admin"} />
              </PermissionRoute>
            }
          />

          <Route
            path='/system/editlinknote/:noteId'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <AddSystemNotePage isEdit={true} pagefor={"admin"}/>
              </PermissionRoute>
            }
          />

          <Route
            path='/system/addnote/:noteId/duplicate'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <AddSystemNotePage isEdit={false} pagefor={"admin"} />
              </PermissionRoute>
            }
          />

          <Route
            path='/system/addlinknote/:noteId/duplicate'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <AddSystemLinkNotePage isEdit={false} pagefor={"admin"}/>
              </PermissionRoute>
            }
          />

          {/* Sequence routes */}
          <Route
            path='/admin/:clientId/addsequence'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <AddSystemSequencePage pagefor='admin' />
              </PermissionRoute>
            }
          />
          <Route
            path='/admin/:clientId/editsequence/:noteId'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <AddSystemSequencePage isEdit={true} pagefor='admin' />
              </PermissionRoute>
            }
          />

          <Route
            path='/admin/:clientId/addsequence/:noteId/duplicate'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <AddSystemSequencePage isEdit={false} pagefor='admin' />
              </PermissionRoute>
            }
          />
          <Route
            path='/admin/:clientId/addsequencenote/:sequenceId'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <AddSystemSequenceNotePage isEdit={false} pagefor='admin' />
              </PermissionRoute>
            }
          />

          <Route
            path='/admin/editsequencenote/:sequenceNoteId'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <AddSystemSequenceNotePage isEdit={true} pagefor='admin' />
              </PermissionRoute>
            }
          />
          <Route
            path='/admin/:clientId/sequence/:sequenceId/addsequencenote/:sequenceNoteId/duplicate'
            element={
              <PermissionRoute role={role} roles={["admin"]}>
                <AddSystemSequenceNotePage isEdit={false} pagefor='admin' />
              </PermissionRoute>
            }
          />

          {/* End of Admin route */}

          {/* Coach Routes */}
          <Route
            path='/coach/clients'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <ClientPage />
              </PermissionRoute>
            }
          />
          <Route
            path='/coach/clients/:clientId/notes'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <CoachTabs tabIndex={0}/>
              </PermissionRoute>
            }
          />
          <Route
            path='/coach/clients/:clientId/sequences'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <CoachTabs tabIndex={1}/>
              </PermissionRoute>
            }
          />
          <Route
            path='/coach/clients/:clientId/reminders'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <CoachTabs tabIndex={2}/>
              </PermissionRoute>
            }
          />
          <Route
            path='/coach/clients/:clientId/client_info'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <CoachTabs tabIndex={3}/>
              </PermissionRoute>
            }
          />
          <Route
            path='/coach/clients/:clientId/paige_ai'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <CoachTabs tabIndex={4}/>
              </PermissionRoute>
            }
          />
          <Route
            path='/coach/clients/:clientId/addnote'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <AddNotePage pagefor={"coach"} />
              </PermissionRoute>
            }
          />
          <Route
            path='/coach/clients/:clientId/editnote/:noteId'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <AddNotePage isEdit={true} pagefor={"coach"} />
              </PermissionRoute>
            }
          />

          <Route
            path='/coach/clients/:clientId/addlinknote'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <AddLinkNotePage pagefor={"coach"} />
              </PermissionRoute>
            }
          />

          <Route
            path='/coach/clients/:clientId/editlinknote/:noteId'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <AddLinkNotePage isEdit={true} pagefor={"coach"}/>
              </PermissionRoute>
            }
          />

          <Route
            path='/coach/clients/:clientId/addnote/:noteId/duplicate'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <AddNotePage isEdit={false} pagefor={"coach"} />
              </PermissionRoute>
            }
          />

          <Route
            path='/coach/clients/:clientId/addlinknote/:noteId/duplicate'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <AddLinkNotePage isEdit={false} pagefor={"coach"}/>
              </PermissionRoute>
            }
          />

          {/* Sequence routes */}
          <Route
            path='/coach/clients/:clientId/addsequence'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <AddSequencePage pagefor='coach' />
              </PermissionRoute>
            }
          />
          <Route
            path='/coach/clients/:clientId/editsequence/:noteId'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <AddSequencePage isEdit={true} pagefor='coach' />
              </PermissionRoute>
            }
          />

          <Route
            path='/coach/clients/:clientId/addsequence/:noteId/duplicate'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <AddSequencePage isEdit={false} pagefor='coach' />
              </PermissionRoute>
            }
          />
          <Route
            path='/coach/clients/:clientId/addsequencenote/:sequenceId'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <AddSequenceNotePage isEdit={false} pagefor='coach' />
              </PermissionRoute>
            }
          />

          <Route
            path='/coach/clients/editsequencenote/:sequenceNoteId'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <AddSequenceNotePage isEdit={true} pagefor='coach' />
              </PermissionRoute>
            }
          />
          <Route
            path='/coach/clients/:clientId/sequence/:sequenceId/addsequencenote/:sequenceNoteId/duplicate'
            element={
              <PermissionRoute role={role} roles={["coach"]}>
                <AddSequenceNotePage isEdit={false} pagefor='coach' />
              </PermissionRoute>
            }
          />
          {/* end of Sequence routes */}

          {/* End of Coach routes */}

          {/* Client Routes */}

          <Route
            path='/notes'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <ClientTabs  tabIndex={0}/>
              </PermissionRoute>
            }
          />
          <Route
            path='/sequences'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <ClientTabs tabIndex={1}/>
              </PermissionRoute>
            }
          />
          <Route
            path='/clients/addnote'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <AddNotePage pagefor='client' />
              </PermissionRoute>
            }
          />
          <Route
            path='/clients/editnote/:noteId'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <AddNotePage isEdit={true} pagefor={"client"} />
              </PermissionRoute>
            }
          />

          <Route
            path='/clients/addlinknote'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <AddLinkNotePage pagefor={"client"} />
              </PermissionRoute>
            }
          />

          <Route
            path='/clients/editlinknote/:noteId'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <AddLinkNotePage isEdit={true} pagefor={"client"}  />
              </PermissionRoute>
            }
          />

          <Route
            path='/clients/addnote/:noteId/duplicate'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <AddNotePage isEdit={false} pagefor={"client"} />
              </PermissionRoute>
            }
          />

          <Route
            path='/clients/addlinknote/:noteId/duplicate'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <AddLinkNotePage isEdit={false} pagefor={"client"} />
              </PermissionRoute>
            }
          />

          {/* Sequence routes */}
          <Route
            path='/clients/addsequence'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <AddSequencePage pagefor='client' />
              </PermissionRoute>
            }
          />
          <Route
            path='/clients/editsequence/:noteId'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <AddSequencePage isEdit={true} pagefor='client' />
              </PermissionRoute>
            }
          />

          <Route
            path='/clients/addsequence/:noteId/duplicate'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <AddSequencePage isEdit={false} pagefor='client' />
              </PermissionRoute>
            }
          />
          <Route
            path='/clients/addsequencenote/:sequenceId'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <AddSequenceNotePage isEdit={false} pagefor='client' />
              </PermissionRoute>
            }
          />

          <Route
            path='/clients/editsequencenote/:sequenceNoteId'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <AddSequenceNotePage isEdit={true} pagefor='client' />
              </PermissionRoute>
            }
          />

          <Route
            path='/clients/sequence/:sequenceId/addsequencenote/:sequenceNoteId/duplicate'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <AddSequenceNotePage isEdit={false} pagefor='client' />
              </PermissionRoute>
            }
          />

          <Route
            path='/reminders'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <ReminderPage pagefor={"client"} />
              </PermissionRoute>
            }
          />
          
          <Route
            path='/happiness/answers'
            element={
              <PermissionRoute role={role} roles={["client"]}>
                <HappinessAnswersPage role={role} />
              </PermissionRoute>
            }
          />

          {/* End of Client routes */}
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </RootLayout>
    </>
  );
}

export default App;
