import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Button, TextButton } from "@lib/Forms";
import { useTitle } from "@lib/Hooks";
import { privateAgent } from "@lib/Requests/AuthRequests";

import { Formik, Form, useFormikContext } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { routesName } from "@lib/RoutesName/Routes";
import AddNoteFields from "@components/formFields/Notes/AddNoteFields";
import NavigateBack from "@components/shared/NavigateBack";
import Editor from "@components/shared/Quill/Editor";
import { noteEditValue, noteInitialValue } from "./notes-helper";
import { AddNoteProps, NoteProps, NoteSteps } from "@lib/Types/NoteProps";
import Cookies from "js-cookie";
import { PAGE_FOR } from "@pages/pagefor";
import PlayIcon from "@assets/Play";
import ViewNotePage from "@pages/ViewNotePage";
import AddNoteValidationSchema from "@components/formFields/Notes/noteValidationSchema";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  // margin-bottom: 8px;
`;
const Header = styled(Typography)`
  font-family: Nunito;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #242424;
  display: flex;
  align-items: center;
`;

const TableContainer = styled.div``;
const NoteContainer = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  text-align: right;
`;
const PreviewDiv = styled.div`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  & p {
    color: #172882;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
const StyledEditor = styled(Editor)``;

interface AddNotePageProps {
  isEdit?: boolean;
  pagefor: PAGE_FOR;
}

function AddSystemNotePage(props: AddNotePageProps) {
  const { isEdit, pagefor } = props;
  useTitle("System Note | Paige");
  const [searchParams, setSearchParams] = useSearchParams()
  const noteType = searchParams.get('type')
  const formikProps = useFormikContext();

  const navigate = useNavigate();
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();
  const { noteId } = params;
  const [notes, setNotes] = useState<NoteProps>();
  const [fieldInitialValues, setFieldInitialValues] =
    useState(noteInitialValue);

  let { isLoading, data: editNotes } = useQuery(["singleNote"], async () => {
    const { data } = await privateAgent.get(
      routesName.SystemNoteRoute({ id: noteId }).get_single_note
    );

    if (data.status === 200) {
      const results = data.result;
      setFieldInitialValues(noteEditValue(results));
      return results;
    }
  });

  const handleSubmit = async (values: any) => {
    const modifiedStepNotes = values.noteSteps.map(
      (stepnote: any, index: number) => {
        return {
          text: values[`noteSteps${index}`]?.text ?? "",
        };
      }
    );
    values.noteSteps = modifiedStepNotes;


    if (isEdit) {
      const editApi = routesName.SystemNoteRoute({ id: noteId }).update_note
      const res = await privateAgent.put(editApi, values);
      if (res.status === 200) {
        enqueueSnackbar("Note edited successfully", { variant: "success" });
        navigate(-1);
      }
    } else {

      values.userId = Cookies.get("userId");
      // for duplicate or add
      values.noteType = noteType ? noteType : editNotes.noteType;
      values.link = "";

      const res = await privateAgent.post(routesName.SystemNoteRoute({}).add_note, values);
      if (res.status === 201) {
        enqueueSnackbar("Note added successfully", { variant: "success" });
        navigate(-1);
      }
    }
  };

  const previewNote = {
    note: {
      noteSteps: [
        {
          text: "<p>AAsdf asdf</p><p>asdfasdf</p><p><strong>Some bold text</strong></p><p><em>Italic</em> <u>underline </u><em><u>asdf</u></em></p><p><br></p><ul><li><em><u>test</u></em></li><li>some Text</li><li>some Text</li><li>some Text</li><li>some Text</li><li>some Text</li></ul>",
        },
        {
          text: "<p><br></p><p>asdfasdf</p><p><strong>Some bold tex…<p><br></p><ul><li><em><u>test</u></em></li></ul>",
        },
      ],
      userId: 1,
      noteType: "Regular",
      title: "Food Plan",
      notificationText: "",
      callToActionText: "Committed",
      link: "",
    },
  };
  const handlePreview = async (values: any) => {
    // setOpenPreviewDialog(true)

    const test = formikProps.values as AddNoteProps;
  };

  return (
    <>
      <NavigateBack text='Notes' />

      <Formik
        enableReinitialize={true}
        initialValues={fieldInitialValues}
        onSubmit={handleSubmit}
        validationSchema={AddNoteValidationSchema}
      >
        {({ isValid, setValues, values }) => (
          <StyledForm>
            <TopContainer>
              <Header>
                {isEdit ? "Edit System Note" : "Add System Note"}
                {/* <PreviewDiv onClick={handlePreview}>
                  <PlayIcon />
                  <p>Preview</p>
                </PreviewDiv> */}
              </Header>
              <ButtonContainer>
                <TextButton
                  label={"Cancel"}
                  onClick={() => navigate(-1)}
                ></TextButton>
                <Button label={"Save"} type={"submit"}></Button>
              </ButtonContainer>
            </TopContainer>
            <TableContainer>
              <NoteContainer>
                <ViewNotePage
                  // note={previewNote.note}
                  isOpen={openPreviewDialog}
                  setIsOpen={(setOpenPreviewDialog)}
                  onClose={() => {
                    setOpenPreviewDialog(false);
                  }}
                />
                <AddNoteFields pageFor="admin" />
              </NoteContainer>
            </TableContainer>
          </StyledForm>
        )}
      </Formik>


    </>
  );
}

export default AddSystemNotePage;
