import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useField } from "formik";

interface CheckBoxProps {
  name: string;
  placeholder: string;
  checked?: boolean;
  disabled?: boolean;
}

export default function CheckBox(props: CheckBoxProps) {
  let { name, placeholder, checked, disabled } = props;
  const [field, meta, helpers] = useField(props);
  let { touched, error, value } = meta;
  const { setValue, setTouched } = helpers;
  const isError = touched && error && true;
  if (typeof value !== "string" && !value) {
    value = "0";
  }
  const handleChange = (event: any) => {
    setValue(event.target.checked ? "1" : "0");
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          onChange={handleChange}
          checked={value === "1" ? true : false}
          disabled={disabled}
        />
      }
      label={placeholder}
    />
  );
}
