import React, { useEffect, useState } from "react";
import { Field, useField } from "formik";

// import { Editor  as TinyEditor} from '@tinymce/tinymce-react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
	DecoupledEditor,
  SourceEditing,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoImage,
	AutoLink,
	Autosave,
	BalloonToolbar,
	Base64UploadAdapter,
	BlockQuote,
	Bold,
	Code,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	Heading,
	Highlight,
	HorizontalLine,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	PageBreak,
	Paragraph,
	PasteFromOffice,
	RemoveFormat,
	SelectAll,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Subscript,
	Superscript,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	TodoList,
	Underline,
	Undo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import './CkEditor.css';


type EditorFieldProps = {
  formikProps: any;
  name: string;
  placeholder: string;
  readonly: boolean;
  [key: string]: any;
};

export default function Editor(props: EditorFieldProps) {
  const { name, placeholder, formikProps, readonly, ...rest } = props;
  const [field, meta] = useField(props);

  const [ isMounted, setMounted ] = useState( false );
  const [editorRef, setEditor] = useState<ClassicEditor>();

  useEffect( () => {
    setMounted( true );

    return () => {
        setMounted( false );
    };
}, [] );

  return (
    <div className='text-editor'>
      <Field name={field.name}>
        {({ field }: { field: any }) => (

      isMounted && <CKEditor
          // editor={ DecoupledEditor }
          editor={ ClassicEditor }
          data={field.value}
          config={ {
            toolbar: {
              items: [
                'undo',
                'redo',
                '|',
                'findAndReplace',
                'selectAll',
                '|',
                'heading',
                '|',
                'fontSize',
                'fontFamily',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'bold',
                'italic',
                'underline',
                'removeFormat',
                '|',
                'alignment',
                '|',
                'bulletedList',
                'numberedList',
                'todoList',
                'indent',
                'outdent',
              ],
              shouldNotGroupWhenFull: false
            },
            plugins: [
              SourceEditing,
              AccessibilityHelp,
              Alignment,
              Autoformat,
              AutoImage,
              AutoLink,
              Autosave,
              BalloonToolbar,
              Base64UploadAdapter,
              BlockQuote,
              Bold,
              Code,
              Essentials,
              FindAndReplace,
              FontBackgroundColor,
              FontColor,
              FontFamily,
              FontSize,
              Heading,
              Highlight,
              HorizontalLine,
              ImageBlock,
              ImageCaption,
              ImageInline,
              ImageInsert,
              ImageInsertViaUrl,
              ImageResize,
              ImageStyle,
              ImageTextAlternative,
              ImageToolbar,
              ImageUpload,
              Indent,
              IndentBlock,
              Italic,
              Link,
              LinkImage,
              List,
              ListProperties,
              PageBreak,
              Paragraph,
              PasteFromOffice,
              RemoveFormat,
              SelectAll,
              SpecialCharacters,
              SpecialCharactersArrows,
              SpecialCharactersCurrency,
              SpecialCharactersEssentials,
              SpecialCharactersLatin,
              SpecialCharactersMathematical,
              SpecialCharactersText,
              Strikethrough,
              Subscript,
              Superscript,
              Table,
              TableCaption,
              TableCellProperties,
              TableColumnResize,
              TableProperties,
              TableToolbar,
              TextTransformation,
              TodoList,
              Underline,
              Undo
            ],
            
            fontFamily: {
              supportAllValues: true
            },
            fontSize: {
              options: [10, 12, 14, 'default', 18, 20, 22, 24, 28, 30, 32, 34],
              supportAllValues: true
            },
            heading: {
              options: [
                {
                  model: 'paragraph',
                  title: 'Paragraph',
                  class: 'ck-heading_paragraph'
                },
                {
                  model: 'heading1',
                  view: 'h1',
                  title: 'Heading 1',
                  class: 'ck-heading_heading1'
                },
                {
                  model: 'heading2',
                  view: 'h2',
                  title: 'Heading 2',
                  class: 'ck-heading_heading2'
                },
                {
                  model: 'heading3',
                  view: 'h3',
                  title: 'Heading 3',
                  class: 'ck-heading_heading3'
                },
                {
                  model: 'heading4',
                  view: 'h4',
                  title: 'Heading 4',
                  class: 'ck-heading_heading4'
                },
                {
                  model: 'heading5',
                  view: 'h5',
                  title: 'Heading 5',
                  class: 'ck-heading_heading5'
                },
                {
                  model: 'heading6',
                  view: 'h6',
                  title: 'Heading 6',
                  class: 'ck-heading_heading6'
                }
              ]
            },
            image: {
              toolbar: [
                'toggleImageCaption',
                'imageTextAlternative',
                '|',
                'imageStyle:inline',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                '|',
                'resizeImage'
              ]
            },
            link: {
              addTargetToExternalLinks: true,
              defaultProtocol: 'https://',
              decorators: {
                toggleDownloadable: {
                  mode: 'manual',
                  label: 'Downloadable',
                  attributes: {
                    download: 'file'
                  }
                }
              }
            },
            list: {
              properties: {
                styles: true,
                startIndex: true,
                reversed: true
              }
            },
            // menuBar: {
            //   isVisible: true
            // },
            placeholder: 'Type or paste your content here!',
            table: {
              contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
            },
            //initialData: field.value,
          } }
          onChange={v => {
            formikProps.setFieldValue(field.name, editorRef!.getData())
          }}
          onBlur={() => formikProps.setFieldTouched(field.name, true)}
          onReady={editor => {
            setEditor(editor)
          }}
        />

          /*<TinyEditor
            apiKey='xkwl7dobxsnhk6o3u3zi50tvm7x0duhs65lwr30lc1ardgqz'
            value={field.value}
            onEditorChange={(value) => formikProps.setFieldValue(field.name, value)}
            onBlur={() => formikProps.setFieldTouched(field.name, true)}
            disabled={readonly}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
              ],
              toolbar: 'undo redo | blocks fontsize| ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}      />*/
        )}
      </Field>
    </div>
  );
}
