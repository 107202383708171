import React, { useState, useMemo } from "react";
import { type MRT_ColumnDef } from "material-react-table";
import {
  AddUserProps,
  privateAgent,
  Button,
  DialogBox,
  TextButton,
  AlertDialog,
  useTitle,
} from "../../../lib";
import AddUserFields from "../../../components/formFields/Users/AddUserFields";
import { Formik, Form } from "formik";
import {
  AddUserValidationSchema,
  EditUserValidationSchema,
} from "../../../components/formFields/Users/AddUserValidationSchema";
import {
  editUserIntitialValues,
  UserIntialFields,
} from "../../../components/formFields/Users/user-helpers";
import { useQuery, useQueryClient } from "react-query";
import { routesName } from "../../../lib/RoutesName/Routes";
import DropdownButton, { SelectMenu as DropdownSelectMenu } from "../../../components/shared/Dropdown";
import { Mode } from "../../../components/shared/dropdown/mode";
import { useSnackbar } from "notistack";
import UserSearchFields, {
  SearchParams,
  defaultSearchParams,
} from "../../../components/formFields/Users/UserSearchFields";
import { DataTable } from "../../../lib/Forms/DataTable";
import {
  ButtonContainer,
  TopContainerNoMargin,
  UsersContainer,
  TableContainer,
} from "src/styles/Containers";
import { TitleLarge } from "src/styles/Typography";
import RitualAnswers from "@pages/RitualAnswers/RitualAnswers";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

function UserPage() {
  useTitle("Users | Paige");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [searchQueries, setSearchQueries] =
    useState<SearchParams>(defaultSearchParams);
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userId, setUserId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [fieldInitialValues, setFieldInitialValues] =
    useState<AddUserProps>(UserIntialFields);
  const queryClient = useQueryClient();
  const [filteredData, setFilteredData] = useState([]);

  const [openRitualAnswerDialog, setRitualAnswerDialogState] = useState(false)

  let { isLoading, data } = useQuery(["UsersQuery"], async () => {
    const { data } = await privateAgent.get(routesName.UserRoute({}).get_users);
    if (data.status === 200) {
      const results = data.result.items;
      // setFilteredData(results);
      if (searchQueries) {
        handleSearchQuery(searchQueries);
      }

      setFilteredData(results);
      return results;
    }
  });

  const handleSubmit = async (values: AddUserProps) => {
    try {
      if (isEdit) {
        setLoading(true);
        const res = await privateAgent.put(
          routesName.UserRoute({ id: userId }).update_user,
          values
        );
        setLoading(false);
        if (res.data && res.data.status === 200) {
          queryClient.invalidateQueries("UsersQuery");
          setOpenAddUserDialog(false);
        }
      } else {
        setLoading(true);
        const res = await privateAgent.post(
          routesName.UserRoute({}).add_user,
          values
        );
        setLoading(false);
        if (res.status === 201) {
          queryClient.invalidateQueries("UsersQuery");
          setOpenAddUserDialog(false);
        }
      }
    } catch (error: any) {
      setLoading(false);
      console.log("error ", error.response.data.error);
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const res = await privateAgent.delete(
        routesName.UserRoute({ id: userId }).delete_user
      );
      setLoading(false);
      if (res.status === 200) {
        queryClient.invalidateQueries("UsersQuery");
        setOpenDeleteDialog(false);
        enqueueSnackbar("User deleted successfully", { variant: "success" });
      }
    } catch (error: any) {
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
      setLoading(false);
    }
  };

  const handleSearchQuery = (searchQuery: SearchParams) => {
    setSearchQueries(searchQuery);
    const filteredData = data?.filter((item: any) => {
      const hasActive = item?.isActive == searchQuery.active;
      const hasCoach = item?.coach?.coachId == searchQuery.coachId;
      const hasUserType = item?.userTypeId == searchQuery.userTypeId;
      const hasClass = item?.classes[0]?.id == searchQuery.classId;
      const hasUser =
        item?.userdetails?.firstName
          ?.toLowerCase()
          .indexOf(searchQuery.searchQuery.toLowerCase()) > -1 ||
        item?.userdetails?.lastName
          ?.toLowerCase()
          .indexOf(searchQuery.searchQuery.toLowerCase()) > -1;

      let toFiltered = true;
      if (searchQuery.searchQuery != "") {
        toFiltered = toFiltered && hasUser;
      }
      if (searchQuery.active != null && searchQuery.active?.toString() != "0") {
        toFiltered = toFiltered && hasActive;
      }
      if (searchQuery.classId != "0") {
        toFiltered = toFiltered && hasClass;
      }
      if (searchQuery.coachId != "0") {
        toFiltered = toFiltered && hasCoach;
      }

      if (searchQuery.userTypeId != "0") {
        toFiltered = toFiltered && hasUserType;
      }

      return toFiltered;
    });

    setFilteredData(filteredData);
  };

  const handleRowClick = (column: any, row: any) => {
    if (column.id === "name") {
      setOpenAddUserDialog(true);
      setFieldInitialValues(editUserIntitialValues(row.original));
      setUserId(row.original.id);
      setIsEdit(true);
    }
  };

  type Class = {
    id: number;
    name: string;
    isLast: boolean;
  };

  type User = {
    id: number;
    email: string;
    userTypeId: number;
    hasAdvancedSettings: boolean;
    isActive: boolean;
    termConditionConsents: boolean;
    isTrial: boolean;
    classes: Class[];
    userdetails: {
      id: number;
      userId: number;
      firstName: string;
      lastName: string;
    };
    role: {
      id: number;
      name: string;
    };
    coach: any;
  };

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorFn: (row) =>
          `${row?.userdetails?.firstName} ${row?.userdetails?.lastName}`,
        id: "name",
        header: "Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        header: "Class",
        id: "classes",
        accessorFn: (row) => `${row.classes[0]?.name ?? "-"}`,
        size: 100,
      },
      {
        id: "coach",
        header: "Coach",
        accessorFn: (row) =>
          `${row?.coach?.users?.userdetails?.firstName ?? ""} ${
            row?.coach?.users?.userdetails?.lastName ?? "-"
          }`,
        size: 100,
      },
      {
        id: "userType",
        header: "User Type",
        accessorFn: (row) => `${row?.role?.name ?? ""}`,
        size: 100,
      },
      {
        id: "advancedSettings",
        header: "Advanced User Settings",
        accessorFn: (row) => `${row?.hasAdvancedSettings ? "Yes" : "No"}`,
        size: 120,
      },
      {
        id: "isActive",
        header: "User Status",
        accessorFn: (row) => `${row?.isActive ? "Active" : "Inactive"}`,
        size: 100,
      },
      {
        id: "termConditionConsents",
        header: "Term & Condition Consent",
        accessorFn: (row) => `${row.termConditionConsents ? "Accepted" : "Not Accepted"}`,
        size: 100,
      },
      {
        id: "isTrial",
        header: "Trial User",
        accessorFn: (row) => `${row.isTrial ? "True" : "False"}`,
        size: 100,
      },
      {
        id: "actions",
        header: "Actions",
        headerClassName: "header-row",
        flex: 1,
        muiTableBodyCellProps: {
          align: "right",
          className: "action",
        },
        size: 50,
        enableResizing: false, //disable resizing for this column
        Cell: ({ cell }) => (
          <DropdownButton
            id={0}
            onItemClick={function (id: number, mode: Mode): void {
              if (mode == "edit") {
                console.log("cell ", cell);
                setOpenAddUserDialog(true);
                setFieldInitialValues(
                  editUserIntitialValues(cell.row.original)
                );
                setUserId(`${cell.row.original.id}`);
                setIsEdit(true);
              }
              if (mode === "delete") {
                setUserId(`${cell.row.original.id}`);
                setOpenDeleteDialog(true);
              }

              if (mode === "duplicate") {
                setOpenAddUserDialog(true);
                setFieldInitialValues(
                  editUserIntitialValues(cell.row.original)
                );
                setUserId(`${cell.row.original.id}`);
                setIsEdit(false);
              }
            }}
          >
            <DropdownSelectMenu onClick={() => {
              setFieldInitialValues(editUserIntitialValues(cell.row.original));
              setUserId(cell.row.original.id as any);
              setRitualAnswerDialogState(true)
            }}><QuestionAnswerIcon/> Ritual Answers</DropdownSelectMenu>
          </DropdownButton>
        ),
      },
    ],
    []
  );

  return (
    <>
    
    {
      openRitualAnswerDialog && userId ?
        <DialogBox
          isOpen={openRitualAnswerDialog}
          onClose={() => {
            setRitualAnswerDialogState(false)
          }}
          title={'Ritual Answers ('+fieldInitialValues.firstName +' '+fieldInitialValues.lastName+')'}
          className="ritual-answers-dialog"
          content={
            <RitualAnswers userId={+userId} userName={fieldInitialValues.firstName +' '+fieldInitialValues.lastName}/>
          }
        />
        : null
    }

      <AlertDialog
        variety='error'
        onConfirm={() => handleDelete()}
        onClose={() => setOpenDeleteDialog(false)}
        isOpen={openDeleteDialog}
        title={"Are you sure you want to delete this users"}
        content={
          "By deleting this user, their account and all associated data will be permanently erased. Do you want to proceed?"
        }
        btnText={{ cancel: "Cancel", action: "Delete" }}
      />
      <DialogBox
        isOpen={openAddUserDialog}
        onClose={() => {
          setOpenAddUserDialog(false);
          setLoading(false);
        }}
        title={isEdit ? "Edit User" : "Add User"}
        content={
          <Formik
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={fieldInitialValues as AddUserProps}
            onSubmit={handleSubmit}
            validationSchema={
              isEdit ? EditUserValidationSchema : AddUserValidationSchema
            }
          >
            {({ isValid, setValues, values }) => (
              <Form>
                <AddUserFields isEdit={isEdit} />
                <ButtonContainer>
                  <TextButton
                    label={"Cancel"}
                    onClick={() => {
                      setOpenAddUserDialog(false);
                      setLoading(false);
                    }}
                  ></TextButton>
                  <Button
                    loading={loading}
                    label={"Save"}
                    type={"submit"}
                    // disabled={isEdit? false: !isValid}
                  ></Button>
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        }
      />
      <TopContainerNoMargin>
        <TitleLarge>Users</TitleLarge>
        <Button
          label={"+ Add User"}
          onClick={() => {
            setOpenAddUserDialog(true);
            setIsEdit(false);
            setFieldInitialValues(UserIntialFields);
          }}
        ></Button>
      </TopContainerNoMargin>
      <UsersContainer>
        <UserSearchFields
          onSearchQuery={(searchQuery: SearchParams) =>
            handleSearchQuery(searchQuery)
          }
        />
        <TableContainer>
          {filteredData && filteredData.length > 0 && (
            <DataTable
              columns={columns}
              data={filteredData}
              onCellClick={handleRowClick}
              // state={{ isLoading: true }}

            />
          )}
        </TableContainer>
      </UsersContainer>
    </>
  );
}

export default UserPage;
