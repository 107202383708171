import SvgIcon from "@mui/material/SvgIcon";

function MeasurementsIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <g id='size_svgrepo.com'>
          <g id='turf-size'>
            <path
              id='Vector'
              d='M12.2635 4.85647C12.0869 4.85647 11.9435 4.71311 11.9435 4.53647V4.05647H11.4635C11.2869 4.05647 11.1435 3.91311 11.1435 3.73647C11.1435 3.55983 11.2869 3.41647 11.4635 3.41647H12.2635C12.4402 3.41647 12.5835 3.55983 12.5835 3.73647V4.53647C12.5835 4.71311 12.4403 4.85647 12.2635 4.85647Z'
              fill='white'
              stroke='white'
              strokeWidth='0.45'
            />
            <path
              id='Vector_2'
              d='M10.0781 4.05647H8.69265C8.51601 4.05647 8.37265 3.91311 8.37265 3.73647C8.37265 3.55983 8.51601 3.41647 8.69265 3.41647H10.0781C10.2547 3.41647 10.3981 3.55983 10.3981 3.73647C10.3981 3.91311 10.2549 4.05647 10.0781 4.05647Z'
              fill='white'
              stroke='white'
              strokeWidth='0.45'
            />
            <path
              id='Vector_3'
              d='M7.30722 4.05647H5.92178C5.74514 4.05647 5.60178 3.91311 5.60178 3.73647C5.60178 3.55983 5.74514 3.41647 5.92178 3.41647H7.30722C7.48386 3.41647 7.62722 3.55983 7.62722 3.73647C7.62722 3.91311 7.48402 4.05647 7.30722 4.05647Z'
              fill='white'
              stroke='white'
              strokeWidth='0.45'
            />
            <path
              id='Vector_4'
              d='M3.7365 4.85647C3.55986 4.85647 3.4165 4.71311 3.4165 4.53647V3.73647C3.4165 3.55983 3.55986 3.41647 3.7365 3.41647H4.5365C4.71314 3.41647 4.8565 3.55983 4.8565 3.73647C4.8565 3.91311 4.71314 4.05647 4.5365 4.05647H4.0565V4.53647C4.0565 4.71311 3.91314 4.85647 3.7365 4.85647Z'
              fill='white'
              stroke='white'
              strokeWidth='0.45'
            />
            <path
              id='Vector_5'
              d='M3.7365 10.3981C3.55986 10.3981 3.4165 10.2547 3.4165 10.0781V8.69263C3.4165 8.51599 3.55986 8.37263 3.7365 8.37263C3.91314 8.37263 4.0565 8.51599 4.0565 8.69263V10.0781C4.0565 10.2549 3.91314 10.3981 3.7365 10.3981Z'
              fill='white'
              stroke='white'
              strokeWidth='0.45'
            />
            <path
              id='Vector_6'
              d='M3.7365 7.6272C3.55986 7.6272 3.4165 7.48384 3.4165 7.3072V5.92176C3.4165 5.74512 3.55986 5.60176 3.7365 5.60176C3.91314 5.60176 4.0565 5.74512 4.0565 5.92176V7.3072C4.0565 7.484 3.91314 7.6272 3.7365 7.6272Z'
              fill='white'
              stroke='white'
              strokeWidth='0.45'
            />
            <path
              id='Vector_7'
              d='M4.5365 12.5835H3.7365C3.55986 12.5835 3.4165 12.4402 3.4165 12.2635V11.4635C3.4165 11.2869 3.55986 11.1435 3.7365 11.1435C3.91314 11.1435 4.0565 11.2869 4.0565 11.4635V11.9435H4.5365C4.71314 11.9435 4.8565 12.0869 4.8565 12.2635C4.8565 12.4402 4.71314 12.5835 4.5365 12.5835Z'
              fill='white'
              stroke='white'
              strokeWidth='0.45'
            />
            <path
              id='Vector_8'
              d='M10.0781 12.5835H8.69265C8.51601 12.5835 8.37265 12.4402 8.37265 12.2635C8.37265 12.0869 8.51601 11.9435 8.69265 11.9435H10.0781C10.2547 11.9435 10.3981 12.0869 10.3981 12.2635C10.3981 12.4402 10.2549 12.5835 10.0781 12.5835Z'
              fill='white'
              stroke='white'
              strokeWidth='0.45'
            />
            <path
              id='Vector_9'
              d='M7.30722 12.5835H5.92178C5.74514 12.5835 5.60178 12.4402 5.60178 12.2635C5.60178 12.0869 5.74514 11.9435 5.92178 11.9435H7.30722C7.48386 11.9435 7.62722 12.0869 7.62722 12.2635C7.62722 12.4402 7.48402 12.5835 7.30722 12.5835Z'
              fill='white'
              stroke='white'
              strokeWidth='0.45'
            />
            <path
              id='Vector_10'
              d='M12.2635 12.5835H11.4635C11.2869 12.5835 11.1435 12.4402 11.1435 12.2635C11.1435 12.0869 11.2869 11.9435 11.4635 11.9435H11.9435V11.4635C11.9435 11.2869 12.0869 11.1435 12.2635 11.1435C12.4402 11.1435 12.5835 11.2869 12.5835 11.4635V12.2635C12.5835 12.4403 12.4403 12.5835 12.2635 12.5835Z'
              fill='white'
              stroke='white'
              strokeWidth='0.45'
            />
            <path
              id='Vector_11'
              d='M12.2635 10.3981C12.0869 10.3981 11.9435 10.2547 11.9435 10.0781V8.69263C11.9435 8.51599 12.0869 8.37263 12.2635 8.37263C12.4402 8.37263 12.5835 8.51599 12.5835 8.69263V10.0781C12.5835 10.2549 12.4403 10.3981 12.2635 10.3981Z'
              fill='white'
              stroke='white'
              strokeWidth='0.45'
            />
            <path
              id='Vector_12'
              d='M12.2635 7.6272C12.0869 7.6272 11.9435 7.48384 11.9435 7.3072V5.92176C11.9435 5.74512 12.0869 5.60176 12.2635 5.60176C12.4402 5.60176 12.5835 5.74512 12.5835 5.92176V7.3072C12.5835 7.484 12.4403 7.6272 12.2635 7.6272Z'
              fill='white'
              stroke='white'
              strokeWidth='0.45'
            />
            <path
              id='Vector_13'
              d='M14.4346 14.7546H1.56545C1.38881 14.7546 1.24545 14.6112 1.24545 14.4346V1.56544C1.24545 1.3888 1.38881 1.24544 1.56545 1.24544H14.4346C14.6112 1.24544 14.7546 1.3888 14.7546 1.56544V14.4346C14.7546 14.6112 14.6112 14.7546 14.4346 14.7546ZM1.88545 14.1146H14.1146V1.88544H1.88545V14.1146Z'
              fill='white'
              stroke='white'
              strokeWidth='0.45'
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default MeasurementsIcon;
