import { useState, useMemo } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { AlertDialog, Button } from "@lib/Forms";
import { privateAgent } from "@lib/Requests/AuthRequests";
import { useTitle } from "@lib/Hooks";
import { routesName } from "@lib/RoutesName/Routes";
import { useNavigate, useParams } from "react-router-dom";
import { MRT_ColumnDef, MRT_Row } from "material-react-table";
import { BehaviorProps } from "@lib/Types/BehaviorProps";
import { DataTable } from "@lib/Forms/DataTable";
import { OnChangeFn } from "@tanstack/react-table";
import SequenceModeMenu from "@components/formFields/Sequence/SequenceModeMenu";
import { Mode } from "@components/shared/dropdown/mode";
import { SequenceProps } from "@lib/Types/SequenceProps";
import dayjs from "dayjs";
import { PAGE_FOR } from "@pages/pagefor";
import Cookies from "js-cookie";
import AddNoteToSequenceBtn from "./AddNoteToSequenceBtn";
import { SequenceNoteProps } from "@lib/Types/SequenceNoteProps";
import SystemSequenceModeMenu from "./SystemSequenceModeMenu";

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const Title = styled(Typography)`
  font-family: Nunito;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color: #242424;
`;

const UsersContainer = styled.div`
  padding: 16px;
  border-radius: 4px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px #0000000d;
`;

const TableContainer = styled.div``;

interface SequencePageProps {
  pagefor: PAGE_FOR;
}

function SequencePage({ pagefor }: SequencePageProps) {
  useTitle("Admin sequence | Paige");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const clientId =  Cookies.get("userId");
  const [filteredData, setFilteredData] = useState([]);
  const [sequenceId, setSequenceId] = useState("");
  const [deleteOption, setDeleteOption] = useState(""); //SEQUENCE , SEQUENCE_NOTE

  let { isLoading, data: clientFullName } = useQuery(
    ["Client"],
    async () => {
      const { data } = await privateAgent.get(
        routesName.UserRoute({ id: clientId }).get_single_user
      );

      if (data.status === 200) {
        const results = `${data.result.userdetails.firstName} ${data.result.userdetails.lastName}`;
        return results;
      }
    },
    { enabled: pagefor == "coach" }
  );

  let { data: systemSequences } = useQuery(["System_Sequences"], async () => {
    const clientid = pagefor == "admin" ? clientId : Cookies.get("userId");
    const { data } = await privateAgent.get(
      routesName.SystemSequenceRoute({ }).get_sequences
    );

    if (data.status === 200) {
      const results = data.result.items;
      console.log(results)

      const refinedResults = results.map((data: { systemSequenceNotes: any }) => {
        const sequenceNotes = data.systemSequenceNotes as SequenceNoteProps[];
        //ordering based on time
        sequenceNotes.sort((a: SequenceNoteProps, b: SequenceNoteProps) => {
          if (a.day < b.day) return -1;
          if (a.day > b.day) return 1;

          if (a.time < b.time) return -1;
          if (a.time > b.time) return 1;
          return 0;
        });

        return { ...data, ismain: true, subRows: sequenceNotes };
      });

      setFilteredData(refinedResults);
      return results;
    }
  });

  type Sequence = {
    id: number;
    title: string;
    day: string;
    time: string;
    isActive: boolean;
    behavior: BehaviorProps;
    ismain?: boolean;
    sequenceId?: number;
    type?: string
  };

  const columns = useMemo<MRT_ColumnDef<Sequence>[]>(
    () => [
      {
        accessorFn: (row) => `${row.title}`,
        id: "title",
        header: "Sequence",
      },
      {
        header: "Day",
        id: "day",
        accessorFn: (row) => `${row.day ?? ""}`,
        enableSorting: false,
        size: 100,
      },
      {
        header: "Time",
        id: "time",
        accessorFn: (row) =>
          `${
            !row?.ismain
              ? dayjs("2023-01-01 " + row.time).format("hh:mm A")
              : "" ?? ""
          }`,
        size: 100,
        enableSorting:false,
      },
      {
        header: "Behavior",
        id: "behavior",
        accessorFn: (row) => `${row?.behavior?.name ?? ""}`,
        size: 100,
      },
      {
        header: "Active",
        id: "isActive",
        accessorFn: (row) =>
          `${row?.ismain ? (row?.isActive ? "Yes" : "No") : ""}`,
        size: 100,
      },
      {
        header: "Type",
        id: "type",
        accessorFn: (row) =>
          `${row?.ismain ? row?.type :""}`,
        size: 100,
      },
      {
        id: "actions",
        header: "Actions",
        headerClassName: "header-row",
        flex: 1,
        muiTableBodyCellProps: {
          align: "right",
          className: "action",
        },
        size: 50,
        enableResizing: false, //disable resizing for this column
        Cell: ({ cell }) => (
          <SystemSequenceModeMenu
            isSubRows={cell.row.depth == 1}
            id={0}
            onItemClick={function (id: number, mode: Mode): void {
              if (mode == "edit") {
                if (cell.row.original.ismain) {
                  if (pagefor == "admin") {
                    navigate(
                      `/admin/${clientId}/editsequence/${cell.row.original.id}`
                    );
                  }
                } else {
                  if (pagefor == "admin") {
                    navigate(
                      `/admin/editsequencenote/${cell.row.original.id}`
                    );
                  }
                }
              }

              if (mode == "duplicate") {
                if (cell.row.original.ismain) {
                  if (pagefor == "admin") {
                    navigate(
                      `/admin/${clientId}/addsequence/${cell.row.original.id}/duplicate`
                    );
                  }
                } else {
                  if (pagefor == "admin") {
                    navigate(
                      `/admin/${clientId}/sequence/${cell.row.original.sequenceId}/addsequencenote/${cell.row.original.id}/duplicate`
                    );
                  }
                }
              }

              if (mode == "add_sequence_note") {
                if (pagefor == "admin") {
                  navigate(
                    `/admin/${clientId}/addsequencenote/${cell.row.original.id}`,
                    {
                      state: {
                        title: cell.row.original.title,
                      },
                    }
                  );
                }
              }

              if (mode === "delete") {
                setSequenceId(`${cell.row.original.id}`);
                if (cell.row.original.ismain) {
                  setDeleteOption("SEQUENCE");
                } else {
                  setDeleteOption("SEQUENCE_NOTE");
                }

                setOpenDeleteDialog(true);
              }
            } } type={""} noteType={""}          />
        ),
      },
    ],
    []
  );

  const handleDelete = async () => {
    try {
      if (deleteOption == "SEQUENCE") {
        const res = await privateAgent.delete(
          routesName.SystemSequenceRoute({ id: sequenceId }).delete_sequence
        );
        setOpenDeleteDialog(false);
        if (res.status === 200) {
          queryClient.invalidateQueries("System_Sequences");
          enqueueSnackbar("Sequence deleted successfully", {
            variant: "success",
          });
        }
      }

      if (deleteOption == "SEQUENCE_NOTE") {
        const res = await privateAgent.delete(
          routesName.SystemSequenceNoteRoute({ id: sequenceId }).delete_sequencenotes
        );
        setOpenDeleteDialog(false);
        if (res.status === 200) {
          queryClient.invalidateQueries("System_Sequences");
          enqueueSnackbar("Sequence Note deleted successfully", {
            variant: "success",
          });
        }
      }
    } catch (error: any) {
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
      setOpenDeleteDialog(false);
    }
  };

  const handleRowClick = (column: any, row: any) => {
    if (column.id !== "title") return;
    if (row.original.ismain) {
      if (pagefor == "admin") {
        navigate(`/admin/${clientId}/editsequence/${row.original.id}`);
      }
    } else {
      if (pagefor == "admin") {
        navigate(`/admin/editsequencenote/${row.original.id}`);
      }
    }
  };

  return (
    <>
      <AlertDialog
        variety='error'
        onConfirm={() => handleDelete()}
        onClose={() => setOpenDeleteDialog(false)}
        isOpen={openDeleteDialog}
        title={`Are you sure you want to delete this ${deleteOption == "SEQUENCE" ? "sequence" : "note"}`}
        content={
          `Deleting this ${deleteOption == "SEQUENCE" ? "sequence" : "note"} will permanently erase any data associated with this ${deleteOption == "SEQUENCE" ? "sequence" : "note"}.`
        }
        btnText={{ cancel: "Cancel", action: "Delete" }}
      />

      <TopContainer>
        <Title>
          {/* {pagefor == "admin" ? `${clientFullName}'s Sequence` : ""} */}
        </Title>
        <RowContainer>
          { (
            <AddNoteToSequenceBtn
              pagefor={pagefor}
              clientId={clientId}
              onItemClick={(sequence: SequenceProps) => {
                console.log("sequence ", sequence);
                if (pagefor == "admin") {
                  navigate(
                    `/admin/${clientId}/addsequencenote/${sequence.id}`, {
                      state: {
                        title: sequence.title
                      },
                    }
                  );
                }
              }}
            />
          )}
          <Button
            label={"+ Add Sequence"}
            onClick={() => {
              if (pagefor == "admin") {
                navigate(`/admin/${clientId}/addsequence`);
              }
            }}
          />
        </RowContainer>
      </TopContainer>
      <UsersContainer>
        <TableContainer>
          {filteredData && filteredData.length > 0 && (
            <DataTable
              columns={columns}
              data={filteredData}
              enableRowOrdering={true}
              enableExpanding={true}
              enableSorting={true}
              onCellClick={handleRowClick}
              initialState={{
                sorting: [
                  {
                    id: "day",
                    desc: false,
                  },
                  {
                    id: "time",
                    desc: false,
                  },
                ],
              }}
              enableMultiRemove={false}
              enableSortingRemoval={false}
              isMultiSortEvent={() => true}
              multiSortEvent={() => true}
              muiTableBodyRowDragHandleProps={({ table }: any) => ({
                onDragEnd: async() => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    console.log("drag pos",(hoveredRow as MRT_Row<any>).index)
                    console.log("draggging  index",draggingRow.index)
                    filteredData.splice(
                      (hoveredRow as MRT_Row<any>).index,
                      0,
                      filteredData.splice(draggingRow.index, 1)[0]
                    );

                    
                    const Order = filteredData.map((data: SequenceNoteProps, index: number)=>{
                      return{
                        id: data.id,
                        ordering: index
                      }
                    })
                    
                    const order = await privateAgent.post(routesName.SystemSequenceRoute({}).sequences_reorder, {sequences: Order})
                    queryClient.invalidateQueries("System_Sequences");

                  }
                },
              })}
            />
          )}
        </TableContainer>
      </UsersContainer>
    </>
  );
}

export default SequencePage;
