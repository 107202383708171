import SvgIcon from "@mui/material/SvgIcon";

function ErrorIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 20 20'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          d='M10 13.3333H10.0083M10 6.66667V10M10 17.5C14.1422 17.5 17.5 14.1422 17.5 10C17.5 5.85787 14.1422 2.5 10 2.5C5.85787 2.5 2.5 5.85787 2.5 10C2.5 14.1422 5.85787 17.5 10 17.5Z'
          stroke='#DD4343'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
}

export default ErrorIcon;
