import React, { useState, useMemo } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { AlertDialog } from "@lib/Forms";
import { useAddButtonOptions, useSystemAddButtonOptions, useTitle } from "@lib/Hooks";
import { privateAgent } from "@lib/Requests/AuthRequests";
import { useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { routesName } from "@lib/RoutesName/Routes";
import AddNoteBtn from "@components/formFields/Notes/AddNoteBtn";
import { useNavigate } from "react-router-dom";
import { MRT_ColumnDef, MRT_Row } from "material-react-table";
import { DataTable } from "@lib/Forms/DataTable";
import NoteModeMenu from "@components/formFields/Notes/NoteModeMenu";
import { BehaviorProps } from "@lib/Types/BehaviorProps";
import { CallsToActionProps } from "@lib/Types/CallsToActionProps";
import { Mode } from "@components/shared/dropdown/mode";
import { OnChangeFn } from "@tanstack/react-table";
import { PAGE_FOR } from "@pages/pagefor";
import Cookies from "js-cookie";
import { NoteProps } from "@lib/Types";
import { Tag } from "@components/styles/general.styled";
import AdminNoteModeMenu from "@components/formFields/Notes/AdminNoteModeMenu";

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const Title = styled(Typography)`
  font-family: Nunito;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color: #242424;
`;

const UsersContainer = styled.div`
  padding: 16px;
  border-radius: 4px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px #0000000;
`;

const TableContainer = styled.div``;

interface NotePageProps {
  pagefor: PAGE_FOR;
}

function NotePage({ pagefor }: NotePageProps) {
  useTitle("Admin System Note | Paige");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const clientId  = Cookies.get("userId") 
  const queryClient = useQueryClient();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [noteId, setNoteId] = useState("");
  const addBtnOptions = useAddButtonOptions()
  const addSystemNoteBtnOptions = useSystemAddButtonOptions()

  const [filteredData, setFilteredData] = useState([]);
  
  let { data: systemNotes } = useQuery(["System_Notes"], async () => {
    const { data } = await privateAgent.get(
      routesName.SystemNoteRoute({  }).get_notes
    );

    if (data.status === 200) {
      const results = data.result.items;
      setFilteredData(results);
      return results;
    }
  }, { enabled: pagefor == "admin" });

  type Note = {
    id: number;
    title: string;
    noteType: string;
    behavior: BehaviorProps;
    calltoAction: CallsToActionProps;
  };

  function capitalizeFirstLetter(val: string) {
    let updatedText = val;
    if (val.toLowerCase().indexOf("system") == 0) {
      updatedText = val.slice(val.lastIndexOf("_") + 1);
    }
    return (
      updatedText.charAt(0).toUpperCase() + updatedText.slice(1).toLowerCase()
    );
  }

  const columns = useMemo<MRT_ColumnDef<Note>[]>(
    () => [
      {
        accessorFn: (row) => `${row.title}`,
        id: "title",
        header: "Note",
        minSize: 250,
        Cell: ({ cell }) => (
          <div>
            {cell.row.original.title}
              <Tag>System</Tag>
          </div>
        ),
      },
      {
        accessorFn: (row) => `${capitalizeFirstLetter(row.noteType)}`,
        accessorKey: "noteType",
        header: "Note Type",
      },
      {
        header: "Behavior",
        id: "behavior",
        accessorFn: (row) => `${row?.behavior?.name ?? "-"}`,
        size: 100,
      },
      {
        header: "Call to Action",
        id: "calltoAction",
        accessorFn: (row) => `${row?.calltoAction?.name ?? "-"}`,
        size: 100,
      },
      {
        id: "actions",
        header: "Actions",
        headerClassName: "header-row",
        flex: 1,
        muiTableBodyCellProps: {
          align: "right",
          className: "action",
        },
        size: 50,
        enableResizing: false, //disable resizing for this column
        Cell: ({ cell }) => (
        <AdminNoteModeMenu
            id={0}
            noteType={cell.row.original.noteType}
            onItemClick={function (id: number, mode: Mode): void {
              const note = cell.row.original as unknown as NoteProps
              if (mode == "edit") {
                if (note.noteType == "REGULAR") {
                  navigate(`/system/editnote/${note.id}`);
                }
                if (note.noteType === "LINK") {
                  navigate(`/system/editlinknote/${note.id}`);
                }
              }

              if (mode == "duplicate") {
                if (note.noteType == "REGULAR") {
                    navigate(`/system/addnote/${note.id}/duplicate`);   
                }
                if (note.noteType == "LINK") {
                    navigate(`/system/addlinknote/${note.id}/duplicate`);
                }
              }

              if (mode === "delete") {
                setNoteId(`${cell.row.original.id}`);
                setOpenDeleteDialog(true);
              }
            }}
          />
        ),
      },
    ],
    []
  );

  const handleDelete = async () => {
    try {
      const res = await privateAgent.delete(
        routesName.SystemNoteRoute({ id: noteId }).delete_note
      );
      setOpenDeleteDialog(false);
      if (res.status === 200) {
        queryClient.invalidateQueries("System_Notes");
        enqueueSnackbar("Note deleted successfully", { variant: "success" });
      }
    } catch (error: any) {
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
      setOpenDeleteDialog(false);
    }
  };

  const handleRowClick = (column: any, row: any) => {
    if (column.id === "title") {
      const note = row.original as NoteProps
      if (note.noteType == "LINK") {
          navigate(`/system/editlinknote/${note.id}`);
      }else{
        navigate(`/system/editnote/${note.id}`);
      }
    }
  };

  return (
    <>
      <AlertDialog
        variety='error'
        onConfirm={() => handleDelete()}
        onClose={() => setOpenDeleteDialog(false)}
        isOpen={openDeleteDialog}
        title={"Are you sure you want to delete this note"}
        content={
          "Deleting this note will permanently erase any data associated with this note."
        }
        btnText={{ cancel: "Cancel", action: "Delete" }}
      />

      <TopContainer>
        <Title>
         {""}
        </Title>
        <AddNoteBtn
          options={  addSystemNoteBtnOptions }
          onItemClick={function (mode: Mode): void {
            if (mode === "system-note") {
              navigate(`/system/addnote?type=REGULAR`);
            }
            if (mode === "system-link") {
              navigate(`/system/addlinknote?type=LINK`);
            }
            if (mode === "system-ritual-note") {
              navigate(`/system/addlinknote?type=RITUAL`);
            }
            if (mode === "system-record-stats") {
              navigate(`/system/addlinknote?type=RECORD_STATS`);
            }
            if (mode === "system-record-weight") {
              navigate(`/system/addlinknote?type=RECORD_WEIGHTS`);
            }
          }}
        />
      </TopContainer>
      <UsersContainer>
        <TableContainer>
          {filteredData && filteredData.length > 0 && (
            <DataTable
              columns={columns}
              data={filteredData}
              onCellClick={handleRowClick}
              enableRowOrdering={true}
              enableSorting={true}
              muiTableBodyRowDragHandleProps={({ table }: any) => ({
                onDragEnd: async () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    filteredData.splice(
                      (hoveredRow as MRT_Row<any>).index,
                      0,
                      filteredData.splice(draggingRow.index, 1)[0]
                    );

                    const Order = filteredData.map(
                      (data: NoteProps, index: number) => {
                        return {
                          id: data.id,
                          ordering: index,
                        };
                      }
                    );

                    const order = await privateAgent.post(
                      routesName.SystemNoteRoute({}).notes_reorder,
                      { notes: Order }
                    );
                    queryClient.invalidateQueries("System_Notes");
                  }
                },
              })}
            />
          )}
        </TableContainer>
      </UsersContainer>
    </>
  );
}

export default NotePage;
