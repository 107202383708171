import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import {
  Button,
  TextButton,
} from "@lib/Forms";
import { useTitle } from "@lib/Hooks";
import { privateAgent } from "@lib/Requests/AuthRequests";

import { Formik, Form } from "formik";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { routesName } from "@lib/RoutesName/Routes";

import NavigateBack from "@components/shared/NavigateBack";
import Editor from "@components/shared/Quill/Editor";

import AddSequenceFields from "@components/formFields/Sequence/AddSequenceFields";
import { sequenceInitialValue, sequenceEditValue } from "./sequence-helper";
import { PAGE_FOR } from "@pages/pagefor";
import Cookies from "js-cookie";
import { AddSequenceValidationSchema } from "@components/formFields/Sequence/sequenceValidationSchema";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
`;
const Header = styled(Typography)`
  font-family: Nunito;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #242424;
`;

const TableContainer = styled.div``;
const NoteContainer = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  text-align: right;
`;
const EditorContainer = styled.div`
  display: flex;
  // padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  & .text-editor {
    width: 40%;
  }
  & .ql-editor {
    min-height: 100px;
    padding: 12px 16px;
  }
`;
const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-family: Nunito;
  & span:first-of-type {
    color: #242424;
    font-size: 20px;
    font-weight: 700;
  }
  & span:last-of-type {
    cursor: pointer;
    color: #dd4343;
    font-size: 16px;
    font-weight: 700;
  }
`;
const AddStep = styled.div`
  color: #172882;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const StyledEditor = styled(Editor)``;

interface AddSequencePageProps {
  isEdit?: boolean;
  pagefor: PAGE_FOR
}

function AddSystemSequencePage(props: AddSequencePageProps) {
  const { isEdit, pagefor } = props;
  useTitle("System Note | Paige");
  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();
  const { clientId, noteId } = params;
  const [fieldInitialValues, setFieldInitialValues] =
    useState(sequenceInitialValue);

  let { isLoading, data: editNotes } = useQuery(["singleSequence"], async () => {
    const { data } = await privateAgent.get(
      routesName.SystemSequenceRoute({ id: noteId }).get_single_sequence
    );

    if (data.status === 200) {
      const results = data.result;
      setFieldInitialValues(sequenceEditValue(results));
      return results;
    }
  },{
    enabled: !!noteId
  });

  const handleSubmit = async (values: any) => {
    values.userId = pagefor == 'admin' ? clientId : Cookies.get('userId')

    if (isEdit) {
      const res = await privateAgent.put(
        routesName.SystemSequenceRoute({ id: noteId }).update_sequence,
        values
      );
      if (res.status === 200) {
        enqueueSnackbar("Sequence edited successfully", { variant: "success" });
        navigate(-1);
      }
    } else {
      const res = await privateAgent.post(
        routesName.SystemSequenceRoute({}).add_sequence,
        values
      );
      if (res.status === 201) {
        enqueueSnackbar("Sequence added successfully", { variant: "success" });
        navigate(-1);
      }
    }
  };

  return (
    <>
      <NavigateBack text='Sequences' />

      <Formik
        enableReinitialize={true}
        initialValues={fieldInitialValues}
        onSubmit={handleSubmit}
        validationSchema={
          AddSequenceValidationSchema
        }
      >
        {({ isValid, setValues, values }) => (
          <StyledForm>
            <TopContainer>
              <Header>
                {isEdit ? "Edit Sequence" : "Add Sequence"}
              </Header>
              <ButtonContainer>
                <TextButton
                  label={"Cancel"}
                  onClick={() => navigate(-1)}
                ></TextButton>
                <Button label={"Save"} type={"submit"}></Button>
              </ButtonContainer>
            </TopContainer>
            <TableContainer>
              <NoteContainer>
                <AddSequenceFields />
              </NoteContainer>
            </TableContainer>
          </StyledForm>
        )}
      </Formik>
    </>
  );
}

export default AddSystemSequencePage;
