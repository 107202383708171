import styled from "@emotion/styled";
import SvgIcon from "@mui/material/SvgIcon";

const StyledSVG = styled(SvgIcon)`
  width: unset !important;
  height: unset !important;
  // // max-width: 73.5% !important;
  // // max-height: 50% !important;
  // position: absolute;
`;
function TitleBackgroundIcon(props: any) {
  return (
    <StyledSVG {...props} viewBox='0 0 390 134'>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 390 134' fill='none'>
        <path
          d='M0 0H390V116.476C261.278 138.663 128.741 138.552 0 116.476V0Z'
          fill='#F1F3FF'
        />
      </svg>
    </StyledSVG>
  );
}

export default TitleBackgroundIcon;
