

import React from 'react'
import NoPermissionPage from "../pages/NotFound/NoPermissionPage";

export type PagePermission = 'any' | 'admin' | 'client' | 'coach' | ''

interface RoleRouteProps{
  role: PagePermission
  roles: PagePermission[]
  children: JSX.Element
}


export default function PermissionRoute({role, roles = [], children }:RoleRouteProps):JSX.Element{

  if(!role)
    return <></>

  if( !roles.includes(role)){
    return <NoPermissionPage/> 
  }
  
  return  children
  
  };
