import PropTypes from "prop-types";
import { useField } from "formik";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styled from "@emotion/styled";

const StyledFormHelperText = styled(FormHelperText)`
  color: #d32f2f;
`;

const SelectMenu = styled(MenuItem)`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  color: #172882;
`;
const SelectFormControl = styled(FormControl)`
  margin: 0;
  height: 40px;
  & .MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
    padding: 8.5px 14px;
    transform: unset;
  }
  & .Mui-error {
    text-align: right;
    margin-top: -3px;
    margin-right: 0;
  }
  // & .MuiOutlinedInput-notchedOutline {
  //   padding: 8.5px 14px;
  // }
  & .MuiInputBase-input {
    padding: 8.5px 14px;
    font-weight:600;
  }
`;

export interface SelectOptions {
  Name: string;
  value: string;
}

type SelectFieldProps = {
  name: string;
  placeholder: string;
  isClearable?: boolean;
  hideLabel?: boolean;
  options: any[];
  disabled?: boolean;
  onChange?: (option: any) => void;

  [key: string]: any;
};

function SelectField(props: SelectFieldProps) {
  let { label, options, placeholder, name, ...rest } = props;
  const [field, meta, helpers] = useField(props);
  let { touched, error, value } = meta;
  const { setValue, setTouched } = helpers;
  const isError = touched && error && true;

  // console.log("SeleectFild error ", isError)

  // if (typeof value !== "string" && !value && value && !value.Id) {
  //   value = null;
  // }

  const handleChange = (event: SelectChangeEvent) => {
    if (props.onChange) {
      props.onChange(event);
    } else {
      setValue(event.target.value);
    }
  };

  function _renderHelperText() {
    if (isError) {
      return <StyledFormHelperText>{error}</StyledFormHelperText>;
    }
  }

  function hasError() {
    if (isError) return true;
    return false;
  }

  return (
    <>
      <SelectFormControl
        sx={{ m: 1, width: "100%" }}
        error={isError ? true : false}
      >
        <InputLabel id='demo-simple-select-helper-label'>
          {placeholder}
        </InputLabel>
        <Select
          labelId='demo-simple-select-helper-label'
          id='demo-simple-select-helper'
          value={value}
          label={placeholder}
          name={field.name}
          onChange={handleChange}
          {...rest}
        >
          {/* {
            options && options?.length < 2 && <MenuItem value={"Does not repeat"}>Does not repeat</MenuItem>
          } */}
           
          {options.length >= 1 && options?.map((option) => {
            return (
              <SelectMenu key={option.value} value={option.value}>
                {option.Name}
              </SelectMenu>
            );
          })}
        </Select>
        {_renderHelperText()}
      </SelectFormControl>
    </>
  );
}

SelectField.defaultProps = {
  data: [],
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SelectField;
