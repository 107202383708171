import React, { useEffect, useState } from "react";
import { useDebounce } from "../../../lib";
import NonFormikSelectField from "../../../lib/Forms/NonFormikSelectField";
import useClassesOptions from "../../../lib/Hooks/options/useClassesOption";
import useCoachesOptions from "../../../lib/Hooks/options/useCoachesOption";
import useUserRoleOptions from "../../../lib/Hooks/options/useUserRoleOptions";
import { SelectChangeEvent } from "@mui/material/Select";
import useActiveOptions from "../../../lib/Hooks/options/useActiveOption";
import { SelectFieldContainer, SearchContainer } from "src/styles/Containers";
import { StyledInputField } from "src/styles/Inputs";

interface SearchFieldsProps {
  onSearchQuery: (searchTerm: SearchParams) => void;
}

export interface SearchParams {
  searchQuery: string;
  classId: string;
  userTypeId: string;
  coachId: string;
  active: string | null;
}

export const defaultSearchParams: SearchParams = {
  searchQuery: "",
  classId: "0",
  userTypeId: "0",
  coachId: "0",
  active: null,
};

function UserSearchFields({ onSearchQuery }: SearchFieldsProps) {
  const activeOptions = useActiveOptions();
  const classOptions = useClassesOptions();
  const coachOptions = useCoachesOptions();
  const userTypeOptions = useUserRoleOptions();

  const [searchQuery, setSearchQuery] = useState("");
  const debounceSearchQuery = useDebounce(searchQuery, 500);

  const [searchTerm, setSearchTerm] =
    useState<SearchParams>(defaultSearchParams);

  useEffect(() => {
    setSearchTerm((prevState) => ({
      ...prevState,
      searchQuery: searchQuery,
    }));

    if (onSearchQuery) {
      onSearchQuery({ ...searchTerm, searchQuery: searchQuery });
    }
  }, [debounceSearchQuery]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleActiveChange = (event: SelectChangeEvent) => {
    setSearchTerm((prevState) => ({
      ...prevState,
      active: event.target.value,
    }));

    if (onSearchQuery) {
      onSearchQuery({ ...searchTerm, active: event.target.value });
    }
  };
  const handleClassChange = (event: SelectChangeEvent) => {
    setSearchTerm((prevState) => ({
      ...prevState,
      classId: event.target.value,
    }));

    if (onSearchQuery) {
      onSearchQuery({ ...searchTerm, classId: event.target.value });
    }
  };
  const handleCoachChange = (event: SelectChangeEvent) => {
    setSearchTerm((prevState) => ({
      ...prevState,
      coachId: event.target.value,
    }));

    if (onSearchQuery) {
      onSearchQuery({ ...searchTerm, coachId: event.target.value });
    }
  };
  const handleUserTypeChange = (event: SelectChangeEvent) => {
    setSearchTerm((prevState) => ({
      ...prevState,
      userTypeId: event.target.value,
    }));

    if (onSearchQuery) {
      onSearchQuery({ ...searchTerm, userTypeId: event.target.value });
    }
  };

  return (
    <SearchContainer>
      <StyledInputField label={"Search"} onChange={handleSearchChange} />
      <SelectFieldContainer>
        <NonFormikSelectField
          name={"active"}
          placeholder={"Active*"}
          options={activeOptions}
          onChange={handleActiveChange}
        />
        <NonFormikSelectField
          name={"class"}
          placeholder={"Class*"}
          options={classOptions}
          onChange={handleClassChange}
        />
        <NonFormikSelectField
          name={"coach"}
          placeholder={"Coach*"}
          options={coachOptions}
          onChange={handleCoachChange}
        />
        <NonFormikSelectField
          name={"usertype"}
          placeholder={"User Type*"}
          options={userTypeOptions}
          onChange={handleUserTypeChange}
        />
      </SelectFieldContainer>
    </SearchContainer>
  );
}

export default UserSearchFields;
