import React, { useEffect, useState } from "react";
import {
  AddUserProps,
  CheckBox,
  InputField,
  SelectField,
  TimeField,
  ToggleButtonField,
} from "../../../../lib";
import styled from "@emotion/styled";
import { useFormikContext } from "formik";
import useReminderTypeOptions from "../../../../lib/Hooks/options/useReminderTypeOption";
import DateField from "../../../../lib/Forms/DateField";
import useNotesOptions from "../../../../lib/Hooks/options/useNotesOption";
import { useNavigation, useParams } from "react-router-dom";
import useSequencesOptions from "../../../../lib/Hooks/options/useSequencesOption";
import { AddSequenceProps, SequenceProps } from "../../../../lib/Types/SequenceProps";
import { AddReminderProps } from "../../../../lib/Types/ReminderProps";
import useFrequencyOptions from "../../../../lib/Hooks/options/useFrequencyOptions";
import useSystemNotesOption from "@lib/Hooks/options/useSystemNotesOption";
import useSystemSequencesOption from "@lib/Hooks/options/useSystemSequencesOption";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  // gap: 30px;
  gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  text-align: right;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  & .MuiToggleButtonGroup-root {
    height: 40px;
  }
`;

interface WidthProps {
  width?: string;
}

const WidthContainer = styled.div<WidthProps>`
  width: ${(props) => (props.width ? props.width : "100%")};
`;

interface AddReminderFieldsProps {
  isEdit?: boolean;
  clientId?: string;
}

function AddSystemReminderFields({ isEdit, clientId }: AddReminderFieldsProps) {
  const formikProps = useFormikContext();
  const reminderTypeOptions = useReminderTypeOptions();
  const [focus, setFocus] = React.useState(true);
  const params = useParams();
  const notesOptions = useSystemNotesOption(clientId);
  const sequenceOptions = useSystemSequencesOption(clientId);
  const repeatOptions = useFrequencyOptions();


  const [noteSequenceOptions, setNoteSequenceOptions] = useState(notesOptions);
  const [checkDuplicate, setCheckDuplicate] = useState<boolean>();
  const [type, setType] = useState<string>('Note');
  const [ frequency, setFrequency ] = useState(repeatOptions)

  useEffect(() => {
    const noteid = (formikProps.values as SequenceProps).noteId;
    const sequeceType = (formikProps.values as SequenceProps).reminderType;
    if (noteid && sequeceType=="SEQUENCE") {
      const selectedItem = (noteSequenceOptions as unknown[])?.find((item: any) => item.value === noteid) as selectednote | undefined;
      //console.log(selectedItem)
      if (selectedItem) {
        const hasDuplicate = selectedItem.hasDuplicate;
        if (hasDuplicate) {
          setCheckDuplicate(true);
          setFrequency([{ Enabled: 1, Name: "Does not repeat", value : "Does not repeat"}]);
        } else {
          setCheckDuplicate(false);
          setFrequency(repeatOptions);
        }
      } else {
        setCheckDuplicate(false)
      }
    }else{
      setFrequency(repeatOptions);
    }
  },[(formikProps.values as SequenceProps).noteId, (formikProps.values as SequenceProps).reminderType])

  type selectednote = {
    Name?: string;
    Enabled?: number;
    value?: number;
    hasDuplicate: boolean;
  };


  useEffect(() => {
    const reminderType = (formikProps.values as AddReminderProps).reminderType;
    if (reminderType == "NOTE") {
      setNoteSequenceOptions(notesOptions);
    }
    if (reminderType == "SEQUENCE") {
      setNoteSequenceOptions(sequenceOptions);
    }
  }, [
    (formikProps.values as AddReminderProps).reminderType,
    notesOptions,
    sequenceOptions,
  ]);

  const handleToggleChange = (options: any) => {
    //console.log("options ", options);
    if (options == "SEQUENCE") {
      setNoteSequenceOptions(sequenceOptions);
      setType('Sequence');
    } else {
      setNoteSequenceOptions(notesOptions);
      setType('Note');
    }
  };

  useEffect(() => {
    formikProps.setTouched({});
    setFocus(false);
  }, []);

  return (
    <FieldContainer>
      <InputField
        placeholder={"Reminder Title"}
        name={"title"}
        // ref={textFieldRef}
        inputRef={(input: any) => (focus ? input && input.focus() : null)}
        autoFocus
        onFocus={(e: any) => {
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          );
        }}
      />
      <RowContainer>
        <WidthContainer width={"auto"}>
          <ToggleButtonField
            onChange={handleToggleChange}
            name='reminderType'
            options={reminderTypeOptions}
          />
        </WidthContainer>
        <WidthContainer width={"100%"}>
          <SelectField
            name={"noteId"}
            placeholder={"Associated Note*"}
            options={noteSequenceOptions}
        />
        </WidthContainer>
      </RowContainer>

      <RowContainer>
        <WidthContainer width={"50%"}>
          <DateField name='date' placeholder='Date' />
        </WidthContainer>
      {
        type === 'Note' &&
        <WidthContainer width={"50%"}>
          <TimeField name='time' placeholder='Time' />
        </WidthContainer>
      }
      </RowContainer>
      <SelectField
        name={"repeat"}
        placeholder={"Frequency"}
        options={ frequency }
      />
    </FieldContainer>
  );
}

export default AddSystemReminderFields;
