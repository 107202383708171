import { Link } from "react-router-dom";
import { AddLoginFields } from "../../components/formFields";
import {
  Button,
  LoginProps,
  privateAgent,
  SubTitle,
  TextButton,
  Title,
} from "../../lib";

import { Formik } from "formik";
import LoginValidationSchema from "../../components/formFields/Login/loginValidationSchema";
import { routesName } from "../../lib/RoutesName/Routes";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import LoginPageLayout from "../../components/shared/loginPageLayout";
import {
  ButtonContainer,
  FormContainer,
  LoginContainer,
  TitleContainer,
} from "./../../components/styles/login.styled";

interface LoginPageProps{
  onSuccess:()=>void
}

function LoginPage({onSuccess}:LoginPageProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = async (values: LoginProps) => {
    try {
      const { data } = await privateAgent.post(
        routesName.AuthRoute({}).login,
        values
      );
      Cookies.set("token", data.result.token);
      Cookies.set("expireDate", JSON.stringify(data.result.refresh.expires));
      Cookies.set("refreshToken", data.result.refresh.token);
      Cookies.set("userId", data.result.id);
      Cookies.set("role", data?.result?.role);
    
      if(data?.result?.role?.toLowerCase() === 'admin'){
        navigate("/users");
      }else if (data?.result?.role?.toLowerCase() ==='coach'){
        navigate("/coach/clients");
      } else if (data?.result?.role?.toLowerCase() ==='client'){
        navigate("/notes");
      }
      else{
        navigate("/");
      }
      if(onSuccess){
        onSuccess()
      }
      
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.error ?? "", { variant: "error" });
    }
  };

  return (
    <>
      <LoginPageLayout>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={LoginValidationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <FormContainer>
              <LoginContainer>
                <TitleContainer>
                  <Title label={"Login"} />
                  
                  <SubTitle
                    label={"Enter your login credentials to start using Paige."}
                  />
                </TitleContainer>
                <AddLoginFields />
                <ButtonContainer>
                  <Button
                    label={"Log In"}
                    type={"Submit"}
                    disabled={!formikProps.isValid}
                  ></Button>
                  <Link to={`/forgotpassword`}>
                    <TextButton label={"Forgot password"} />
                  </Link>
                </ButtonContainer>
              </LoginContainer>
            </FormContainer>
          )}
        </Formik>
      </LoginPageLayout>
    </>
  );
}

export default LoginPage;
