import SvgIcon from "@mui/material/SvgIcon";

function ClientsIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <g clipPath='url(#clip0_1011_5464)'>
          <path
            d='M1.99984 12.6666H0.666504V11.9999C0.666504 10.7574 1.51635 9.71332 2.6665 9.41725M3.99984 7.21945C3.22304 6.94492 2.6665 6.20406 2.6665 5.33325C2.6665 4.46244 3.22304 3.72161 3.99984 3.44705M13.9998 12.6666H15.3332V11.9999C15.3332 10.7574 14.4833 9.71332 13.3332 9.41725M11.9998 3.44705C12.7766 3.72161 13.3332 4.46244 13.3332 5.33325C13.3332 6.20406 12.7766 6.94492 11.9998 7.21945M6.6665 9.33325H9.33317C10.8059 9.33325 11.9998 10.5272 11.9998 11.9999V12.6666H3.99984V11.9999C3.99984 10.5272 5.19374 9.33325 6.6665 9.33325ZM9.99984 5.33325C9.99984 6.43782 9.10444 7.33325 7.99984 7.33325C6.89524 7.33325 5.99984 6.43782 5.99984 5.33325C5.99984 4.22869 6.89524 3.33325 7.99984 3.33325C9.10444 3.33325 9.99984 4.22869 9.99984 5.33325Z'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_1011_5464'>
            <rect width='16' height='16' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default ClientsIcon;
