import { useState, useMemo } from "react";
import {
  privateAgent,
  Button,
  DialogBox,
  TextButton,
  AlertDialog,
  useTitle,
} from "../../../lib";
import { DataTable } from "../../../lib/Forms/DataTable";
import { MRT_ColumnDef } from "material-react-table";
import { Formik, Form } from "formik";
import { useQuery, useQueryClient } from "react-query";
import { routesName } from "../../../lib/RoutesName/Routes";
import { useSnackbar } from "notistack";
import ClassModeMenu from "../../../components/formFields/Classes/ClassModeMenu";
import AddBehaviorFields from "../../../components/formFields/Behaviors/AddBehaviorFields";
import { AddBehaviorProps } from "../../../lib/Types/BehaviorProps";
import {
  behaviorInitialValues,
  editBehaviorIntitialValues,
} from "../../../components/formFields/Behaviors/behavior-helpers";
import AddBehaviorValidationSchema from "../../../components/formFields/Behaviors/AddBehaviorValidationSchema";
import BehaviorSearchFields, {
  defaultSearchParams,
  SearchParams,
} from "../../../components/formFields/Behaviors/BehaviorSearchFields";
import { Mode } from "../../../components/shared/dropdown/mode";
import {
  ButtonContainer,
  TopContainerNoMargin,
  UsersContainer,
  TableContainer,
} from "src/styles/Containers";
import { TitleLarge } from "src/styles/Typography";

function BehaviorPage() {
  useTitle("Behaviors | Paige");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openAddBehaiorDialog, setOpenAddBehaviorDialog] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [behaviorId, setBehaviorId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [fieldInitialValues, setFieldInitialValues] =
    useState<AddBehaviorProps>(behaviorInitialValues);
  const [searchQueries, setSearchQueries] =
    useState<SearchParams>(defaultSearchParams);
  const queryClient = useQueryClient();

  let { isLoading, data } = useQuery(["BehaviorQuery"], async () => {
    const { data } = await privateAgent.get(
      routesName.BehaviorRoute({}).get_behavior
    );

    if (data.status === 200) {
      const results = data.result.items;
      setFilteredData(results);
      return results;
    }
  });

  const handleSubmit = async (values: AddBehaviorProps) => {
    try {
      if (isEdit) {
        const res = await privateAgent.put(
          routesName.BehaviorRoute({ id: behaviorId }).update_behavior,
          values
        );
        if (res.data && res.data.status === 200) {
          queryClient.invalidateQueries("BehaviorQuery");
          setOpenAddBehaviorDialog(false);
          enqueueSnackbar("Behaviour edited successfully", {
            variant: "success",
          });
        }
      } else {
        const res = await privateAgent.post(
          routesName.BehaviorRoute({}).add_behavior,
          values
        );
        if (res.status === 201) {
          queryClient.invalidateQueries("BehaviorQuery");
          setOpenAddBehaviorDialog(false);
          enqueueSnackbar("Behaviour added successfully", {
            variant: "success",
          });
        }
      }
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.error ?? "", { variant: "error" });
    }
  };

  const handleDelete = async () => {
    try {
      const res = await privateAgent.delete(
        routesName.BehaviorRoute({ id: behaviorId }).delete_behavior
      );
      if (res.status === 200) {
        queryClient.invalidateQueries("BehaviorQuery");
        setOpenDeleteDialog(false);
        enqueueSnackbar("Behavior deleted successfully", {
          variant: "success",
        });
      }
    } catch (error: any) {
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
    }
  };

  const handleRowClick = (column: any, row: any) => {
    if (column.id === "name") {
      setOpenAddBehaviorDialog(true);
      setFieldInitialValues(editBehaviorIntitialValues(row.original));
      setBehaviorId(row.original.id);
      setIsEdit(true);
    }
  };

  type Behavior = {
    id: number;
    name: string;
    behaviorCategoryId: number;
    behaviorCategory: {
      name: string;
    };
  };
  //grid defn
  const columns = useMemo<MRT_ColumnDef<Behavior>[]>(
    () => [
      {
        accessorFn: (row) => `${row.name}`,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row) => `${row.behaviorCategory?.name}`,
        id: "behaviorCategory",
        header: "Behavior Category",
      },
      {
        id: "actions",
        header: "Actions",
        headerClassName: "header-row",
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
          className: "action",
        },
        size: 50,
        enableResizing: false, //disable resizing for this column
        Cell: ({ cell }) => (
          <>
            <ClassModeMenu
              id={0}
              onItemClick={function (id: number, mode: Mode): void {
                if (mode == "edit") {
                  setOpenAddBehaviorDialog(true);
                  setFieldInitialValues(
                    editBehaviorIntitialValues(cell.row.original)
                  );
                  setBehaviorId(cell.row.original.id.toString());
                  setIsEdit(true);
                }
                if (mode == "duplicate") {
                  setOpenAddBehaviorDialog(true);
                  setFieldInitialValues(
                    editBehaviorIntitialValues(cell.row.original)
                  );
                  setBehaviorId(cell.row.original.id.toString());
                  setIsEdit(false);
                }
                if (mode === "delete") {
                  setBehaviorId(cell.row.original.id.toString());
                  setOpenDeleteDialog(true);
                }
              }}
            />
          </>
        ),
      },
    ],
    []
  );
  //end of grid defn

  const handleSearchQuery = (searchQuery: SearchParams) => {
    setSearchQueries(searchQuery);
    const filteredData = data?.filter((item: any) => {
      const hasBehaviorCategory =
        item?.behaviorCategoryId == searchQuery.behaviorCategoryId;
      const hasBehavior =
        item?.name
          ?.toLowerCase()
          .indexOf(searchQuery.searchQuery.toLowerCase()) > -1;

      let toFiltered = true;
      if (searchQuery.searchQuery != "") {
        toFiltered = toFiltered && hasBehavior;
      }

      if (searchQuery.behaviorCategoryId != "0") {
        toFiltered = toFiltered && hasBehaviorCategory;
      }
      return toFiltered;
    });

    setFilteredData(filteredData);
  };

  return (
    <>
      <AlertDialog
        variety='error'
        onConfirm={() => handleDelete()}
        onClose={() => setOpenDeleteDialog(false)}
        isOpen={openDeleteDialog}
        title={"Are you sure you want to delete this behavior?"}
        content={
          "Deleting this behavior will permanently erase any data associated with this behavior."
        }
        btnText={{ cancel: "Cancel", action: "Delete" }}
      />
      <DialogBox
        isOpen={openAddBehaiorDialog}
        onClose={() => setOpenAddBehaviorDialog(false)}
        title={isEdit ? "Edit Behavior" : "Add Behavior"}
        content={
          <Formik
            initialValues={fieldInitialValues}
            onSubmit={handleSubmit}
            validationSchema={AddBehaviorValidationSchema}
          >
            {(formikProps) => (
              <Form>
                <AddBehaviorFields />
                <ButtonContainer>
                  <TextButton
                    label={"Cancel"}
                    onClick={() => setOpenAddBehaviorDialog(false)}
                  ></TextButton>
                  <Button
                    label={"Save"}
                    type={"submit"}
                    disabled={!formikProps.isValid}
                  ></Button>
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        }
      />
      <TopContainerNoMargin>
        <TitleLarge>Behaviors</TitleLarge>
        <Button
          label={"+ Add Behavior"}
          onClick={() => {
            setOpenAddBehaviorDialog(true);
            setIsEdit(false);
            setFieldInitialValues(behaviorInitialValues);
          }}
        ></Button>
      </TopContainerNoMargin>
      <UsersContainer>
        <BehaviorSearchFields
          onSearchQuery={(searchQuery: SearchParams) =>
            handleSearchQuery(searchQuery)
          }
        />
        <TableContainer>
          {filteredData && filteredData.length > 0 && (
            <DataTable
              columns={columns}
              data={filteredData}
              onCellClick={handleRowClick}
              enableSorting={true}
              enableRowOrdering={false}
            />
          )}
        </TableContainer>
      </UsersContainer>
    </>
  );
}

export default BehaviorPage;
