
import { AddCallsToActionProps } from "../../../lib/Types/CallsToActionProps"
import { AddChangePwProps } from "../../../lib/Types/ChangePwProps"

export const changePwInitialValues : AddChangePwProps =  {
    oldPassword: "",
    password:"",
    confirmPassword:""
} 

