import SvgIcon from "@mui/material/SvgIcon";

function PlayIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'>
        <g>
          <path
            id='Vector'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.14299 4.24463L10.933 7.10927C11.1809 7.28593 11.3281 7.57153 11.3281 7.87593C11.3281 8.1804 11.1809 8.466 10.933 8.6426L7.13965 11.7759C6.81945 12.0302 6.38592 12.0893 6.00937 11.9299C5.63282 11.7705 5.3734 11.4182 5.33301 11.0113V5.01129C5.37195 4.60281 5.63167 4.24854 6.00951 4.0885C6.38735 3.92846 6.82252 3.9884 7.14299 4.24463Z'
            stroke='#172882'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default PlayIcon;
