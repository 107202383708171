import { useState, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { AlertDialog, Button } from "@lib/Forms";
import { privateAgent } from "@lib/Requests/AuthRequests";
import { useTitle } from "@lib/Hooks";
import { routesName } from "@lib/RoutesName/Routes";
import { useNavigate, useParams } from "react-router-dom";
import { MRT_ColumnDef, MRT_Row } from "material-react-table";
import { BehaviorProps } from "@lib/Types/BehaviorProps";
import { DataTable } from "@lib/Forms/DataTable";
import SequenceModeMenu from "@components/formFields/Sequence/SequenceModeMenu";
import { Mode } from "@components/shared/dropdown/mode";
import { SequenceProps } from "@lib/Types/SequenceProps";
import dayjs from "dayjs";
import { PAGE_FOR } from "@pages/pagefor";
import Cookies from "js-cookie";
import AddNoteToSequenceBtn from "@components/formFields/Sequence/AddNoteToSequenceBtn";
import { SequenceNoteProps } from "@lib/Types/SequenceNoteProps";
import { Tag } from "@components/styles/general.styled";
import {
  TopContainerNoMargin,
  SeqRowContainer,
  UsersContainer,
  TableContainer,
  Dot,
} from "src/styles/Containers";
import { TitleLarge } from "src/styles/Typography";
import { ReminderProps, UserProps } from "@lib/Types";
import PaigeSkeleton from "@components/formFields/skeleton/PaigeSkeleton";

interface SequencePageProps {
  pagefor: PAGE_FOR;
}

function SequencePage({ pagefor }: SequencePageProps) {
  useTitle("Client sequence | Paige");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDupSeqDialog, setDupSeqDialog] = useState(false);

  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const { clientId } = params;
  const [filteredData, setFilteredData] = useState([]);
  const [sequenceId, setSequenceId] = useState("");
  const [deleteOption, setDeleteOption] = useState(""); //SEQUENCE , SEQUENCE_NOTE
  const [user, setUser] = useState<UserProps>()

  let {  data: clientFullName } = useQuery(
    ["Client"],
    async () => {
      const userId = pagefor == 'coach' ? clientId : Cookies.get("userId")
      const { data } = await privateAgent.get(
        routesName.UserRoute({ id: userId }).get_single_user
      );

      if (data.status === 200) {
        const results = `${data.result.userdetails.firstName} ${data.result.userdetails.lastName}`;
        setUser(data.result)
        return results;
      }
    },
    { enabled: pagefor == "coach" || pagefor == "client" }
  );

  let {isLoading,  data: clientSequences } = useQuery(["Clients_Sequences"], async () => {
    const clientid = pagefor == "coach" ? clientId : Cookies.get("userId");
    const { data } = await privateAgent.get(
      routesName.SequenceRoute({ id: clientid }).get_sequences_by_clientId
    );

    if (data.status === 200) {
      const results = data.result.items;

      const refinedResults = results.map((data: { sequenceNotes: any }) => {
        const sequenceNotes = data.sequenceNotes as SequenceNoteProps[];
        //ordering based on time
        sequenceNotes.sort((a: SequenceNoteProps, b: SequenceNoteProps) => {
          if (a.day < b.day) return -1;
          if (a.day > b.day) return 1;

          if (a.time < b.time) return -1;
          if (a.time > b.time) return 1;
          return 0;
        });
        return { ...data, ismain: true, subRows: sequenceNotes };
      });
      setFilteredData(refinedResults);
      return results;
    }
  });

  type Sequence = {
    id: number;
    title: string;
    type: string;
    noteType: string;
    day: string;
    time: string;
    isActive: boolean;
    behavior: BehaviorProps;
    ismain?: boolean;
    sequenceId?: number;
    isSystem?: boolean;
    reminders?: ReminderProps[];
  };

  const columns = useMemo<MRT_ColumnDef<Sequence>[]>(
    () => [
      {
        accessorFn: (row) => `${row.title}`,
        id: "title",
        header: "Sequence",
        Cell: ({ cell }) => (
          <div>
            {cell.row.original.title}
            {cell.row.original.ismain && cell.row.original.isSystem 
               && <Tag>System</Tag>}
            {!cell.row.original.ismain && cell.row.original.isSystem && <Dot />}
          </div>
        ),
      },
      {
        header: "Day",
        id: "day",
        accessorFn: (row) => `${row.day ?? ""}`,
        enableSorting: false,
        size: 100,
      },
      {
        header: "Time",
        id: "time",
        accessorFn: (row) =>
          `${
            !row?.ismain
              ? dayjs("2023-01-01 " + row.time).format("hh:mm A")
              : "" ?? ""
          }`,
        size: 100,
        enableSorting: false,
      },
      {
        header: "Behavior",
        id: "behavior",
        accessorFn: (row) => `${row?.behavior?.name ?? ""}`,
        size: 100,
      },
      {
        header: "Active",
        id: "isActive",
        accessorFn: (row) =>
          `${row?.ismain ? (row?.isActive ? "Yes" : "No") : ""}`,
        size: 100,
      },
      {
        id: "actions",
        header: "Actions",
        headerClassName: "header-row",
        flex: 1,
        muiTableBodyCellProps: {
          align: "right",
          className: "action",
        },
        size: 50,
        enableResizing: false, //disable resizing for this column
        Cell: ({ cell }) =>
          
          (
            // cell.row.original.noteType.indexOf("SYSTEM") == -1 && (
              <SequenceModeMenu
                isSubRows={cell.row.depth == 1}
                id={0}
                type={cell.row.original.type}
                isSystem={cell.row.original.isSystem ?? false}
                pageFor= {pagefor}
                onItemClick={function (id: number, mode: Mode): void {
                  if (mode == "edit") {
                    if (cell.row.original.ismain) {
                      if (pagefor == "coach") {
                        navigate(
                          `/coach/clients/${clientId}/editsequence/${cell.row.original.id}`
                        );
                      }
                      if (pagefor == "client") {
                        navigate(
                          `/clients/editsequence/${cell.row.original.id}`
                        );
                      }
                    } else {
                      if (pagefor == "coach") {
                        navigate(
                          `/coach/clients/editsequencenote/${cell.row.original.id}`
                        );
                      }
                      if (pagefor == "client") {
                        navigate(
                          `/clients/editsequencenote/${cell.row.original.id}`
                        );
                      }
                    }
                  }

                  if (mode == "duplicate") {
                    if (cell.row.original.ismain) {
                      if (pagefor == "coach") {
                        navigate(
                          `/coach/clients/${clientId}/addsequence/${cell.row.original.id}/duplicate`
                        );
                      }
                      if (pagefor == "client") {
                        navigate(
                          `/clients/addsequence/${cell.row.original.id}/duplicate`
                        );
                      }
                    } else {
                      if (pagefor == "coach") {
                        navigate(
                          `/coach/clients/${clientId}/sequence/${cell.row.original.sequenceId}/addsequencenote/${cell.row.original.id}/duplicate`
                        );
                      }
                      if (pagefor == "client") {
                        navigate(
                          `/clients/sequence/${cell.row.original.sequenceId}/addsequencenote/${cell.row.original.id}/duplicate`
                        );
                      }
                    }
                  }

                  if (mode == "add_sequence_note") {
                    if (pagefor == "coach") {
                      navigate(
                        `/coach/clients/${clientId}/addsequencenote/${cell.row.original.id}`,
                        {
                          state: {
                            title: cell.row.original.title,
                          },
                        }
                      );
                    }
                    if (pagefor == "client") {
                      navigate(
                        `/clients/addsequencenote/${cell.row.original.id}`,
                        {
                          state: {
                            title: cell.row.original.title,
                          },
                        }
                      );
                    }
                  }

                  if (mode === "delete") {
                    if(cell.row.original.reminders?.length!>0){
                      enqueueSnackbar("This sequence is associated to a reminder", { variant: "error" });
                      return;
                    }
                    setSequenceId(`${cell.row.original.id}`);
                    if (cell.row.original.ismain) {
                      setDeleteOption("SEQUENCE");
                    } else {
                      setDeleteOption("SEQUENCE_NOTE");
                    }

                    setOpenDeleteDialog(true);
                  }

                  if(mode === "duplicate_sequence"){
                    setSequenceId(`${cell.row.original.id}`);
                    setDupSeqDialog(true)
                  }
                }}
              />
            // )
            ),
      },
    ],
    []
  );

  const handleDelete = async () => {
    try {
      if (deleteOption == "SEQUENCE") {
        const res = await privateAgent.delete(
          routesName.SequenceRoute({ id: sequenceId }).delete_sequence
        );
        setOpenDeleteDialog(false);
        if (res.status === 200) {
          queryClient.invalidateQueries("Clients_Sequences");
          enqueueSnackbar("Sequence deleted successfully", {
            variant: "success",
          });
        }
      }

      if (deleteOption == "SEQUENCE_NOTE") {
        const res = await privateAgent.delete(
          routesName.SequenceNoteRoute({ id: sequenceId }).delete_sequencenotes
        );
        setOpenDeleteDialog(false);
        if (res.status === 200) {
          queryClient.invalidateQueries("Clients_Sequences");
          enqueueSnackbar("Sequence Note deleted successfully", {
            variant: "success",
          });
        }
      }
    } catch (error: any) {
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
      setOpenDeleteDialog(false);
    }
  };

  const handleDuplicateSeq = async()=>{
    console.log("handle duplicate sequence")
    try{
      const res = await privateAgent.post(
        routesName.SequenceRoute({}).duplicate_sequence,
        { sequenceId: sequenceId }
      );

      setDupSeqDialog(false)
      
      if (res.status === 201) {
        queryClient.invalidateQueries("Clients_Sequences");
        enqueueSnackbar("Sequence duplicated successfully", {
          variant: "success",
        });
      }

    }catch(error:any){
      enqueueSnackbar(error.response.data.error ?? "", { variant: "error" });
      setDupSeqDialog(false); 
    }
    
  }

  const handleRowClick = (column: any, row: any) => {
    const sequence = row.original as Sequence
    if(row.original.ismain && sequence.isSystem) return;
    if (
      column.id !== "title" ||
      (row.original.ismain &&
        column.id == "title" &&
        row.original.type.includes("SYSTEM"))
    )
      return;
    if (row.original.ismain) {
      if (pagefor == "coach") {
        navigate(`/coach/clients/${clientId}/editsequence/${row.original.id}`);
      }
      if (pagefor == "client") {
        navigate(`/clients/editsequence/${row.original.id}`);
      }
    } else {
      if (pagefor == "coach") {
        navigate(`/coach/clients/editsequencenote/${row.original.id}`);
      }
      if (pagefor == "client") {
        navigate(`/clients/editsequencenote/${row.original.id}`);
      }
    }
  };


  if(isLoading){
    return <PaigeSkeleton />
  }

  return (
    <>
      <AlertDialog
        variety='error'
        onConfirm={() => handleDelete()}
        onClose={() => setOpenDeleteDialog(false)}
        isOpen={openDeleteDialog}
        title={`Are you sure you want to delete this ${deleteOption == "SEQUENCE" ? "sequence" : "note"}`}
        content={
          `Deleting this ${deleteOption == "SEQUENCE" ? "sequence" : "note"} will permanently erase any data associated with this ${deleteOption == "SEQUENCE" ? "sequence" : "note"}.`
        }
        btnText={{ cancel: "Cancel", action: "Delete" }}
      />

    <AlertDialog
        variety='error'
        onConfirm={() => handleDuplicateSeq()}
        onClose={() => setDupSeqDialog(false)}
        isOpen={openDupSeqDialog}
        title={`Are you sure you want to duplicate this sequence`}
        content={
          `You are about to duplicate this sequence and all its notes.`
        }
        btnText={{ cancel: "Cancel", action: "Duplicate" }}
      />

      <TopContainerNoMargin>
        <TitleLarge>
          {pagefor == "coach" && clientFullName != undefined
            ? `${clientFullName}'s Sequence`
            : ""}
        </TitleLarge>
        <SeqRowContainer>
          {

            <AddNoteToSequenceBtn
              hide = { false}
              pagefor={pagefor}
              clientId={clientId}
              onItemClick={(sequence: SequenceProps) => {
                if (pagefor == "coach") {
                  navigate(
                    `/coach/clients/${clientId}/addsequencenote/${sequence.id}`,
                    {
                      state: {
                        title: sequence.title,
                      },
                    }
                  );
                }
                if (pagefor == "client") {
                  navigate(`/clients/addsequencenote/${sequence.id}`, {
                    state: {
                      title: sequence.title,
                    },
                  });
                }
              }}
            />
          }
          {/* {
            pagefor === 'client' && user?.hasAdvancedSettings
          } */}
          <Button
            label={"+ Add Sequence"}
            hide = {pagefor === 'client' && !user?.hasAdvancedSettings}
            onClick={() => {
              if (pagefor == "coach") {
                navigate(`/coach/clients/${clientId}/addsequence`);
              }
              if (pagefor == "client") {
                navigate(`/clients/addsequence`);
              }
            }}
          />
        </SeqRowContainer>
      </TopContainerNoMargin>
      <UsersContainer>
        <TableContainer>
          {filteredData && filteredData.length > 0 && (
            <DataTable
              columns={columns}
              data={filteredData}
              enableRowOrdering={true}
              enableExpanding={true}
              enableSorting={true}
              onCellClick={handleRowClick}
              initialState={{
                sorting: [
                  {
                    id: "day",
                    desc: false,
                  },
                  {
                    id: "time",
                    desc: false,
                  },
                ],
              }}
              enableMultiRemove={false}
              enableSortingRemoval={false}
              isMultiSortEvent={() => true}
              multiSortEvent={() => true}
              muiTableBodyRowDragHandleProps={({ table }: any) => ({
                onDragEnd: async () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    filteredData.splice(
                      (hoveredRow as MRT_Row<any>).index,
                      0,
                      filteredData.splice(draggingRow.index, 1)[0]
                    );

                    const Order = filteredData.map(
                      (data: SequenceNoteProps, index: number) => {
                        return {
                          id: data.id,
                          ordering: index,
                        };
                      }
                    );

                    const order = await privateAgent.post(
                      routesName.SequenceRoute({}).sequences_reorder,
                      { sequences: Order }
                    );
                    queryClient.invalidateQueries("Clients_Sequences");
                  }
                },
              })}
            />
          )}
        </TableContainer>
      </UsersContainer>
    </>
  );
}

export default SequencePage;
