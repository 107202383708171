import SvgIcon from "@mui/material/SvgIcon";

function RemindersIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          d='M9.99996 11.3333V12C9.99996 13.1046 9.10456 14 7.99996 14C6.89536 14 5.99996 13.1046 5.99996 12V11.3333M9.99996 11.3333H5.99996M9.99996 11.3333H12.3936C12.6486 11.3333 12.7768 11.3333 12.88 11.2985C13.0773 11.232 13.2316 11.0771 13.2982 10.8799C13.3331 10.7762 13.3331 10.6477 13.3331 10.3906C13.3331 10.2781 13.333 10.2219 13.3242 10.1683C13.3076 10.0669 13.2682 9.97087 13.2084 9.88747C13.1767 9.8434 13.1365 9.8032 13.0572 9.72387L12.7975 9.4642C12.7137 9.3804 12.6666 9.26673 12.6666 9.14827V6.66667C12.6666 4.08933 10.5773 1.99999 7.99996 2C5.42263 2.00001 3.33329 4.08934 3.33329 6.66667V9.14827C3.33329 9.2668 3.28612 9.3804 3.20234 9.4642L2.94267 9.72387C2.86313 9.8034 2.82332 9.84333 2.79163 9.88747C2.73173 9.97093 2.69206 10.0669 2.67543 10.1683C2.66663 10.2219 2.66663 10.2781 2.66663 10.3907C2.66663 10.6477 2.66663 10.7761 2.70159 10.8798C2.76813 11.0771 2.92316 11.232 3.1204 11.2985C3.22367 11.3333 3.3513 11.3333 3.60633 11.3333H5.99996'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
}

export default RemindersIcon;
