import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import styled from "@emotion/styled";
import { Button } from "../../../lib";
import { EditIcon, DeleteIcon, DuplicateIcon } from "../../../assets";
import { Mode } from "../../shared/dropdown/mode";

const MenuStyled = styled(Menu)`
  border: 1px solid #e2e6e8;
`;
const SelectMenu = styled(MenuItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 0;
  background: #fff;
  margin: 8px 4px;
  color: #172882;
  & > :first-of-type {
    box-sizing: border-box;
    height: 16px;
  }
`;

export interface AddNoteButtonOptions{
  title: string
  type: Mode
}

export interface HoverMenuProps {
  onItemClick: (mode: Mode) => void;
  options: AddNoteButtonOptions[]
}

export default function AddNoteBtn({ onItemClick , options}: HoverMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log("handle click");
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const OnMenuItemClicked = (mode: Mode) => {
    handleClose();
    onItemClick(mode);
  };

  return (
    <div>
      <Button
        label={"+ Add Notes"}
        onClick={handleClick}
        aria-label='more'
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup='true'
      ></Button>
      <MenuStyled
        id='long-menu'
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <SelectMenu
          onClick={() => OnMenuItemClicked("regular-note")}
          disableRipple
        >
          Regular Note
        </SelectMenu>
        <SelectMenu
          onClick={() => OnMenuItemClicked("link-note")}
          disableRipple
        >
          Link Note
        </SelectMenu> */}

        { options.map((option) =>  <SelectMenu
           onClick={() => OnMenuItemClicked(option.type)}
           disableRipple
         >
           {option.title}
         </SelectMenu>
         )}
      </MenuStyled>
    </div>
  );
}
