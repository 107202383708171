import { AddClassesProps, AddUserProps } from "../../../lib"
import { AddBehaviorCategoryProps } from "../../../lib/Types/BehaviorCategoryProps"
import { AddBehaviorProps } from "../../../lib/Types/BehaviorProps"

export const behaviorCategoryInitialValues :AddBehaviorCategoryProps =  {
    name: ""
} 

export const editBehaviorCategoryIntitialValues=(item: any): AddBehaviorCategoryProps=>{

    return {
         name:item?.name 
    }
}


export const behaviorInitialValues :AddBehaviorProps =  {
    name: "",
    behaviorCategoryId: ''
} 

export const editBehaviorIntitialValues=(item: any): AddBehaviorProps=>{

    return {
         name:item?.name,
         behaviorCategoryId:`${item?.behaviorCategoryId}`
    }
}