import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BehaviorPage from "./BehaviorPage";
import BehaviorCategoryPage from "./BehaviorCategoryPage";
import styled from "@emotion/styled";
import { TabScrollButton } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BehaviorTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const StyledTabs = styled(Tabs)`
    & .MuiTab-root {
      text-transform: none;
      font-family: Nunito;
      font-size: 16px;
      font-weight: 500;
    }
    & .Mui-selected {
      font-weight: 700;
    }
  `;

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{}}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='Behaviors' {...a11yProps(0)} />
          <Tab label='Behavior Categories' {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <BehaviorPage />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BehaviorCategoryPage />
      </TabPanel>
    </Box>
  );
}
