import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { LoginInputField } from "../../../lib";
import { FieldContainer } from "src/styles/Containers";

function AddLoginFields() {
  const formikProps = useFormikContext();
  const [focus, setFocus] = React.useState(true);

  useEffect(() => {
    formikProps.setTouched({});
    setFocus(false);
  }, []);

  return (
    <>
      <FieldContainer>
        <LoginInputField
          placeholder={"Email*"}
          name={"email"}
          inputRef={(input: any) => (focus ? input && input.focus() : null)}
          onFocus={(e: any) => {
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            );
          }}
        />
        <LoginInputField
          type={"password"}
          placeholder={"Password*"}
          name={"password"}
        />
      </FieldContainer>
    </>
  );
}

export default AddLoginFields;
