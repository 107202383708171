import { AddClassesProps, AddUserProps } from "../../../lib"

export const classesInitialValues :AddClassesProps =  {
    name: "",
    isLast: "1",
    isActive: true
} 

export const CreateUsersListObject=(item: any)=>{

    return {
         id: item.id,
         firstName:item.userdetails?.firstName ?? '',
         name: item.userdetails?  `${item.userdetails?.firstName ?? ''} ${item?.userdetails?.lastName ?? ''}`: '' , 
         email: item?.email ?? '', 
         class: item?.classes[0]?.name ?? '', 
         userType: item?.role?.name ?? '', 
         coach: `${item?.coach?.users?.userdetails?.firstName} ${item?.coach?.users?.userdetails?.lastName}`?? '', 
         advancedSettings: 'No', 
         status: item?.isActive ?? false, 
    }
}

export const editClasesIntitialValues=(item: any): AddClassesProps=>{

    console.log("item in class ", item)
    return {
         name:item?.name ,
         isActive: true, 
         isLast: item.isLast ? "1":"0"
    }
}