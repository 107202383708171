import SvgIcon from "@mui/material/SvgIcon";

function LogoIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox='0 0 32 29'>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 29'>
        {/* SVG content */}
        <g clipPath='url(#clip0_43_11141)'>
          <path
            d='M30.0983 27.4474H0.832686L0 12.1981L10.8155 14.3099L20.0302 14.3945L31.122 12.191L30.0983 27.4474Z'
            fill='#F08812'
          />
          <path
            d='M25.7401 26.0063H5.70121C4.72743 26.0063 3.91013 25.2775 3.80543 24.3161L2.91721 16.1707C2.8977 15.9918 3.03879 15.8356 3.22004 15.8356L27.9725 15.8337C28.1526 15.8337 28.2937 15.9884 28.2757 16.1666L27.4366 24.482C27.3492 25.3476 26.6159 26.0066 25.7405 26.0066L25.7401 26.0063Z'
            fill='#351C16'
          />
          <path
            d='M25.163 20.5172C25.163 22.3136 24.0215 23.8453 22.4199 24.4354C21.9643 24.6031 21.472 24.6948 20.9579 24.6948C20.4438 24.6948 19.9252 24.5983 19.4599 24.422C17.8771 23.8222 16.7528 22.3002 16.7528 20.5176C16.7528 18.2105 18.6354 16.3403 20.9579 16.3403C23.2803 16.3403 25.163 18.2105 25.163 20.5176V20.5172Z'
            fill='white'
          />
          <path
            d='M14.6787 20.5172C14.6787 21.5226 14.3211 22.4448 13.7256 23.1658C12.9544 24.0992 11.7837 24.6949 10.4733 24.6949C9.16289 24.6949 7.88703 24.0444 7.11926 23.0375C6.5849 22.3371 6.26819 21.4641 6.26819 20.5172C6.26819 18.2102 8.15083 16.34 10.4733 16.34C12.7957 16.34 14.6783 18.2102 14.6783 20.5172H14.6787Z'
            fill='white'
          />
          <path
            d='M19.9785 13.9937L28.9711 12.191C27.0194 6.08729 21.9835 2.44421 15.5509 2.44421C9.11832 2.44421 3.93795 6.53909 2.13074 12.191L10.9916 13.8793L19.9781 13.9937H19.9785Z'
            fill='#F08812'
          />
          <path
            d='M5.05237 6.51377C3.64293 5.41261 2.93745 2.3421 2.62674 0.120396C2.6076 -0.0171561 2.42185 -0.0451138 2.36031 0.079764C2.11565 0.576666 1.85973 1.29872 1.68598 2.16206C1.23943 4.37966 1.47021 7.35735 3.04965 9.22679L3.20988 8.9405C3.70747 8.05033 4.32776 7.23359 5.05237 6.51377Z'
            fill='#F08812'
          />
          <path
            d='M26.1597 6.3859C27.5691 5.28436 28.1226 2.39875 28.4333 0.17667C28.4525 0.0391183 28.6382 0.0111606 28.6994 0.136038C28.944 0.632941 29.2003 1.35537 29.3741 2.2187C29.8206 4.43631 29.7426 7.30067 28.1631 9.17049L27.9706 8.86743C27.5342 8.18078 27.0614 7.51763 26.5541 6.88094L26.1597 6.3859Z'
            fill='#F08812'
          />
          <path
            d='M10.8189 22.2342C11.8688 22.2342 12.7199 21.3887 12.7199 20.3457C12.7199 19.3028 11.8688 18.4573 10.8189 18.4573C9.76897 18.4573 8.91785 19.3028 8.91785 20.3457C8.91785 21.3887 9.76897 22.2342 10.8189 22.2342Z'
            fill='#351C16'
          />
          <path
            d='M9.21544 21.1547C9.54538 21.1547 9.81285 20.889 9.81285 20.5612C9.81285 20.2335 9.54538 19.9678 9.21544 19.9678C8.88551 19.9678 8.61804 20.2335 8.61804 20.5612C8.61804 20.889 8.88551 21.1547 9.21544 21.1547Z'
            fill='white'
          />
          <path
            d='M20.5188 22.3487C21.5687 22.3487 22.4199 21.5032 22.4199 20.4602C22.4199 19.4173 21.5687 18.5718 20.5188 18.5718C19.4689 18.5718 18.6178 19.4173 18.6178 20.4602C18.6178 21.5032 19.4689 22.3487 20.5188 22.3487Z'
            fill='#351C16'
          />
          <path
            d='M18.781 21.1547C19.1109 21.1547 19.3784 20.889 19.3784 20.5612C19.3784 20.2335 19.1109 19.9678 18.781 19.9678C18.4511 19.9678 18.1836 20.2335 18.1836 20.5612C18.1836 20.889 18.4511 21.1547 18.781 21.1547Z'
            fill='white'
          />
          <path
            d='M15.9021 19.8191L12.9834 22.4736L15.8687 29L18.772 22.4889L15.9021 19.8191Z'
            fill='#F7921F'
          />
        </g>
        <defs>
          <clipPath id='clip0_43_11141'>
            <rect width='31.122' height='29' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default LogoIcon;
