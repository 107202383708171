import dayjs from "dayjs";
import { SequenceNoteProps } from "@lib/Types/SequenceNoteProps";
import { SequenceProps } from "@lib/Types/SequenceProps";

export const sequenceInitialValue = {
  title: "",
  behaviorId: "",
  isActive: 1,
  overrideRegular: "0",
  isRitualDisable: "0",
};

export const sequenceEditValue = (sequence: SequenceProps) => {
  const value: any = {
    title: sequence.title,
    behaviorId: sequence.behaviorId,
    isActive: sequence.isActive ? 1 : 0,
    overrideRegular: sequence.overrideRegular ? "1" : "0",
    isRitualDisable: sequence.isRitualDisable ? "1" : "0",
  };
  return value;
};

export const sequenceNoteInitialValue = {
  title: "",
  notificationText: "",
  behaviorId: "",
  callToActionId: "",
  day: "",
  time: dayjs(),
  noteSteps: [{ text: "" }],
  noteType: "REGULAR",
  link: "",
};

export const sequenceNoteEditValue = (notes: SequenceNoteProps) => {
  const value: any = {
    title: notes.title,
    notificationText: notes.notificationText,
    behaviorId: notes.behaviorId,
    callToActionId: notes.callToActionId,
    noteSteps: notes.noteSteps,
    noteType: notes.noteType,
    link: notes.link,
    day: notes.day,
    time: dayjs(`2023-01-01 ${notes?.time}`),
  };
  for (let i = 0; i < notes.noteSteps.length; i++) {
    value[`noteSteps${i}`] = { text: notes.noteSteps[i].text };
  }
  return value;
};
