import ArrowRightCircle from "@assets/ArrowRightCircle";
import { CheckBox, InputField, SelectField, TextArea } from "../../../lib";
import useCallToActionOptions from "../../../lib/Hooks/options/useCalltoActionOption";
import { Button, TextButton } from "@lib/Forms";
import { Formik } from "formik";
import {
  MainFieldContainer,
  MainFieldContainer1,
  FieldContainer,
  EvenColumnContainer,
  ButtonContainer,
  MainFieldContainer2,
  LabelContainer,
  PaigeAiFormBtnContainer,
  SingleRowSelectContainer,
} from "src/styles/Containers";
import { Label, Title } from "src/styles/Typography";
import { ObjectSchema } from "yup";
import { StyledForm } from "src/styles/Forms";
import { IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface PaigeAIFormFieldsProps {
  isEdit?: boolean;
  initialValues: any;
  handleSubmit: (
    value: any
  ) => Promise<void> | (Promise<any> & ((values: any) => Promise<any>));
  validationSchema: any | ObjectSchema<any>;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
  resetData?: () => void;
}

function PaigeAIFormFields({
  isEdit,
  initialValues,
  handleSubmit,
  validationSchema,
  loading,
  setLoading,
  resetData,
}: PaigeAIFormFieldsProps) {
  const callToActionOptions = useCallToActionOptions();
  const testSelectOptions = [
    {
      Name: "Refutation Generator",
      value: "process_client_input",
      Enabled: 1,
    },
  ];

  return (
    <Formik
      //enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, setValues, values }) => (
        <StyledForm>
          <FieldContainer>
            <SingleRowSelectContainer>
              <SelectField
                name={"action"}
                value={values.action}
                placeholder={"Select Option"}
                options={testSelectOptions}
              />
            </SingleRowSelectContainer>
            <MainFieldContainer2>
              {/* <Title>{"Paige AI"}</Title> */}
              <EvenColumnContainer style={{ position: "relative", marginTop:'5px' }}>
                {values.user_input ? (
                  <IconButton
                    onClick={() => {
                      resetData && resetData();
                      setValues({ ...values, user_input: "" });
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                ) : null}
                <InputField
                  style={{
                    height: "auto",
                    fontSize: "14px",
                  }}
                  rows={16}
                  placeholder={"Add client's Rationalization"}
                  name={"user_input"}
                  value={values.user_input}
                  multiline={true}
                />
              </EvenColumnContainer>

              <EvenColumnContainer >
                
                        <LabelContainer>
                            <Label>You Path</Label>
                        {/* <IconButton>
                                            <ArrowRightCircle/>
                                        </IconButton> */}
                        </LabelContainer>
                        <div style={{position:"relative"}}>
                        {values.you_path_input ? (
                        <IconButton
                            onClick={() => {
                            resetData && resetData();
                            setValues({ ...values, you_path_input: "" });
                            }}
                            style={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            zIndex: 1,
                            }}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                        ) : null}

                        <InputField
                        style={{
                            height: "auto",
                            fontSize: "14px",
                        }}
                        rows={5}
                        placeholder={""}
                        name={"you_path_input"}
                        value={values.you_path_input}
                        multiline={true}
                        />
                </div>
                <LabelContainer>
                  <Label>Lizard Path</Label>
                  {/* <IconButton>
                                    <ArrowRightCircle/>
                                </IconButton> */}
                </LabelContainer>
                <div style={{position:"relative"}}>
                {values.lizard_path_input ? (
                  <IconButton
                    onClick={() => {
                      resetData && resetData();
                      setValues({ ...values, lizard_path_input: "" });
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                ) : null}
                <InputField
                  style={{
                    height: "auto",
                    fontSize: "14px",
                  }}
                  rows={5}
                  placeholder={""}
                  name={"lizard_path_input"}
                  value={values.lizard_path_input}
                  multiline={true}
                />
                </div>
              </EvenColumnContainer>
            </MainFieldContainer2>

            <PaigeAiFormBtnContainer>
              <TextButton
                label={"Clear Text"}
                onClick={() => {
                  resetData && resetData();
                  setValues({ ...values, user_input: "" });
                }}
              ></TextButton>
              <Button label={"Send"} type={"submit"} loading={loading}></Button>
            </PaigeAiFormBtnContainer>
          </FieldContainer>
        </StyledForm>
      )}
    </Formik>
  );
}

export default PaigeAIFormFields;
