import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styled from "@emotion/styled";

const StyledFormHelperText = styled(FormHelperText)`
  color: #d32f2f;
`;

const SelectMenu = styled(MenuItem)`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  color: #172882;
`;

export interface SelectOptions {
  Name: string;
  value: string;
}

type SelectFieldProps = {
  name: string;
  placeholder: string;
  isClearable?: boolean;
  hideLabel?: boolean;
  options: any[];
  disabled?: boolean;
  onChange?: (option: SelectChangeEvent) => void;

  [key: string]: any;
};

function NonFormikSelectField(props: SelectFieldProps) {
  let { label, options, placeholder, name } = props;
  const handleChange = (event: SelectChangeEvent) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <>
      <FormControl sx={{ m: 1, width: "100%" }}>
        <InputLabel id='demo-simple-select-helper-label'>
          {placeholder}
        </InputLabel>
        <Select label={placeholder} onChange={handleChange} defaultValue=''>
          <SelectMenu key='0' value='0'>
            All
          </SelectMenu>
          {options.map((option) => {
            return (
              <SelectMenu key={option.value} value={option.value}>
                {option.Name}
              </SelectMenu>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}

NonFormikSelectField.defaultProps = {
  data: [],
};

NonFormikSelectField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default NonFormikSelectField;
